import BuyTravel from './BuyTravelComponent';
import { cloneDeep } from 'lodash';
import { defaultConfig } from '@/cms/api/ApiConfig';
import store from '@/store/store';
import AxiosService from '@/services/axiosService';
import {
	getDiscount,
	getRisksForPackage,
	mapCoveragesFromProductConfig,
	mapPriceResult,
	reducePack,
	setPrices,
} from '../services/CalculationService';
import { Formatter, monthYearArray } from '../services/ValidationService';
import { togglePopup } from '../services/AlertService';

export default class TravelCalculator {
	private model: any; // the data structure for state and properties
	private cms: any; // settings from EPI
	private calcConfig: any;
	private successCalc = true;
	private axiosService: AxiosService;
	private calculatorUrl: string;
	private calculatorInfo: Array<any>;

	constructor(buyTravel: BuyTravel) {
		this.model = buyTravel.model;
		this.cms = buyTravel.cms;
		this.calculatorInfo = JSON.parse(buyTravel.calculatorInfoBlock.markup);

		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/travel/quotes';
		this.axiosService = new AxiosService(this.calculatorUrl);

		this.setUpConfig();
	}

	private mapCoveragesFromCalc() {
		mapCoveragesFromProductConfig(this.model, this.cms);
		// change sum on afbestilling if other sum present in package
		this.model.calculation.abCalc.packages.forEach((pack, packageInx) => {
			try {
				// let cancelSum = parseInt(this.cms.cancelSum);
				// let featureId = this.cms.cancelSumId;
				if (pack.sums) {
					const sums = JSON.parse(pack.sums);
					const [covId, sum] = Object.entries(sums[0])[0];
					// cancelSum = sum as number;
					// featureId = covId;
					// override CMS settings
					this.cms.cancelSum = sum + '';
					this.cms.cancelSumId = covId;
				}
				const cancelSum = parseInt(this.cms.cancelSum);
				const featureId = this.cms.cancelSumId;

				// only default excess
				const res = this.model.calculation.allCoverages.values().next().value.get(packageInx);
				res.forEach((cov) => {
					if (cov.featureId === featureId) {
						// '3' - afbestilling
						const sumFormatted = Formatter.format(cancelSum);
						cov.description = cov.description
							.replace('30.000', sumFormatted)
							.replace('60.000', sumFormatted)
							.replace('100.000', sumFormatted)
							.replace('150.000', sumFormatted);
					}
				});
			} catch (e) {
				console.error(e);
				// wrong config
			}
		});
	}

	public async setUpConfig() {
		if (!this.calcConfig) {
			if (this.model.campaign.valid) {
				this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
			}

			const calculatorInfo = this.calculatorInfo.find(
				(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId
			);

			this.calcConfig = calculatorInfo.insuranceConfiguration;
			this.calcConfig.familyMemberCountOptions = calculatorInfo.familyMemberCountOptions;
		}

		this.model.calculation.abCalc = {
			excessIdDefault: this.calcConfig.excessIdDefault,
			packageIds: this.calcConfig.packageIds,
			packages: cloneDeep(this.calcConfig.packages),
			coveragesDetails: this.calcConfig.coverages,
			segmentedProductProperties: ['Europa', 'Verden'], // destinaions
			familyMemberCountOptions: this.calcConfig.familyMemberCountOptions,
		};

		this.setupExcessList();

		this.model.calculation.abCalc.packages.forEach((pack) => {
			pack.coverages = [];
			if (this.cms.extendCoverages) {
				pack.includedCoverageIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
				pack.expandedCoverages = [];
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
				});
			} else {
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
			}
		});
		this.model.calculation.abCalc.packs = new Map<number, Object>();
		this.model.calculation.abCalc.excesses.forEach((excess) => {
			const calcs = [];
			this.model.calculation.abCalc.packs.set(excess.id, calcs);
			this.model.calculation.abCalc.packages.forEach((pack) => {
				calcs.push(reducePack(cloneDeep(pack)));
			});
		});
		this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

		this.mapCoveragesFromCalc();
		this.updateCalcKeys();
	}

	public setupExcessList() {
		//this.model.calculation.abCalc.excesses = this.oldCalcConfig.excessList;
		// hardcode dummy excess
		this.model.calculation.abCalc.excesses = [
			{
				// "amount": 3229,
				id: -1,
				minCustomerAge: 18,
			},
		];
		this.model.ownRiskOptions = [];
		this.model.ownRiskLabels = [];
	}

	private checkFirstPackagePrices(excessId: number) {
		let ok = true;
		const packages = this.model.calculation.abCalc.packs.get(excessId);
		packages.forEach((calc) => {
			if (!calc.totalPrice) {
				ok = false;
			}
		});
		return ok;
	}

	private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
		try {
			this.successCalc = true;

			const rebate = getDiscount(this.cms);
			this.model.calculation.discount = rebate.discount;
			this.model.calculation.discountDisplay = rebate.discountDisplay;

			const promises = [];
			for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
				const packageId = this.model.calculation.abCalc.packageIds[index];
				promises.push(this.onePackageCalcEpi(monthly, excessId, packageId));
			}
			await Promise.all(promises);
			return Promise.resolve(this.successCalc);
		} catch (ex) {
			console.error(ex);
			if (monthly) {
				this.errorPopUp(400);
			}
			return Promise.resolve(false);
		}
	}

	public async getCalculations(): Promise<boolean> {
		if (!this.model.calculation.isCalculated) {
			store.state.showSpinner = true;
			store.state.calculatorContext.calculating = true;
			// handle "eternal" spinning
			setTimeout(() => {
				if (store.state.showSpinner) {
					store.state.showSpinner = false;
					store.state.calculatorContext.calculating = false;
				}
			}, this.cms.calculationSpinnerTimeout);
		}

		// reset prices
		const pack = this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault);
		pack.totalPriceDisplay = undefined;
		return this.getEpiCalculations();
	}

	private async getEpiCalculations(): Promise<boolean> {
		// reset calculation
		await this.setUpConfig();

		let success = true;

		success = await this.calculatePackagesEpi(true, this.model.calculation.abCalc.excessIdDefault);
		// check if has got prices
		if (!success) {
			success = this.checkFirstPackagePrices(this.model.calculation.abCalc.excessIdDefault);
		}
		if (success) {
			this.calculatePackagesEpi(false, this.model.calculation.abCalc.excessIdDefault);
		}
		this.model.calculation.isCalculated = success;
		store.state.showSpinner = false;
		store.state.calculatorContext.calculating = false;
		this.model.choosePackage.ownRiskId = this.model.calculation.abCalc.excessIdDefault;

		return success;
	}

	private async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {
		const response = await this.axiosService.getRetryDefaultUrl(
			this.createParamsEpi(excessId, packageId, monthly ? 1 : 0)
		);
		if (response?.status !== 200) {
			this.successCalc = false;
			// this.model.modal.content = response.data.warningMessage ? response.data.warningMessage : this.cms.defaultCalcErrorContent;
			this.errorPopUp(response?.status);
			return Promise.resolve(false);
		}
		const pack = this.model.calculation.abCalc.packs.get(excessId);
		mapPriceResult(response);
		setPrices(packageId, response, monthly, pack, this.model, this.cms);

		this.updateCalcKeys();
		return Promise.resolve(true);
	}

	private errorPopUp(status) {
		const popup: any = {
			content: this.cms.defaultCalcErrorContent,
			track: true,
			btnSecondLabel: 'Ok',
			id: status === 250 ? 'calcWarning' : 'calcError',
			title: this.cms.defaultCalcErrorTitle,
			show: true,
			trackToken: 'Travel calculator',
		};
		if (this.cms.useBtnInlineFail) {
			popup.btnInlineLabel = this.cms.btnInlineFailLabel;
			popup.btnInlineStyle = this.cms.btnInlineFailStyle;
			popup.btnInlineIcon = this.cms.btnInlineIcon;
			popup.btnInlineAction = () => {
				this.getCalculations();
			};
		}
		togglePopup(this.cms, popup);
	}

	private createParamsEpi(excessId: number, packageId: number, monthlyPrices: number) {
		//  https://preprod-api.almbrand.dk/insurance/travel/quotes/?
		// customer_age=34&
		// post_code=3480&
		// destination=europa&
		// cancel_sum=30000&
		// family_member_count=2&
		// plus_customer=PK0&
		// payments_per_year=1&
		// selected=1,2,3,5,8,9,10,11,12,13,14,15,16,17,18,19,30,31,32,33,34,35,36,37,38,42&
		// optional=41
		const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g, ''));
		let cancelSum = this.cms.cancelSum; // afbestillingssum

		// change sum on afbestilling if sum present in package
		this.model.calculation.abCalc.packages.forEach((pack) => {
			if (pack.id === packageId) {
				if (pack.sums) {
					try {
						const sums = JSON.parse(pack.sums);
						const [coverageId, sum] = Object.entries(sums[0])[0];
						//coverageId === '3'
						cancelSum = sum;
					} catch (e) {
						console.error(e);
						// wrong config
					}
				}
			}
		});

		let groupAgreement = 0;
		if (this.model.campaign.valid) {
			groupAgreement = this.cms.campaigns.get(this.model.campaign.ID).groupId;
		}

		// create params
		const params = {
			plus_customer: 'PK0',
			customer_age: customerAge,
			family_member_count: this.model.personInfo.familyMemberCountId,
			destination: this.model.choosePackage.segmentedProductProperty,
			post_code: `${this.model.personInfo.zipCode}`,
			group_agreement: groupAgreement < 1 ? groupAgreement : undefined,
			cancel_sum: cancelSum,
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: getRisksForPackage(packageId, this.model),
		};
		return params;
	}

	public updateCalcKeys() {
		this.model.calculation.updatePrices = this.model.calculation.updatePrices.map((elem) => {
			return (elem += '1');
		});
	}
}
