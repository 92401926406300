import { HeaderCardBlock, HtmlBlock, BasketPage } from '@/cms/definitions/content-types';
import { UPDATE_BASKET } from '@/store/modules/calculatorContext';
import { BasketSettings } from '@/views/calculators/basket/BasketSettings';
import { Model } from '@/views/calculators/basket/Model';
import PageTitleComponent from '@/sharedcomponents/pageComponents/pageTitle/PageTitleComponent.vue';
import ContentArea from '@/cms/components/infrastructure/contentArea/ContentArea.vue';
import { cloneDeep } from 'lodash';
import { PropType, nextTick } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import store from '@/store/store';
import BasketWidgetComponent from '@/views/calculators/components/BasketWidgetComponent.vue';
import { getDiscount } from '../services/CalculationService';
import { handlePreselectedProducts, headerBadgeShown } from '../services/NavigationService';
import { AppSendMessage, isApp } from '@/services/environmentService';
import CookieFooter from '@/sharedcomponents/pageComponents/cookieFooter/cookieFooter.vue';
import Menu from '@/sharedcomponents/pageComponents/menu/Menu.vue';
import { loadCustomer } from '../services/UserLoginService';
import { trackViewItemList } from '@/services/trackingUtility';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { togglePopup } from '../services/AlertService';

@Options({
	name: 'BasketPageComponent',
	components: {
		ContentArea,
		PageTitleComponent,
		BasketWidgetComponent,
		CookieFooter,
		Menu,
		UsabillaInPageComponent,
	},
	computed: mapState<any>({
		isInEditMode: (state) => state.epiContext.inEditMode,
		menuModel: (state) => state.menuModel,
	}),
	props: {
		model: Object as PropType<BasketPage>,
	},
})
export default class BasketPageComponent extends Vue {
	public model!: BasketPage;
	public cmsInit = false;
	public preTitle = '';
	public mainTitle = '';
	public headerBadgeTxt: string = null;
	public hasBadgeText = false;
	public menuModel!: Array<any>;
	public usabillaProductBlock: any = null;

	// LIFECYCLE
	async beforeMount() {
		await handlePreselectedProducts(this.$route.query);
		await this.handleBasket();
		this.handleTitles();
		window.addEventListener('resize', this.handleTitles);
		// this.handleTracking();
		loadCustomer(store.getters.getBasket.cms);
		this.handleClosedCalculators();
	}

	async mounted() {
		const idName = 'insurances_basket';
		await trackViewItemList(idName, idName, false, false);
	}

	public beforeUnmount() {
		window.removeEventListener('resize', this.handleTitles);
	}

	// COMPUTED
	public get showSpinner() {
		return store.state.showSpinner;
	}

	get isLoggedInAsApp(): boolean {
		return isApp();
	}

	// METHODS
	private async handleBasket() {
		const settingsBlock = { markup: '' } as HtmlBlock;

		this.model.mainArea.forEach((block) => {
			if (block.contentType === 'HtmlBlock') {
				if (block.name.toLocaleLowerCase() === 'global settings') {
					// global settings first
					settingsBlock.markup = (block as HtmlBlock).markup + settingsBlock.markup;
				} else {
					settingsBlock.markup += (block as HtmlBlock).markup;
				}
			} else if (block.contentType === 'HeaderCardBlock') {
				const headerBlock = block as HeaderCardBlock;
				const text = headerBlock.text && headerBlock.text.trim() === '' ? '' : headerBlock.text;
				const hasText = text !== '';
				const hasCss = headerBlock.css && headerBlock.css?.trim() !== '';

				const isHeaderBadgeShown = headerBadgeShown(block.contentId);
				if (!isHeaderBadgeShown) {
					if (hasText) {
						this.headerBadgeTxt = this.headerBadgeTxt ? this.headerBadgeTxt + text : text;
						this.hasBadgeText = true;
					}
					if (hasCss) {
						this.headerBadgeTxt = this.headerBadgeTxt
							? this.headerBadgeTxt + headerBlock.css
							: headerBlock.css;
					}
					if (headerBlock.script) {
						const s = document.createElement('script');
						s.type = 'text/javascript';
						try {
							s.appendChild(document.createTextNode(headerBlock.script));
							document.body.appendChild(s);
						} catch (e) {
							console.error(e);
						}
					}
				}
			} else if (block.contentType === 'UsabillaInPageBlock') {
				const usabillaId = (block as any)?.usabillaId;
				switch (usabillaId) {
					case store.state.UsabillaProductId:
						this.usabillaProductBlock = block;
						break;
					default:
						break;
				}
			}
		});
		if (store.getters.getBasket) {
			this.cmsInit = true;
			return;
		}

		const cms = new BasketSettings(settingsBlock);

		// this is first load - update store
		await store.dispatch(UPDATE_BASKET, { cms, model: cloneDeep(Model.model) });
		const model = store.getters.getBasket.model;
		// check for contact_information
		const basket = store.getters.getSelectableGroup('basket');
		basket.forEach((product) => {
			const calc = store.getters.getCalculator(product);
			if (calc && calc.model?.contact_information?.customerNo) {
				model.contact_information.customerNo = calc.model.contact_information.customerNo;
				if (calc.model.contact_information.name) {
					model.contact_information.name = calc.model.contact_information.name;
				}
				if (calc.model.contact_information.phone) {
					model.contact_information.phone = calc.model.contact_information.phone;
				}
				if (calc.model.contact_information.email) {
					model.contact_information.email = calc.model.contact_information.email;
				}
			}
		});
		const rebate = getDiscount(cms);
		model.calculation.discount = rebate.discount;
		model.calculation.discountDisplay = rebate.discountDisplay;
		this.cmsInit = true;
	}

	private handleTitles() {
		try {
			if (isApp()) {
				this.mainTitle = store.getters.getBasket.cms.shortTitleChooseInsurance; // beregn
				this.preTitle = undefined; // undefined
				AppSendMessage({ MAIN_TITLE: this.model.mainTitle });
			} else if (document.body.clientWidth < 768) {
				this.mainTitle = store.getters.getBasket.cms.shortMainTitleChooseInsurance; // forsikringer
				this.preTitle = store.getters.getBasket.cms.shortTitleChooseInsurance; // beregn
			} else {
				this.mainTitle = this.model.mainTitle; // forsikringer
				this.preTitle = this.model.preTitle; // beregn
			}
		} catch (e) {
			// Too bad
		}
	}

	private handleClosedCalculators() {
		try {
			const cms = store.getters.getBasket.cms;
			if (cms.closeCalculator) {
				nextTick().then(() => {
					togglePopup(cms, {
						id: 'closed',
						show: true,
						btnLabel: cms.btnLabelClose,
						btnSecondLabel: cms.btnSecondLabelClose,
						btnSecondStyle: cms.btnSecondLabelCloseStyle,
						redirectOnClose: cms.redirectOnClose,
						redirect: cms.btnRedirectUrlClose,
						track: true,
						trackToken: 'calculator closed',
						title: cms.closeCalculatorPopupTitle,
						content: cms.closeCalculatorPopupContent,
					});
				});
			}
		} catch (error) {
			// Too bad
		}
	}
	// public handleTracking() {
	//     try {
	//         const query = this.$route.query;
	//         const source = query.utm_source as string
	//         if (source) {
	//             triggerCustomGtmEvent({
	//                 'event': 'track-vp',
	//                 'virtualPath': `${getTrackingPreUrl()}kurv/utm_source/${source}`
	//             });
	//         }
	//         const medium = query.utm_medium as string
	//         if (medium) {
	//             triggerCustomGtmEvent({
	//                 'event': 'track-vp',
	//                 'virtualPath': `${getTrackingPreUrl()}kurv/utm_medium/${medium}`
	//             });
	//         }
	//         const content = query.utm_content as string
	//         if (content) {
	//             triggerCustomGtmEvent({
	//                 'event': 'track-vp',
	//                 'virtualPath': `${getTrackingPreUrl()}kurv/utm_content/${content}`
	//             });
	//         }
	//         const campaign = query.utm_campaign as string
	//         if (campaign) {
	//             triggerCustomGtmEvent({
	//                 'event': 'track-vp',
	//                 'virtualPath': `${getTrackingPreUrl()}kurv/utm_campaign/${campaign}`
	//             });
	//         }
	//     } catch (error) {
	//         console.error(error);
	//         // too bad
	//     }

	// }
}
