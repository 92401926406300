import { mapState } from 'vuex';
import FieldValidator from '@/services/fieldValidatorService';
import { CardBlock } from '@/cms/definitions/content-types';
import { AccidentSettings } from '../accident/AccidentSettings';
import { HomeSettings } from '../home/HomeSettings';
import { HouseSettings } from '../house/HouseSettings';
import { DogSettings } from '../dog/DogSettings';
import { Options, Vue } from 'vue-class-component';
import { TravelSettings } from '../travel/TravelSettings';
import store from '@/store/store';
import { jaNejArray, Validator, ValuePair } from '../services/ValidationService';
import { getCalculatorNameUi, getShortProductName } from '../services/ProductService';
import { togglePopup } from '../services/AlertService';

@Options({
	name: 'AdditionalInfoStepComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object, // Card from cms
		isValid: Boolean, // wether card is complete
		showInsuranceChoices: Boolean, // wether to show current and existing insurance choice
		existingInsuranceLabel: String,
		showValidNowOption: Boolean,
		showCommon: { type: Boolean, default: true }, // wether to show this ui - used for wait on slot questions
	},
})
export default class AdditionalInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete
	showInsuranceChoices: boolean; // wether to show current and existing insurance choice
	existingInsuranceLabel: string;
	showValidNowOption: boolean;
	showCommon?: boolean; // wether to show this ui - used for wait on slot questions

	public model!: any; // datastructure for user input (store)
	public cms!: DogSettings | TravelSettings | AccidentSettings | HomeSettings | HouseSettings; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;

	public jaNejArray: Array<ValuePair> = jaNejArray;
	public validNowArray: Array<ValuePair> = [
		{
			value: 'ja',
			displayValue: 'Hurtigst muligt',
		},
		{
			value: 'nej',
			displayValue: 'Vælg dato',
		},
	];
	public skaderOptions = ['0', '1', '2', '3+'];
	public skaderLabels = this.skaderOptions;

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public selectExistingInsurance(evt) {
		this.model.additionalInfo.existingInsurance = evt.selectedValue;
		const inx = this.cms.insuranceCompanies.indexOf(evt.selectedValue);
		this.model.additionalInfo.existingInsuranceId = this.cms.insuranceCompaniesIds[inx];
	}

	// public handleValidFrom(evt) {
	//     try {
	//         // try nicyfi
	//         const res = this.model.additionalInfo.validFromDate.trim().match(/[\d]+/g);
	//         if(res && res.length > 2) {
	//             this.model.additionalInfo.validFromDate = `${res[0]}-${res[1]}-${res[2]}`;
	//         }
	//     } catch(e) {
	//     }
	// }

	private isValidFromFilled() {
		// TOOD KLK might not be needed anymore
		if (this.model.additionalInfo.validNow) {
			if (
				this.model.additionalInfo.validNow === 'nej' &&
				!this.fieldValidator.isValidFromDate(this.model.additionalInfo.validFromDate)
			) {
				return false;
			}
			return true;
		}
		return false;
	}

	public get isInsuranceInfoFilled() {
		if (!this.showValidNowOption) {
			return false;
		}
		return this.isValidFromFilled();
	}

	public handleRadioClick(evt: any) {
		const name = evt.name ? evt.name : evt.id;
		switch (name) {
			case 'skader':
				this.model.additionalInfo.skader = evt.value;
				if (this.cms.damagesPopupTitle && !this.cms.damagesOk.includes(evt.value)) {
					const popup = {
						title: this.cms.damagesPopupTitle,
						content: this.cms.damagesPopupContent,
						redirect: this.cms.damagesPopupRedirect,
						btnSecondLabel: this.cms.damagesPopupRedirect ? 'Afslut' : 'Ok',
						track: false,
						id: 'skader',
						show: true,
					};
					togglePopup(this.cms, popup);
				}
				break;
			case 'rki':
				this.model.additionalInfo.rki = evt.value;
				if (evt.value === 'ja' && this.cms.rkiPopupTitle) {
					const popup = {
						title: this.cms.rkiPopupTitle,
						content: this.cms.rkiPopupContent,
						redirect: this.cms.rkiPopupRedirect,
						btnSecondLabel: this.cms.rkiPopupRedirect ? 'Afslut' : 'Ok',
						track: false,
						id: 'rki',
						show: true,
					};
					togglePopup(this.cms, popup);
				}
				break;
			case 'existInsurance':
				this.model.additionalInfo.existInsurance = evt.value;
				break;
			case 'validNow':
				this.model.additionalInfo.validNow = evt.value;
				if (this.model.additionalInfo.validNow === 'nej') {
					setTimeout(() => {
						const input = document.getElementById('validFromDate');
						if (input) {
							input.focus();
						}
					}, this.cms.initialTimeout);
				}
				break;
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}

	public get skaderOk() {
		return this.model.additionalInfo.skader && this.cms.damagesOk.includes(this.model.additionalInfo.skader);
	}

	public getNextCardLinkText(): string {
		// check if there is multiple products (Basket)

		if (this.model.multipleProducts) {
			const res = store.getters.getSelectableGroup('basket');
			const activeIndex = store.getters.getActiveCalculatorInx;
			let foundNextProduct = undefined;
			res.forEach((productName) => {
				if (!foundNextProduct && this.model.productName + activeIndex !== productName) {
					const product = store.getters.getCalculator(productName);
					if (!product || !product.model.readyForBasket) {
						if (screen.width < 425) {
							productName = getShortProductName(productName);
						}
						foundNextProduct = 'Fortsæt til ' + getCalculatorNameUi(productName);
					}
				}
			});
			if (foundNextProduct) {
				return foundNextProduct;
			}
			return 'Gå til Kurv';
		} else {
			return this.card.linkText;
		}
	}
}
