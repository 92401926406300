import { getDevice, isDevelop } from '@/services/environmentService';
import store from '@/store/store';
import { CalculatorComponent } from '../BuyInsuranceComponent';
import { togglePopup } from '../services/AlertService';
import {
	addProp,
	buildExistingProductsDescription,
	getPaymentInfo,
	sendErrorMailToTeam,
	submitOrder,
} from '../services/OrderService';
import { getCoverageNames } from '../services/ProductService';

export default class MapOrderDescription {
	private uiComp: CalculatorComponent;

	public constructor(uiComp: CalculatorComponent) {
		this.uiComp = uiComp;
	}

	public async orderByEmail() {
		const model = this.uiComp.model;
		const cms = this.uiComp.cms;
		try {
			const pack = model.choosePackage.selectedPackage;
			let desc = '';

			let tracking = '';

			if (model.contact_information.customerNo) {
				const device = getDevice();
				desc += `<li><h2><i>Kunde genkendt ${device}</i></h2></li>`;
				tracking += '/existingCustomer';
			}

			if (model.campaign.valid) {
				const id = model.campaign.ID ? model.campaign.ID.toLocaleUpperCase() : 'Ukendt!';
				desc += `<li><h2><i>Kampagne ${id}</i></h2></li>`;
				tracking += `/campaign/${id}`;
			}

			if (model.additionalInfo.rki?.toLowerCase() === 'ja') {
				desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
				// tracking += "/rki";
			}
			if (model.additionalInfo.skader !== '0') {
				desc += `<li><h2><i>Kunde har ${model.additionalInfo.skader} skader</i></h2></li>`;
				// tracking += "/damages";
			}

			if (desc !== '') {
				desc = '<ul>' + desc + '</ul>';
			}

			await submitOrder(desc, tracking, this.uiComp);
		} catch (e) {
			store.state.showSpinner = false;
			if (isDevelop) {
				model.modal.content = model.modal.content + '<br><div style="border: 2px solid red">' + e + '</div> ';
			}
			togglePopup(cms, {
				id: 'error',
				show: true,
				redirect: undefined,
				track: true,
				trackToken: 'child accident submitOrder',
			});
			sendErrorMailToTeam(e, model);
		}
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		const model = this.uiComp.model;
		const cms = this.uiComp.cms;

		const pack = model.choosePackage.selectedPackage;
		desc += '<h2>Person</h2>';
		const now: Date = new Date();
		desc += addProp(
			'Email sendt',
			`${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${
				now.getMonth() + 1
			}-${now.getFullYear()}`
		);
		desc += addProp('Navn', model.contact_information.name);
		if (model.personInfo.address) {
			desc += addProp('Adresse', `${model.personInfo.address}`);
		} else {
			desc += addProp('Postnr./by', `${model.personInfo.zipCode} ${model.personInfo.zipName}`);
		}
		if (model.contact_information.customerNo) {
			desc += addProp('KundeNr', model.contact_information.customerNo);
		} else {
			desc += addProp('Cpr', model.contact_information.cpr);
		}

		desc += addProp('Antal skader', model.additionalInfo.skader);
		desc += addProp('Kunde har accepteret betingelser', model.contact_information.accept ? 'Ja' : 'Nej');
		desc += addProp('Email', model.contact_information.email);
		desc += addProp('Telefonnummer', model.contact_information.phone);

		desc += buildExistingProductsDescription(model, cms);

		desc += '<h2>Forsikring</h2>';
		desc += addProp('Rki', model.additionalInfo.rki);
		desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);

		if (model.additionalInfo.existInsurance === 'ja') {
			desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
		}

		desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
		if (model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
		}

		desc += '<h2>Ønsket forsikring</h2>';
		desc += addProp('Forsikringstype', pack.name);
		if (model.familyInfo.personCountId > 0) {
			desc += '<br><b>' + (model.familyInfo.personCountId + 1) + ' Børn</b><br>';
		} else {
			desc += '<br><b>' + (model.familyInfo.personCountId + 1) + ' Barn</b><br>';
		}

		for (let i = 0; i <= model.familyInfo.personCountId; i++) {
			desc += '<br><b>Barn ' + (i + 1) + '</b><br>';
			desc += addProp('navn ', model.familyInfo['name' + i]);
			desc += addProp('alder ', model.familyInfo['age' + i] + ' år');
			desc += addProp('cpr ', model.familyInfo['cpr' + i]);
			if (model.familyInfo['workId' + i]) {
				desc += addProp('job ', model.familyInfo['work' + i]);
				desc += addProp('jobId ', model.familyInfo['workId' + i]);
			}
		}
		desc += addProp('<br>Betaling', model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
		desc += addProp(
			'Samlet pris',
			model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', 'Tia beregning');
		desc += addProp('Dækninger', getCoverageNames(pack));

		desc += getPaymentInfo(model, cms);

		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
