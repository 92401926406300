import { mapState } from 'vuex';
import { CardBlock } from '@/cms/definitions/content-types';
import { DogSettings } from '../DogSettings';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { jaNejArray, ValuePair } from '../../services/ValidationService';
import { togglePopup } from '../../services/AlertService';

@Options({
	name: 'AdditionalQuestionsInfoStepComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object as PropType<CardBlock>,
		isValid: Boolean,
	},
})
export default class AdditionalQuestionsInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean;

	public model!: any; // datastructure for user input (store)
	public cms!: DogSettings; // settings from CMS (store)

	public jaNejArray: Array<ValuePair> = jaNejArray;

	public handleRadioClick(evt: any) {
		const name = evt.name ? evt.name : evt.id;
		switch (name) {
			case 'vaccine':
				this.model.dogInfo.vaccine = evt.value;
				if (evt.value === 'nej') {
					const popup = {
						title: this.cms.vaccinePopTitle,
						content:
							this.model.dogInfo.dogCount === 2
								? this.cms.vaccinePopContentMulti
								: this.cms.vaccinePopContent,
						btnSecondLabel: 'Ok',
						track: false,
						id: 'vaccine',
						show: true,
					};
					togglePopup(this.cms, popup);
				}

				break;
			case 'healthy':
				this.model.dogInfo.healthy = evt.value;
				if (evt.value === 'nej') {
					const popup = {
						title: this.cms.healthyPopTitle_,
						content:
							this.model.dogInfo.dogCount === 2
								? this.cms.healthyPopContent_Multi
								: this.cms.healthyPopContent_,
						btnSecondLabel: 'Ok',
						track: false,
						id: 'healthy',
						show: true,
					};
					togglePopup(this.cms, popup);
				}

				break;
			case 'beenUnhealthy':
				this.model.dogInfo.beenUnhealthy = evt.value;
				if (evt.value === 'ja') {
					const popup = {
						title: this.cms.beenUnhealthyPopTitle,
						content:
							this.model.dogInfo.dogCount === 2
								? this.cms.beenUnhealthyPopContentMulti
								: this.cms.beenUnhealthyPopContent,
						btnSecondLabel: 'Ok',
						track: false,
						id: 'beenUnhealthy',
						show: true,
					};
					togglePopup(this.cms, popup);
				}

				break;
			case 'vet':
				this.model.dogInfo.vet = evt.value;
				if (evt.value === 'ja') {
					const popup = {
						title: this.cms.vetPopTitle,
						content:
							this.model.dogInfo.dogCount === 2 ? this.cms.vetPopContentMulti : this.cms.vetPopContent,
						btnSecondLabel: 'Ok',
						track: false,
						id: 'vet',
						show: true,
					};
					togglePopup(this.cms, popup);
				}
				break;
		}
	}
}
