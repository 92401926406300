import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import store from '@/store/store';
import localforage from 'localforage';
import { getDiscount } from './CalculationService';
import { STEP } from './UiStepService';
import { Formatter } from './ValidationService';
import { isDevelop, isLocalHost } from '@/services/environmentService';

export const getCalculatorIndex = (name: string): number => {
	const productInx = name.replace(/[^\d]/g, '');
	return productInx === '' ? -1 : parseInt(productInx);
};

export const getShortProductName = (productName: string): string => {
	const name = stripProductIndex(productName);
	const inx = getCalculatorIndex(productName);
	let productInx = '';
	if (inx > 0) {
		productInx = ' ' + inx;
	}
	switch (name) {
		case InsurancesProductEnum.BIL_FORSIKRING:
		case InsurancesProductEnum.INDBO_FORSIKRING:
		case InsurancesProductEnum.REJSE_FORSIKRING:
		case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
		case InsurancesProductEnum.HUS_FORSIKRING:
		case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
			return name.replace('forsikring', '') + productInx;
		case InsurancesProductEnum.ULYKKES_FORSIKRING:
		case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
			return name.replace('sforsikring', '') + productInx;
		case InsurancesProductEnum.HUNDE_FORSIKRING:
			return name.replace('eforsikring', '') + productInx;
		default:
			return productName + productInx;
	}
};

export const getCalculatorNameUi = (name: string): string => {
	const n = name.charAt(0).toLocaleUpperCase() + name.slice(1);
	const inx = getCalculatorIndex(n);
	return inx < 1 ? n.replace('' + inx, '') : n.replace('' + inx, ' ' + (inx + 1)); // næste forsikring hedder 2 ikke 1
};

export const getCoverageNames = (selectedPackage) => {
	if (selectedPackage.expandedCoverages) {
		return selectedPackage.expandedCoverages.join(', ');
	}

	const names = [];
	selectedPackage.coverages.forEach((element) => {
		names.push(element.name || element.title);
	});
	return names.join(', ');
};

export const setHighLights = (model) => {
	model.overview.highlights = [];
	const isPlusCustomer = model.calculation.discount && model.calculation.discount < 1;

	let hasCampaignDiscount = false;
	let campaignDiscount = 0;
	let discountDisplay: string = undefined;
	let hasCampaignSplash = false;
	if (model.campaign.valid) {
		hasCampaignDiscount = model.campaign.discount > 0;
		if (hasCampaignDiscount) {
			campaignDiscount = model.campaign.discount;
			discountDisplay = model.campaign.discountDisplay;
		} else {
			hasCampaignDiscount = model.campaign.tiaDiscount > 0;
			if (hasCampaignDiscount) {
				campaignDiscount = model.campaign.tiaDiscount;
				discountDisplay = Formatter.format(100 * campaignDiscount) + ' %';
			}
		}
		hasCampaignSplash = model.campaign.splash;
	}

	if (isPlusCustomer) {
		let txt = '<div class="grow"><i>Plus</i>Kunde rabat fratrukket pris </div><div>';
		if (hasCampaignDiscount) {
			txt = '<div class="grow"><i>Plus</i>Kunde rabat </div><div>';
		}
		model.overview.highlights.push(`${txt}<div>${model.calculation.discountDisplay}</div>`);
	}
	if (hasCampaignDiscount) {
		let txt = '<div class="grow">Online rabat fratrukket pris </div>';
		if (isPlusCustomer) {
			txt = '<div class="grow">Online rabat </div>';
		}
		model.overview.highlights.push(`${txt}<div>${discountDisplay}</div>`);
	}
	if (isPlusCustomer && hasCampaignDiscount) {
		const totalDiscount = Math.abs(model.calculation.discount - 1) + campaignDiscount;
		const display = Number(totalDiscount).toLocaleString('da-DK', {
			style: 'percent',
			minimumFractionDigits: 0,
		});
		model.overview.highlights.push(
			`<div class="grow">Samlet rabat fratrukket pris </div><div><strong>${display}</strong></div>`
		);
	}

	if (hasCampaignSplash) {
		model.overview.highlights.push(`<div class="grow">Kampagne </div><div>${model.campaign.splash}</div>`);
	}
};
export const setDetailsYearlyPrice = (model, cms) => {
	if (!cms.overviewShowYearlyPrice) {
		// wait for all calcs
		let max = 20;
		const interval = setInterval(() => {
			max--;
			if (model.choosePackage.selectedPackage.yearlyPriceTotalDisplay || max < 1) {
				model.overview.details = [
					'Årlig pris: ' + model.choosePackage.selectedPackage.yearlyPriceTotalDisplay,
					...model.overview.details,
				];
				clearInterval(interval);
			}
		}, 500);
	}
};

export const getCardTitle = (card, model, cms): string => {
	return card.name === STEP.PACKAGE && model.choosePackage.selectedPackage !== undefined
		? cms.calculatedHeadline
		: card.title;
};

export const getSubtitle = (cardName: string, model) => {
	switch (cardName) {
		case STEP.CAR_INFO:
			return model.carInfo.subtitle;
		case STEP.DOG_INFO:
			return model.dogInfo.subtitle;
		// case STEP.FAMILY_INFO:
		case STEP.CHILDREN_INFO:
			return model.familyInfo.subtitle;
		case STEP.PERSON_INFO:
			return model.personInfo.subtitle;
		case STEP.OVERVIEW:
			return model.overview.subtitle;
		case STEP.ADDITIONAL_INFO:
			return model.additionalInfo.subtitle;
		case STEP.PACKAGE:
			return model.choosePackage.subtitle;
	}
	return model[cardName] ? model[cardName].subtitle : undefined;
};

export const stripProductIndex = (productName) => {
	return productName.replace(/[0-9]/g, ''); // remove indexes (bilforsikring0 -> bilforsikring, bilforsikring1 -> bilforsikring)
};

export const getUrlProductName = (productName) => {
	const name = stripProductIndex(productName);

	switch (name) {
		case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
			return 'boerneulykkesforsikring';
		case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
			return 'boernefamilieforsikring';
		default:
			return name;
	}
};

export const getSuggestedProducts = (cms: any): { suggestedProducts: string; discountDisplay: string } => {
	const basket = store.getters.getSelectableGroupUnique('basket');
	if (basket.length === 0) {
		return {
			suggestedProducts:
				'at tilføje <strong>' +
				cms.discountPrimaryProducts[0] +
				'</strong> og eksempelvis <strong>' +
				cms.discountSecondaryProducts[0] +
				'</strong>',
			discountDisplay: '5 %',
		};
	}
	const rebate = getDiscount(cms);

	let products = [];
	let personInfo = store.getters.getCalculatorCommonModel?.personInfo;

	if (!personInfo?.almbrandCustomer) {
		// get from active calculator
		personInfo = store.getters.getActiveCalculator?.model?.personInfo;
	}

	if (personInfo?.existingAlmBrandProducts) {
		products = Object.assign([], personInfo.existingAlmBrandProducts);
		products = products.map((product) => product.toLocaleLowerCase());
	}

	basket.forEach((product) => {
		if (!products.includes(product)) {
			products.push(product);
		}
	});

	if (products.includes(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING)) {
		if (!products.includes(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
			products.push(InsurancesProductEnum.ULYKKES_FORSIKRING);
		}
	}

	if (products.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING)) {
		if (!products.includes(InsurancesProductEnum.INDBO_FORSIKRING)) {
			products.push(InsurancesProductEnum.INDBO_FORSIKRING);
		}
		if (!products.includes(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
			products.push(InsurancesProductEnum.ULYKKES_FORSIKRING);
		}
	}

	// kun hus eller fritidshus er rabatgivende
	if (
		products.includes(InsurancesProductEnum.HUS_FORSIKRING) &&
		products.includes(InsurancesProductEnum.FRITIDSHUS_FORSIKRING)
	) {
		products = products.filter((item) => item !== InsurancesProductEnum.FRITIDSHUS_FORSIKRING);
	}

	const suggestProducts = cms.discountSecondaryProducts.filter((element) => {
		return !products.includes(element.toLocaleLowerCase());
	});

	if (!products.includes('indboforsikring')) {
		switch (suggestProducts.length) {
			case 4:
				return {
					suggestedProducts:
						'at tilføje <strong>' +
						cms.discountPrimaryProducts[0] +
						'</strong> og eksempelvis <strong>' +
						cms.discountSecondaryProducts[0] +
						'</strong>',
					discountDisplay: '5 %',
				};
			case 3:
				return {
					suggestedProducts: 'at tilføje <strong>' + cms.discountPrimaryProducts[0] + '</strong>',
					discountDisplay: '5 %',
				};
			case 2:
				return {
					suggestedProducts: 'at tilføje <strong>' + cms.discountPrimaryProducts[0] + '</strong>',
					discountDisplay: '10 %',
				};
			case 1:
			case 0:
				return {
					suggestedProducts: 'at tilføje <strong>' + cms.discountPrimaryProducts[0] + '</strong>',
					discountDisplay: '15 %',
				};
		}
	} else {
		switch (rebate.discount) {
			case 0.85:
				return undefined; // ikke mere pluskunde rabat
			case 0.9:
				return {
					suggestedProducts: 'eksempelvis at tilføje <strong>' + suggestProducts[0] + '</strong>',
					discountDisplay: '15 %',
				};
			case 0.95:
				return {
					suggestedProducts: 'eksempelvis at tilføje <strong>' + suggestProducts[0] + '</strong>',
					discountDisplay: '10 %',
				};
			default:
				return {
					suggestedProducts: 'eksempelvis at tilføje <strong>' + suggestProducts[0] + '</strong>',
					discountDisplay: '5 %',
				};
		}
	}
};

// cleanup campaign in storage if it exists
export const resetCampaignStorage = (model: any) => {
	if (model?.campaign?.valid) {
		try {
			const campaignName = 'campaign' + model.productName;
			localforage.removeItem(campaignName);
		} catch (e) {
			// no problem
		}
	}
};

//update excesses from calculation result
export const updateExcessesFromCalculation = (response: any, calcConfig: any): boolean => {
	try {
		if (isDevelop) {
			console.log('updateExcessesFromCalculation');
		}
		const excesses: Array<{ id: number; amount: number }> = response?.data?._embedded?.excesses;
		if (excesses?.length > 0) {
			excesses.forEach((excess) => {
				if (Number.isNaN(excess.amount)) {
					console.warn('update excess isNAN', excess);
					return;
				}

				calcConfig.excessList.forEach((excessCurrent, inx) => {
					// car excess Ids are strings!
					if (excess.id + '' === excessCurrent.id + '') {
						if (isDevelop) {
							console.log('updating', excessCurrent.id, excessCurrent.amount);
						}
						if (isDevelop || excess.amount !== calcConfig.excessList[inx].amount) {
							console.warn('update excess', excess, calcConfig.excessList[inx]);
							calcConfig.excessList[inx].amount = excess.amount;
						}

						if (isDevelop) {
							console.log('updated', calcConfig.excessList[inx].id, calcConfig.excessList[inx].amount);
						}
					}
				});
			});
			return true;
		}
	} catch (e) {
		console.error(e);
		// Too bad
	}
	return false;
};
