import { mapState } from 'vuex';
import { CardBlock } from '@/cms/definitions/content-types';
import { BasketSettings } from '../BasketSettings';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import FieldValidator from '@/services/fieldValidatorService';
import { BasketProductsEnum } from '@/store/modules/calculatorContext';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { jaNejArray, Validator, ValuePair } from '../../services/ValidationService';
import { togglePopup } from '../../services/AlertService';
import { mapDawaAddress, resetAddress } from '@/services/addressMappingService';
import { cloneDeep } from 'lodash';

@Options({
	name: 'ContactInformationBasketStepComponent',
	components: {},
	computed: mapState<any>({
		basketModel: (state) => state.calculatorContext.basket.model,
		basketCms: (state) => state.calculatorContext.basket.cms,
	}),
	props: {
		card: Object, // Card from cms
		isValid: Boolean, // wether card is complete
	},
})
export default class ContactInformationBasketStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete

	public basketModel!: any; // datastructure for user input (store)
	public basketCms!: BasketSettings; // settings from CMS (store)
	public fieldValidator: FieldValidator = Validator;
	public modelChildren: any = null;
	public modelAdultAccident: Array<any> = [];
	private hasCarInsurance = false;
	public noEmailCustomer = false;
	public showAddress = false;

	public jaNejArray: Array<ValuePair> = jaNejArray;
	public skaderOptions = ['0', '1', '2', '3+'];
	public skaderLabels = this.skaderOptions;

	public mounted() {
		window.addEventListener('keyup', this.moveOn);

		const basketUnique = store.getters.getSelectableGroupUnique('basket');
		if (basketUnique.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING)) {
			const calc = store.getters.getCalculator(BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0);
			if (calc) {
				this.modelChildren = calc.model;
			}
		} else if (basketUnique.includes(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING)) {
			const calc = store.getters.getCalculator(BasketProductsEnum.BOERNEULYKKES_FORSIKRING0);
			if (calc) {
				this.modelChildren = calc.model;
			}
		}

		if (basketUnique.includes(InsurancesProductEnum.BIL_FORSIKRING)) {
			this.hasCarInsurance = true;
		}

		if (!this.modelChildren?.readyForBasket) {
			this.modelChildren = undefined;
		}

		if (this.basketModel.contact_information.customerNo) {
			if (!this.fieldValidator.isValidEmail(this.basketModel.contact_information.email)) {
				this.noEmailCustomer = true;
			}
		}
		const tmpAddress = this.basketModel.personInfo.address;
		this.basketModel.personInfo = cloneDeep(store.getters.getCalculatorCommonModel.personInfo);
		if (tmpAddress) {
			this.basketModel.personInfo.address = tmpAddress;
		}
		if (this.basketCms.customerAddressRequired) {
			// this.basketModel.personInfo = cloneDeep(store.getters.getCalculatorCommonModel.personInfo);

			this.showAddress = this.basketModel.personInfo.address === undefined;
		}

		const basket = store.getters.getSelectableGroup('basket');
		basket.forEach((product) => {
			if (product.startsWith(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
				const d = store.getters.getCalculator(product)?.model;
				if (d?.readyForBasket) {
					this.modelAdultAccident.push(d);
				}
			}
		});
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public setSubHeader(evt) {
		if (evt.event.type === 'blur') {
			this.basketModel.contact_information.subtitle = evt.value;
		}
	}

	public get rkiHeader(): string {
		return this.hasCarInsurance ? this.basketCms.rkiHeaderDFIM : this.basketCms.rkiHeader;
	}

	public get rkiPopupTitle(): string {
		return this.hasCarInsurance ? this.basketCms.rkiPopupTitleDFIM : this.basketCms.rkiPopupTitle;
	}

	public get rkiPopover(): string {
		return this.hasCarInsurance ? this.basketCms.rkiPopoverDFIM : this.basketCms.rkiPopover;
	}

	public get rkiPopupContent(): string {
		return this.hasCarInsurance ? this.basketCms.rkiPopupContentDFIM : this.basketCms.rkiPopupContent;
	}

	public handleRkiClick(evt) {
		this.basketModel.contact_information.rki = evt.value;
		if (evt.value === 'ja' && this.rkiPopupTitle) {
			const popup = {
				title: this.rkiPopupTitle,
				content: this.rkiPopupContent,
				redirect: this.basketCms.rkiPopupRedirect,
				btnSecondLabel: this.basketCms.rkiPopupRedirect ? 'Afslut' : 'Ok',
				track: false,
				id: 'rki',
				show: true,
			};
			togglePopup(this.basketCms, popup);
		}
	}

	public checkboxEvent(evt) {
		this.basketModel.contact_information.accept = evt.checked;
	}

	public get showEmail(): boolean {
		if (this.basketModel.contact_information.customerNo) {
			return this.noEmailCustomer;
		}
		return true;
	}

	public get emailFilled(): boolean {
		return this.fieldValidator.isValidEmail(this.basketModel.contact_information.email);
	}

	public get addressFilled(): boolean {
		if (this.showAddress) {
			return this.basketModel.personInfo.address !== undefined;
		}
		return true;
	}

	public addressSearch(address) {
		if (address.matchText) {
			if (!this.isConsistentAddress(address?.data?.postnr)) {
				this.basketModel.pristineStep.set(this.card.name, false);
				return;
			}
			mapDawaAddress(this.basketModel.personInfo, address);
			this.basketModel.pristineStep.set(this.card.name, true);
		} else {
			resetAddress(this.basketModel.personInfo);
		}
	}

	private isConsistentAddress(newZipCode): boolean {
		const commonPersonInfo = store.getters.getCalculatorCommonModel.personInfo;

		if (!commonPersonInfo.zipCode) {
			return true;
		}

		const ok = newZipCode === commonPersonInfo.zipCode;
		if (!ok) {
			let msg = this.basketCms.wrongAddressPopupContent;
			msg = msg.replace('@zipTxt', commonPersonInfo.zipCode + ' ' + commonPersonInfo.zipName);
			const popup = {
				title: this.basketCms.wrongAddressPopuptitle,
				content: msg,
				btnSecondLabel: 'Ok',
				track: true,
				trackToken: 'addressMismatch',
				id: 'addressMismatch',
				show: true,
			};
			togglePopup(this.basketCms, popup);
			resetAddress(this.basketModel.personInfo);

			return false;
		}
		return true;
	}

	public get hasAdultCpr(): boolean {
		const ok = this.modelAdultAccident.length > 1;
		if (!ok && this.modelAdultAccident.length === 1) {
			return this.modelAdultAccident[0].personInfo.otherCustomerAge !== undefined;
		}
		return ok;
	}

	public get skaderOk() {
		return (
			this.basketModel.contact_information.skader &&
			this.basketCms.damagesOk.includes(this.basketModel.contact_information.skader)
		);
	}
	// public isValidAdultCpr(inx) : boolean {
	//     return this.fieldValidator.isValidCpr(this.modelAdultAccident[inx].personInfo.otherCpr);
	// }

	// public isValidChildCpr(inx) : boolean {
	//     return this.fieldValidator.getAgeUnderEighteen(this.modelChildren.familyInfo['cpr' + (inx)]) !== -1;
	// }

	public damagesEvent(evt) {
		this.basketModel.contact_information.skader = evt.value;
		if (this.basketCms.damagesPopupTitle && !this.basketCms.damagesOk.includes(evt.value)) {
			const popup = {
				title: this.basketCms.damagesPopupTitle,
				content: this.basketCms.damagesPopupContent,
				redirect: this.basketCms.damagesPopupRedirect,
				btnSecondLabel: this.basketCms.damagesPopupRedirect ? 'Afslut' : 'Ok',
				track: false,
				id: 'skader',
				show: true,
			};
			togglePopup(this.basketCms, popup);
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}
}
