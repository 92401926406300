import Coverage from '../components/coverageComponent/Coverage';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/services/environmentService';

// list and order of steps
export const STEPS: Array<string> = [
	STEP.CAR_INFO,
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class Model {
	public static model = {
		campaign: {
			ID: undefined, // lowercase i.e. diba
			valid: false,
			init: false,
			hasContentPage: false,
			subtitle: undefined,
			uniqueKey: undefined,
			discount: undefined,
			productStepDiscount: undefined,
		},
		carInfo: {
			subtitle: undefined,
			car: undefined,
			year: undefined,
			yearlyMileage: undefined,
			licenseplate: undefined,
			carWeight: 0,
			kid: 0,
			fuelType: undefined,
			isElectricHybrid: false,
			variantId: undefined,
			variantName: undefined,
			vehicleGroup: undefined,
			model: undefined,
			brand: undefined,
			displayText: undefined,
			searchBrandModel: false,
			checkForSpecialCar: false,
			isBrandModelSelected: false,
		},
		personInfo: {
			subtitle: undefined,
			carOwnerYears: undefined,
			carOwnerYearsByAgeArray: [],
			customerAge: undefined,
			address: undefined,
			zipCode: undefined,
			zipName: undefined,
			streetName: undefined,
			houseNr: undefined,
			floorNr: undefined,
			doorNr: undefined,
			kvhxValue: undefined,
			almbrandCustomer: undefined,
			existingAlmBrandProducts: [],
		},
		choosePackage: {
			subtitle: undefined,
			ownRiskId: undefined,
			selectedPackage: undefined,
			monthYear: undefined, // monthYearArray[0].value,
			useTiaOffer: false,
		},
		overview: {
			subtitle: undefined,
			highlights: undefined,
			details: undefined,
		},
		contact_information: {
			subtitle: undefined,
			name: undefined,
			email: undefined,
			phone: undefined,
			cpr: undefined,
			customerNo: undefined,
			accept: false,
		},
		additionalInfo: {
			subtitle: undefined,
			existInsurance: undefined,
			existingInsurance: undefined,
			existingInsuranceId: undefined,
			existFormerInsurance: undefined,
			formerInsurance: undefined,
			formerInsuranceId: undefined,
			validNow: undefined,
			validFromDate: undefined,
			skader: undefined,
			rki: undefined,
		},
		payment: {
			subtitle: undefined,
			regNo: undefined,
			kontoNo: undefined,
			userSelected: false,
		},
		pristineStep: new Map([
			[STEP.CAR_INFO.valueOf(), true],
			[STEP.PERSON_INFO.valueOf(), true],
			[STEP.PACKAGE.valueOf(), true],
			[STEP.ADDITIONAL_INFO.valueOf(), true],
			[STEP.OVERVIEW.valueOf(), true],
			[STEP.CONTACT_INFORMATION.valueOf(), true],
			[STEP.PAYMENT.valueOf(), true],
		]),
		productName: InsurancesProductEnum.BIL_FORSIKRING,
		ownRiskOptions: [] as Array<number>,
		ownRiskLabels: [] as Array<string>,
		updatedExcessListFromProduct: false,
		calculation: {
			isCalculated: false,
			updatePrices: ['pr1', 'pr2', 'pr3'],
			excessIdDefault: undefined,
			packageIds: undefined,
			packages: undefined,
			abCalc: {
				excesses: [],
				packs: new Map<number, Object>(),
			},
			allCoverages: new Map<number, Map<number, Array<Coverage>>>(),
			discount: undefined,
			discountDisplay: undefined,
			addressSupplement: 1,
		},
		modal: {
			show: false,
			id: 'none',
			track: false,
			trackToken: undefined,
			errorType: undefined,
			productName: undefined,
			stepName: undefined,
			trackMsg: undefined,
			content: undefined,
			btnStyle: undefined,
			btnLabel: undefined,
			btnAction: undefined,
			btnSecondStyle: undefined,
			btnSecondLabel: undefined,
			title: undefined,
			redirect: undefined,
		},
		modalId: 'none',
		showReceipt: false,
		currentCardName: 'none',
		updateAll: 'updateAll',
		readyForBasket: false,
		multipleProducts: false,
		showExistingAlmBrandProducts: true,
	};

	public static mock() {
		if (isDevelop) {
			Object.assign(Model.model.personInfo, {
				carOwnerYears: 'Har ikke haft bil før',
				// carOwnerYearsByAgeArray: [],
				address: 'Adolphsvej 19, 2820 Gentofte',
				almbrandCustomer: 'nej',
				customerAge: '45',
				existingAlmBrandProducts: [],
				houseNr: '19',
				streetName: 'Adolphsvej',
				subtitle: '45 år, Adolphsvej 19, 2820 Gentofte',
				zipCode: '2820',
				zipName: 'Gentofte',
				thatchedRoof: 'ja',
			});

			Object.assign(Model.model.carInfo, {
				subtitle: 'Suzuki Baleno (DM34004)',
				car: 'Suzuki Baleno (DM34004)',
				year: 2018,
				yearlyMileage: 'Op til 15.000 km.',
				licenseplate: 'DM34004',
				carWeight: 948,
				kid: 9000000002636721,
				fuelType: 'Benzin',
				isElectricHybrid: false,
				variantId: '20231784',
				variantName: '1,2 DUALJET 16V ACTIVE 90HK 5D',
				vehicleGroup: 106,
				model: 'Baleno',
				brand: 'Suzuki',
				displayText: 'Suzuki Baleno (DM34004) 2018 1,2 DUALJET 16V ACTIVE 90HK 5D',
				searchBrandModel: false,
				checkForSpecialCar: false,
				isBrandModelSelected: true,
			});
			Object.assign(Model.model.additionalInfo, {
				existInsurance: 'nej',
				existFormerInsurance: 'nej',
				formerInsurance: 'nej',
				validNow: 'ja',
				skader: '0',
				rki: 'nej',
			});
			Object.assign(Model.model.contact_information, {
				accept: true,
				cpr: '2342342323',
				customerNo: undefined,
				email: 'abklku@almbrand.dk',
				name: 'Test Testesen',
				phone: '11111111',
				subtitle: 'Test Testesen',
			});

			// Object.assign(Model.model.payment, {
			//     kontoNo:'123456',
			//     regNo:'1233',
			// });
		}
	}
}
