import { mapState } from 'vuex';
import FieldValidator from '@/services/fieldValidatorService';
import { FamilySettings } from '../FamilySettings';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import { jaNejArray, Validator, ValuePair } from '../../services/ValidationService';
import { getCalculatorNameUi, getShortProductName } from '../../services/ProductService';
import { togglePopup } from '../../services/AlertService';

@Options({
	name: 'AdditionalInfoStepComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
		existingInsuranceLabel: String,
		showValidNowOption: Boolean,
	},
})
export default class AdditionalInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete
	existingInsuranceLabel: string;
	showValidNowOption: boolean;

	public model!: any; // datastructure for user input (store)
	public cms!: FamilySettings; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;

	public jaNejArray: Array<ValuePair> = jaNejArray;
	public validNowArray: Array<ValuePair> = [
		{
			value: 'ja',
			displayValue: 'Hurtigst muligt',
		},
		{
			value: 'nej',
			displayValue: 'Vælg dato',
		},
	];
	public skaderOptions = ['0', '1', '2', '3+'];
	public skaderLabels = this.skaderOptions;

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public selectExistingInsuranceFamily(evt) {
		this.model.additionalInfo.existingInsurance = evt.selectedValue;
		const index = this.cms.insuranceCompanies.indexOf(evt.selectedValue);
		this.model.additionalInfo.existingInsuranceId = this.cms.insuranceCompaniesIds[index];
	}

	public selectExistingInsuranceChild(evt) {
		const id = evt.id.replace(/\D/g, ''); // find child ID
		this.model.familyInfo[evt.id] = evt.selectedValue;
		const index = this.cms.insuranceCompanies.indexOf(evt.selectedValue);
		this.model.familyInfo['existingInsuranceId' + id] = this.cms.insuranceCompaniesIds[index];
		this.model.familyInfo[evt.id] = evt.selectedValue;
	}

	private isValidFromFilled() {
		// TOOD KLK might not be needed anymore
		if (this.model.additionalInfo.validNow) {
			if (
				this.model.additionalInfo.validNow === 'nej' &&
				!this.fieldValidator.isValidFromDate(this.model.additionalInfo.validFromDate)
			) {
				return false;
			}
			if (!this.showPerson(this.model.familyInfo.personCountId + 1)) {
				return false;
			}
			return true;
		}
		return false;
	}

	public get showPersonsBlock() {
		if (
			this.model.additionalInfo.existInsurance === 'nej' ||
			(this.model.additionalInfo.existInsurance === 'ja' && this.model.additionalInfo.existingInsurance)
		) {
			return true;
		}
		return false;
	}

	public get isInsuranceInfoFilled() {
		if (!this.showValidNowOption) {
			return false;
		}
		if (!this.showPerson(this.model.familyInfo.personCountId + 1)) {
			return false;
		}

		return this.isValidFromFilled();
	}

	public showPerson(inx: number): boolean {
		if (!this.showValidNowOption) {
			return false;
		}
		if (inx === 0) {
			return true;
		}
		const inxPrev = inx - 1;
		if (this.model.familyInfo['existInsurance' + inxPrev] === undefined) {
			return false;
		}
		if (this.model.familyInfo['existInsurance' + inxPrev] === 'ja') {
			if (this.model.familyInfo['existingInsurance' + inxPrev] === undefined) {
				return false;
			}
		}
		return true;
	}

	public handleRadioClick(evt: any) {
		const propName = evt.name ? evt.name : evt.id;
		const cleanedName = propName.replace(/[0-9]/g, '');

		switch (cleanedName) {
			case 'existInsurance':
				if (propName === cleanedName) {
					this.model.additionalInfo.existInsurance = evt.value;
				} else {
					this.model.familyInfo[propName] = evt.value;
				}
				break;
			case 'skader':
				this.model.additionalInfo.skader = evt.value;

				if (this.cms.damagesPopupTitle && !this.cms.damagesOk.includes(evt.value)) {
					const popup = {
						title: this.cms.damagesPopupTitle,
						content: this.cms.damagesPopupContent,
						redirect: this.cms.damagesPopupRedirect,
						btnSecondLabel: this.cms.damagesPopupRedirect ? 'Afslut' : 'Ok',
						track: false,
						id: 'skader',
						show: true,
					};
					togglePopup(this.cms, popup);
				}
				break;
			case 'rki':
				this.model.additionalInfo.rki = evt.value;
				if (evt.value === 'ja' && this.cms.rkiPopupTitle) {
					const popup = {
						title: this.cms.rkiPopupTitle,
						content: this.cms.rkiPopupContent,
						redirect: this.cms.rkiPopupRedirect,
						btnSecondLabel: this.cms.rkiPopupRedirect ? 'Afslut' : 'Ok',
						track: false,
						id: 'rki',
						show: true,
					};
					togglePopup(this.cms, popup);
				}
				break;
			case 'validNow':
				this.model.additionalInfo.validNow = evt.value;
				if (this.model.additionalInfo.validNow === 'nej') {
					setTimeout(() => {
						const input = document.getElementById('validFromDate');
						if (input) {
							input.focus();
						}
					}, this.cms.initialTimeout);
				}
				break;
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}

	public get skaderOk() {
		return this.model.additionalInfo.skader && this.cms.damagesOk.includes(this.model.additionalInfo.skader);
	}

	public getNextCardLinkText(): string {
		// check if there is multiple products (Basket)
		if (this.model.multipleProducts) {
			const res = store.getters.getSelectableGroup('basket');
			const activeIndex = store.getters.getActiveCalculatorInx;
			let foundNextProduct = undefined;
			res.forEach((productName) => {
				if (!foundNextProduct && this.model.productName + activeIndex !== productName) {
					const product = store.getters.getCalculator(productName);
					if (!product || !product.model.readyForBasket) {
						if (screen.width < 425) {
							productName = getShortProductName(productName);
						}
						foundNextProduct = 'Fortsæt til ' + getCalculatorNameUi(productName);
					}
				}
			});
			if (foundNextProduct) {
				return foundNextProduct;
			}
			return 'Gå til Kurv';
		} else {
			return this.card.linkText;
		}
	}
	public getName(inx: number): string {
		return this.model.familyInfo['name' + inx];
	}

	public getExistingInsuranceLabel(inx: number): string {
		return (
			'Er der en eksisterende Ulykkesforsikring for <b>' +
			this.model.familyInfo['name' + inx] +
			'</b>, som vi skal opsige?'
		);
	}
}
