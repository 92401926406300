import BuyDog from './BuyDogComponent';
import { cloneDeep } from 'lodash';

import AxiosService from '@/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	getDiscount,
	getRisksForPackage,
	mapCoveragesFromProductConfig,
	mapPriceResult,
	reducePack,
	setPrices,
} from '../services/CalculationService';
import { monthYearArray } from '../services/ValidationService';
import { togglePopup } from '../services/AlertService';

export default class DogCalculator {
	private model: any; // the data structure for state and properties
	private cms: any; // settings from EPI
	private calcConfig: any;
	// used for first three calculations, to see if there's known error codes
	private successCalc = true;
	private axiosService: AxiosService;
	private calculatorUrl: string;
	private calculatorInfo: Array<any>;

	constructor(buyDog: BuyDog) {
		this.model = buyDog.model;
		this.cms = buyDog.cms;
		this.calculatorInfo = JSON.parse(buyDog.calculatorInfoBlock.markup);

		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/dog/quotes';
		this.axiosService = new AxiosService(this.calculatorUrl);
		this.setUpConfig();
	}

	public async setUpConfig() {
		if (!this.calcConfig) {
			if (this.model.campaign.valid) {
				this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
			}

			const calculatorInfo = this.calculatorInfo.find(
				(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId
			);

			this.calcConfig = calculatorInfo.insuranceConfiguration;
		}
		this.model.calculation.abCalc = {
			excessIdDefault: this.calcConfig.excessIdDefault,
			packageIds: cloneDeep(this.calcConfig.packageIds),
			packages: cloneDeep(this.calcConfig.packages),
			coveragesDetails: this.calcConfig.coverages,
			// TODO KLK from config
			dogCountOptions: ['1', '2', '3'], // dogcount
			dogCountLabels: ['1', '2', 'Mere end 2'],
		};

		if (!this.cms.alwaysUseSickAccident && getDiscount(this.cms).discount === 1) {
			// ikke pluskunde, fjern Stor pakke
			this.model.calculation.abCalc.packages = this.model.calculation.abCalc.packages.filter(
				(pack) => pack.id !== this.cms.sickAccidentpackageId
			);
			this.model.calculation.abCalc.packageIds = this.model.calculation.abCalc.packageIds.filter(
				(id) => id !== this.cms.sickAccidentpackageId
			);
		}

		this.setupExcessList();

		this.model.calculation.abCalc.packages.forEach((pack) => {
			pack.coverages = [];
			if (this.cms.extendCoverages) {
				pack.includedCoverageIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
				pack.expandedCoverages = [];
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
				});
			} else {
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
			}
		});
		this.model.calculation.abCalc.packs = new Map<number, Object>();
		this.model.calculation.abCalc.excesses.forEach((excess) => {
			const calcs = [];
			this.model.calculation.abCalc.packs.set(excess.id, calcs);
			this.model.calculation.abCalc.packages.forEach((pack) => {
				calcs.push(reducePack(cloneDeep(pack)));
			});
		});
		this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

		mapCoveragesFromProductConfig(this.model, this.cms);
		this.updateCalcKeys();
	}

	public setupExcessList() {
		// hardcode dummy excess
		this.model.calculation.abCalc.excesses = [
			{
				// "amount": 3229,
				id: -1,
				minCustomerAge: 18,
			},
		];
		this.model.ownRiskOptions = [];
		this.model.ownRiskLabels = [];
	}

	private checkFirstPackagePrices(excessId: number) {
		let ok = true;
		const packages = this.model.calculation.abCalc.packs.get(excessId);
		packages.forEach((calc) => {
			if (!calc.totalPrice) {
				ok = false;
			}
		});
		return ok;
	}

	private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
		try {
			this.successCalc = true;

			const rebate = getDiscount(this.cms);
			this.model.calculation.discount = rebate.discount;
			this.model.calculation.discountDisplay = rebate.discountDisplay;

			const promises = [];
			for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
				const packageId = this.model.calculation.abCalc.packageIds[index];
				promises.push(this.onePackageCalcEpiNew(monthly, excessId, packageId));
			}
			await Promise.all(promises);
			return Promise.resolve(this.successCalc);
		} catch (ex) {
			console.error(ex);
			if (monthly) {
				this.errorPopUp(400);
			}
			return Promise.resolve(false);
		}
	}

	public async getCalculations(): Promise<boolean> {
		if (!this.model.calculation.isCalculated) {
			store.state.showSpinner = true;
			store.state.calculatorContext.calculating = true;
			// handle "eternal" spinning
			setTimeout(() => {
				if (store.state.showSpinner) {
					store.state.showSpinner = false;
					store.state.calculatorContext.calculating = false;
				}
			}, this.cms.calculationSpinnerTimeout);
		}

		// reset prices
		const pack = this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault);
		pack.totalPriceDisplay = undefined;
		return this.getEpiCalculations();
	}

	private async getEpiCalculations(): Promise<boolean> {
		// reset calculation
		await this.setUpConfig();

		let success = true;

		this.ensureCorrectDates();
		success = await this.calculatePackagesEpi(true, this.model.calculation.abCalc.excessIdDefault);
		// check if  has got prices
		if (!success) {
			success = this.checkFirstPackagePrices(this.model.calculation.abCalc.excessIdDefault);
		}
		if (success) {
			this.calculatePackagesEpi(false, this.model.calculation.abCalc.excessIdDefault);
		}
		this.model.calculation.isCalculated = success;
		store.state.showSpinner = false;
		store.state.calculatorContext.calculating = false;

		// triggerCustomGtmEvent({
		//     'event': 'track-vp',
		//     'virtualPath': `${getTrackingPreUrl()}${this.model.productName}/prices_shown`,
		// });

		// this.doCallTest();
		return success;
	}
	/**
	 * make sure dates are in format dd-MM-yyyy for api calculation
	 */
	private ensureCorrectDates() {
		try {
			for (let i = 1; i < 3; i++) {
				// dog one and two
				if (this.model.dogInfo['birthdate' + i]) {
					this.model.dogInfo['birthdate' + i] = this.model.dogInfo['birthdate' + i]
						.trim()
						.replace(/\D/g, '-');
				}
			}
		} catch (e) {
			// move on - will fail in calculation
		}
	}

	private async onePackageCalcEpiNew(monthly: boolean, excessId: number, packageId: number) {
		let dogCount = this.model.dogInfo.dogCount;

		let responseSplit = undefined;
		if (dogCount > 1 && packageId === this.cms.sickAccidentpackageId) {
			dogCount = 1;
			// lav 2 requests og læg tal sammen på response
			// kan kun tilbyde syge- ulykkedækning til pluskunder
			responseSplit = await this.axiosService.getRetryDefaultUrl(
				this.createParamsEpiNew(
					excessId,
					packageId,
					monthly ? 1 : 0,
					this.model.dogInfo.gender2,
					dogCount,
					this.model.dogInfo.dogTypeId2,
					this.model.dogInfo.birthdate2
				)
			);
		}

		let response = undefined;
		if (responseSplit === undefined || responseSplit.status === 200) {
			response = await this.axiosService.getRetryDefaultUrl(
				this.createParamsEpiNew(
					excessId,
					packageId,
					monthly ? 1 : 0,
					this.model.dogInfo.gender1,
					dogCount,
					this.model.dogInfo.dogTypeId1,
					this.model.dogInfo.birthdate1
				)
			);
		}

		// response = JSON.parse(JSON.stringify(DogTestCalculation));

		const responseAny = response || responseSplit;

		if (responseAny?.status !== 200) {
			this.successCalc = false;
			// this.model.modal.content = response.data.warningMessage ? response.data.warningMessage : this.cms.defaultCalcErrorContent;
			this.errorPopUp(responseAny?.status);
			return Promise.resolve(false);
		}
		mapPriceResult(response);
		// Hack calculator is calculating wrong!!!!
		this.handleSpecialFeeSickDisease(packageId, response);
		// put results from two calculations together, if syge- ulykkedækning and 2 dogs in calculation
		if (responseSplit) {
			mapPriceResult(responseSplit);
			this.handleSpecialFeeSickDisease(packageId, responseSplit);
			response.data.specialFee += responseSplit.data.specialFee;
			response.data.totalPrice += responseSplit.data.totalPrice;
			response.data.statutoryFee += responseSplit.data.statutoryFee;
			response.data.rebatePrice += responseSplit.data.rebatePrice;
		}

		const pack = this.model.calculation.abCalc.packs.get(excessId);
		setPrices(packageId, response, monthly, pack, this.model, this.cms);

		this.updateCalcKeys();
		return Promise.resolve(true);
	}

	private errorPopUp(status) {
		const popup: any = {
			content: this.cms.defaultCalcErrorContent,
			track: true,
			btnSecondLabel: 'Ok',
			id: status === 250 ? 'calcWarning' : 'calcError',
			title: this.cms.defaultCalcErrorTitle,
			trackToken: 'Dog calculator',
			errorType: status ? status + '' : undefined,
			show: true,
		};
		if (this.cms.useBtnInlineFail) {
			popup.btnInlineLabel = this.cms.btnInlineFailLabel;
			popup.btnInlineStyle = this.cms.btnInlineFailStyle;
			popup.btnInlineIcon = this.cms.btnInlineIcon;
			popup.btnInlineAction = () => {
				this.getCalculations();
			};
		}

		togglePopup(this.cms, popup);
	}

	private handleSpecialFeeSickDisease(packageId: number, response: any) {
		// Passer ved 10 og 15% uden modifikationer
		if (packageId === this.cms.sickAccidentpackageId) {
			//const sickDiseaseRisk = response.data.productPrices.find( risk => risk.risk_id === 4);
			const sickDiseaseRisk = response.data.quotes[0].selected_risks.find((risk) => risk.risk_id === 4);

			if (!sickDiseaseRisk) {
				return;
			}
			const specialFee = sickDiseaseRisk.price * this.cms.sickDiseasePK1Fee; // is 21%, should be 20%, but includes the 5% pluscustomer
			response.data.specialFee = specialFee;
			// console.log('sickDiseaseRisk.price', sickDiseaseRisk.price, 'this.cms.sickDiseasePK1Fee', this.cms.sickDiseasePK1Fee, 'specialFee', specialFee);
			// console.log('totalPrice', (response.data.totalPrice-response.data.statutoryFee)/12);
			// console.log('specialFee', response.data.specialFee/12);
			// console.log('all', ((response.data.totalPrice-response.data.statutoryFee) + response.data.specialFee) /12);
		}
	}

	private createParamsEpiNew(
		excessId: number,
		packageId: number,
		monthlyPrices: number,
		gender: string,
		dog_count: number,
		dogTypeId: number,
		birthdate: string
	) {
		const params = {
			dog_count,
			gender, // T, H, U -> TÆVE, HAN, Uoplyst
			birthdate, // : '13-01-2019', // dd-MM-yyyy
			race_id: dogTypeId, //'20',
			plus_customer: 'PK0',
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: getRisksForPackage(packageId, this.model),
			ts: new Date().getTime(),
			// optional:''
		};
		if (this.cms.plusCustomerCalculation) {
			params.plus_customer = 'PK1'; // 5%
		}
		// monthlyPrices: `${monthlyPrices}`,
		// calculatorBlockId: this.cms.oldCalculatorBlockId,

		// excessId: `${excessId}`,  ikke selvrisiko
		//extendedPackage: `${packageId}`,

		return params;
	}

	public updateCalcKeys() {
		this.model.calculation.updatePrices = this.model.calculation.updatePrices.map((elem) => {
			return (elem += '1');
		});
	}

	// private prices: Map<string, Array<string>>;
	// private async testDogPrices(from, to, monthly) {
	//     const packageId = parseInt(this.cms.sickAccidentpackageId);
	//     const promises = [];
	//     console.log('running', from, to);
	//     const dogList = this.cms.dogList.slice(from, to);
	//     dogList.forEach((_dogType) => {
	//         const dogType = _dogType;
	//         const dogTypeId = this.cms.dogListIds[ this.cms.dogList.indexOf(_dogType)];
	//         promises.push(this.abAxiosCalculator.getRetry(
	//                 this.cms.oldCalculatorPrices,
	//                 this.createParamsEpi(-1, packageId, monthly, this.model.dogInfo.gender1, 1, dogTypeId, this.model.dogInfo.birthdate1)
	//             ).then( response => {
	//                 try {
	//                     let arr = this.prices.get(response.data.productPrices[2].price + '');
	//                     if (!arr) {
	//                         arr = [];
	//                         this.prices.set(response.data.productPrices[2].price + '', arr);
	//                     }
	//                     arr.push(dogTypeId + ', ' + dogType)
	//                     console.log(response.data.productPrices[2].price, dogTypeId, dogType);
	//                 } catch(e) {
	//                     console.error('failed', dogTypeId, dogType);
	//                 }
	//         }));
	//     });
	//     return await Promise.all(promises);
	// }

	// private async doCallTest() {
	//     this.prices = new Map();
	//     // 298
	//     const monthly = 1; // yearly = 0, monthly = 1
	//     await this.testDogPrices(0,20, monthly);
	//     await this.testDogPrices(20,40, monthly);
	//     await this.testDogPrices(40,60, monthly);
	//     await this.testDogPrices(60,80, monthly);
	//     await this.testDogPrices(80,100, monthly);
	//     await this.testDogPrices(100,120, monthly);
	//     await this.testDogPrices(120,140, monthly);
	//     await this.testDogPrices(140,160, monthly);
	//     await this.testDogPrices(160,180, monthly);
	//     await this.testDogPrices(180,200, monthly);
	//     await this.testDogPrices(200,220, monthly);
	//     await this.testDogPrices(240,260, monthly);
	//     await this.testDogPrices(260,280, monthly);
	//     await this.testDogPrices(280,298, monthly);
	//     console.log(this.prices);

	// }
}
