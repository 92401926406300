import BuyAccidentChildren from './BuyAccidentChildrenComponent';
import { productId } from './AccidentChildrenSettings';
import { cloneDeep } from 'lodash';
import store from '@/store/store';
import AxiosService from '@/services/axiosService';
import { defaultConfig } from '@/cms/api/ApiConfig';
import { getDiscount, mapCoveragesFromProductConfig, reducePack } from '../services/CalculationService';
import { Formatter, monthYearArray } from '../services/ValidationService';
import { togglePopup } from '../services/AlertService';
import { isDevelop } from '@/services/environmentService';

export default class AccidentCalculator {
	private axiosService: AxiosService;
	private buyAccident: BuyAccidentChildren; // component using calculator
	private model: any; // the data structure for state and properties
	private cms: any; // settings from EPI
	private calcConfig: any;
	private errorPopup: any;
	private calculatorInfo: Array<any>;

	constructor(buyAccident: BuyAccidentChildren) {
		this.buyAccident = buyAccident;
		this.model = buyAccident.model;
		this.cms = buyAccident.cms;
		this.calculatorInfo = JSON.parse(buyAccident.calculatorInfoBlock.markup);

		this.errorPopup = {
			id: 'calcError',
			title: this.cms.defaultCalcErrorTitle,
			content: this.cms.defaultCalcErrorContent,
			btnSecondLabel: 'Ok',
			track: true,
			trackToken: 'child accident calculator',
			show: true,
		};

		if (this.cms.useBtnInlineFail) {
			this.errorPopup.btnInlineLabel = this.cms.btnInlineFailLabel;
			this.errorPopup.btnInlineStyle = this.cms.btnInlineFailStyle;
			this.errorPopup.btnInlineIcon = this.cms.btnInlineIcon;
			this.errorPopup.btnInlineAction = () => {
				this.getCalculations();
			};
		}

		const ulykkeCalculatorUrl =
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/accident/offers';
		this.axiosService = new AxiosService(ulykkeCalculatorUrl);

		this.setUpConfig();
	}

	public async setUpConfig() {
		if (!this.calcConfig) {
			if (this.model.campaign.valid) {
				this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
			}
			const calculatorInfo = this.calculatorInfo.find(
				(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId
			);
			this.calcConfig = calculatorInfo.insuranceConfiguration;
		}
		this.model.calculation.abCalc = {
			excessIdDefault: this.calcConfig.excessIdDefault,
			packageIds: cloneDeep(this.calcConfig.packageIds),
			packages: cloneDeep(this.calcConfig.packages),
			coveragesDetails: this.calcConfig.coverages,
		};

		this.setupExcessList();

		this.model.calculation.abCalc.packages.forEach((pack) => {
			pack.coverages = [];
			if (this.cms.extendCoverages) {
				pack.includedCoverageIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
				pack.expandedCoverages = [];
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
				});
			} else {
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
			}
		});
		this.model.calculation.abCalc.packs = new Map<number, Object>();
		this.model.calculation.abCalc.excesses.forEach((excess) => {
			const calcs = [];
			this.model.calculation.abCalc.packs.set(excess.id, calcs);
			this.model.calculation.abCalc.packages.forEach((pack) => {
				calcs.push(reducePack(cloneDeep(pack)));
			});
		});
		this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'
		mapCoveragesFromProductConfig(this.model, this.cms);
		this.updateCalcKeys();
	}

	public setupExcessList() {
		// hardcode dummy excess
		this.model.calculation.abCalc.excesses = [
			{
				// "amount": 3229,
				id: -1,
				minCustomerAge: 18,
			},
		];
		this.model.ownRiskOptions = [];
		this.model.ownRiskLabels = [];
	}

	public async getCalculations(excessId?: number): Promise<boolean> {
		if (!this.model.calculation.isCalculated) {
			store.state.showSpinner = true;
			store.state.calculatorContext.calculating = true;
			// handle "eternal" spinning
			setTimeout(() => {
				if (store.state.showSpinner) {
					store.state.showSpinner = false;
					store.state.calculatorContext.calculating = false;
				}
			}, this.cms.calculationSpinnerTimeout);
		}

		// reset calculation
		await this.setUpConfig();
		// this.model.calculation.abCalc.calculated = true;
		try {
			const rebate = getDiscount(this.cms);
			this.buyAccident.model.calculation.discount = rebate.discount;
			this.buyAccident.model.calculation.discountDisplay = rebate.discountDisplay;

			const promises = [];
			this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
				calc.summedBasePrice = 0;
				calc.summedStatutoryFee = 0; // not returned by api
				calc.summedTotalPrice = 0;

				calc.summedYearlyBasePrice = 0;
				calc.summedYearlyStatutoryFee = 0; // not returned by api
				calc.summedYearlyPriceTotal = 0;
			});
			for (let i = 0; i <= this.model.familyInfo.personCountId; i++) {
				const age = this.model.familyInfo['age' + i];
				const workId = this.model.familyInfo['workId' + i];

				this.model.calculation.abCalc.packages.forEach((pack) => {
					const features = this.getFeatures(pack);
					promises.push(this.calculateQuickQuote(pack.name, age, features, workId));
				});
			}
			await Promise.all(promises);
			this.sumUpPrices();
			this.model.calculation.abCalc.calculated = true;

			this.model.calculation.isCalculated = true;
			this.updateCalcKeys();
			store.state.showSpinner = false;
			store.state.calculatorContext.calculating = false;

			// triggerCustomGtmEvent({
			//     'event': 'track-vp',
			//     'virtualPath': `${getTrackingPreUrl()}${this.model.productName}/prices_shown`,
			// });
			return Promise.resolve(true);
		} catch (err) {
			console.error('Accident offer', err);
			// triggerCustomGtmEvent({
			//     'event': 'track-vp',
			//     'virtualPath': `${getTrackingPreUrl()}${this.model.productName}/error/tiaonlineoffer`,
			// });

			togglePopup(this.cms, this.errorPopup);
			store.state.showSpinner = false;
			store.state.calculatorContext.calculating = false;
			return Promise.resolve(false);
		}
	}

	private sumUpPrices() {
		this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
			// console.log('calc', calc);
			calc.basePrice = calc.summedBasePrice;
			calc.totalPrice = calc.summedTotalPrice;
			calc.yearlyBasePrice = calc.summedYearlyBasePrice;
			calc.yearlyPriceTotal = calc.summedYearlyPriceTotal;
			calc.totalPriceDisplay = Formatter.format(calc.summedTotalPrice) + ' kr.';
			calc.yearlyPriceTotalDisplay = Formatter.format(calc.summedYearlyPriceTotal) + ' kr.';
		});
	}

	private async calculateQuickQuote(
		productType: string,
		childAge: string,
		features: Array<any>,
		workId: string
	): Promise<any> {
		let customerAge = parseInt(childAge);
		if (customerAge < this.cms.minChildAge) {
			customerAge = this.cms.minChildAge;
		}
		if (!features) {
			return Promise.reject();
		}
		const createOffer: any = {
			product: {
				product_name: productId,
				features,
				parameters: {
					age: customerAge,
					postalCode: this.model.personInfo.zipCode,
				},
			},
		};

		workId ? (createOffer.product.parameters.employment = workId) : undefined;

		try {
			const res = await this.axiosService.postRetry(undefined, createOffer);
			const offer = res.data;
			if (!offer || !offer.price || !offer.price.annually) {
				throw new Error();
			}
			this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
				if (calc.name === productType) {
					let totalPrice = offer.price.monthly;
					calc.basePrice = totalPrice;

					calc.statutoryFee = 0; // not returned by api

					// pluscustomer discount
					let discount = this.model.calculation.discount;
					if (this.model.campaign.valid) {
						discount -= this.model.campaign.discount;
					}

					totalPrice *= discount;

					// totalPrice *= this.buyAccident.model.calculation.discount;

					// // campaign discount
					// if (this.model.campaign.valid)  {
					//     totalPrice -= (totalPrice * this.model.campaign.discount);
					// }

					calc.totalPrice = Math.round(totalPrice);

					totalPrice = offer.price.annually;
					calc.yearlyBasePrice = totalPrice;
					calc.yearlyStatutoryFee = 0; // not returned by api

					// pluscustomer discount
					totalPrice *= discount;
					// totalPrice *= this.model.calculation.discount;

					// // campaign discount
					// if (this.model.campaign.valid)  {
					//     totalPrice -= (totalPrice * this.model.campaign.discount);
					// }

					calc.yearlyPriceTotal = Math.round(totalPrice);

					calc.summedBasePrice += calc.basePrice;
					calc.summedStatutoryFee = 0; // not returned by api
					calc.summedTotalPrice += calc.totalPrice;

					calc.summedYearlyBasePrice += calc.yearlyBasePrice;
					calc.summedYearlyPriceTotal += calc.yearlyPriceTotal;
					calc.summedYearlyStatutoryFee = 0; // not returned by api
				}
			});

			return Promise.resolve();
		} catch (err) {
			console.error(err);
			togglePopup(this.cms, this.errorPopup);
			if (isDevelop && this.cms.mockData) {
				// MOCK
				console.warn('USING MOCK DATA!');

				let add = 2000;
				this.model.calculation.abCalc.packs
					.get(this.model.calculation.abCalc.excessIdDefault)
					.forEach((calc) => {
						add += 100;
						if (calc.name === productType) {
							let totalPrice = add;
							calc.basePrice = totalPrice;
							calc.statutoryFee = 0; // not returned by api
							if (this.model.campaign.valid) {
								totalPrice -= totalPrice * this.model.campaign.discount;
							}

							calc.totalPrice = Math.round(totalPrice * this.model.calculation.discount);
							calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

							totalPrice = 12 * add * 0.97;
							calc.yearlyBasePrice = totalPrice;
							calc.yearlyStatutoryFee = 0; // not returned by api

							if (this.model.campaign.valid) {
								totalPrice -= totalPrice * this.model.campaign.discount;
							}
							calc.yearlyPriceTotal = Math.round(totalPrice * this.model.calculation.discount);
							calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
							this.updateCalcKeys();
						}
					});
			} else {
				return Promise.reject(err);
			}
		}
	}

	private getFeatures(pack) {
		const features = [];
		pack.includedCoveragesExpandedIds.forEach((riskId) => {
			const cov = cloneDeep(
				this.model.calculation.abCalc.coveragesDetails.filter((coverage) => riskId === coverage.riskId)[0]
			);
			cov.name = cov.riskId;
			features.push(cov);
		});
		return features;
	}

	public updateCalcKeys() {
		this.model.calculation.updatePrices = this.model.calculation.updatePrices.map((elem) => {
			return (elem += '1');
		});
	}
}
