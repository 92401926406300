import BuyFamily from './BuyFamilyComponent';
import { cloneDeep } from 'lodash';
import { productAccidentId } from './FamilySettings';
import AxiosService from '@/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import Coverage from '../components/coverageComponent/Coverage';
import { getDiscount, getRisksForPackage, mapPriceResult, reducePack, setPrices } from '../services/CalculationService';
import { Formatter, hasAddressSupplement, monthYearArray } from '../services/ValidationService';
import { togglePopup } from '../services/AlertService';
import { isDevelop } from '@/services/environmentService';
import { updateExcessesFromCalculation } from '../services/ProductService';

export default class FamilyCalculator {
	private model: any; // the data structure for state and properties
	private cms: any; // settings from EPI
	private calcConfigHome: any;
	// used for first three calculations, to see if there's known error codes
	private successCalc = true;
	private axiosAccidentService: AxiosService;
	private axiosService: AxiosService;
	private errorPopup: any;
	private calculatorInfo: Array<any>;
	private calcConfigAccident: any;

	constructor(buyFamily: BuyFamily) {
		this.model = buyFamily.model;
		this.cms = buyFamily.cms;
		this.calculatorInfo = JSON.parse(buyFamily.calculatorInfoBlock.markup);

		this.errorPopup = {
			id: 'calcError',
			title: this.cms.defaultCalcErrorTitle,
			content: this.cms.defaultCalcErrorContent,
			btnSecondLabel: 'Ok',
			track: true,
			trackToken: 'Family calculator',
			show: true,
		};
		if (this.cms.useBtnInlineFail) {
			this.errorPopup.btnInlineLabel = this.cms.btnInlineFailLabel;
			this.errorPopup.btnInlineStyle = this.cms.btnInlineFailStyle;
			this.errorPopup.btnInlineIcon = this.cms.btnInlineIcon;
			this.errorPopup.btnInlineAction = () => {
				this.getCalculations();
			};
		}

		// ulykke
		const ulykkeCalculatorUrl =
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/accident/offers';
		this.axiosAccidentService = new AxiosService(ulykkeCalculatorUrl);
		// indbo
		const indboCalculatorUrl =
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/home/quotes';
		this.axiosService = new AxiosService(indboCalculatorUrl);
		this.setUpConfig();
	}

	public async setUpConfig() {
		if (!this.calcConfigHome) {
			if (this.model.campaign.valid) {
				this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
			}

			const calculatorInfo = this.calculatorInfo.find(
				(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId
			);

			this.calcConfigHome = calculatorInfo.insuranceConfiguration;

			this.calcConfigHome.insuredValueOptions = calculatorInfo.insuredValueOptions;
			this.calcConfigHome.familyMemberCountOptions = [];
			this.calcConfigHome.familyMemberCountOptions = calculatorInfo.familyMemberCountOptions;

			this.model.calculation.abCalc = {
				excessIdDefault: this.calcConfigHome.excessIdDefault,
				packageIds: this.calcConfigHome.packageIds,
				packages: cloneDeep(this.calcConfigHome.packages),
				coveragesDetails: this.calcConfigHome.coverages,
				insuredValueOptions: this.calcConfigHome.insuredValueOptions,
				familyMemberCountOptions: this.calcConfigHome.familyMemberCountOptions,
			};

			this.setupExcessList();
			this.model.calculation.abCalc.packages.forEach((pack, inx) => {
				pack.coverages = [];
				pack.expandedCoverages = [];
				pack.includedCoverageIds.forEach((riskId) => {
					const coverage = this.calcConfigHome.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfigHome.coverages.find((x) => riskId === x.riskId);
					pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
				});
				this.cms.mapCoverageGroups(pack.coverages);
			});

			this.model.calculation.abCalc.packs = new Map<number, Object>();
			this.model.calculation.abCalc.excesses.forEach((excess) => {
				const calcs = [];
				this.model.calculation.abCalc.packs.set(excess.id, calcs);
				this.model.calculation.abCalc.packages.forEach((pack) => {
					calcs.push(reducePack(cloneDeep(pack)));
				});
			});
			this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

			await this.setupAccident();

			this.mapCoveragesFromCalc();
		}
		this.updateCalcKeys();
	}

	// Accident
	private async setupAccident() {
		if (!this.calcConfigAccident) {
			if (this.model.campaign.valid) {
				this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
			}
			const calculatorInfo = this.calculatorInfo.find(
				(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId + '_CHILD_ACCIDENT'
			);
			this.calcConfigAccident = calculatorInfo.insuranceConfiguration;
		}
		this.model.calculationAccident.abCalc = {
			excessIdDefault: this.calcConfigAccident.excessIdDefault,
			packageIds: cloneDeep(this.calcConfigAccident.packageIds),
			packages: cloneDeep(this.calcConfigAccident.packages),
			coveragesDetails: this.calcConfigAccident.coverages,
		};

		this.setupAccidentExcessList();

		this.model.calculationAccident.abCalc.packages.forEach((pack) => {
			pack.coverages = [];
			if (this.cms.extendCoverages) {
				pack.includedCoverageIds.forEach((riskId) => {
					const coverage = this.calcConfigAccident.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
				pack.expandedCoverages = [];
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfigAccident.coverages.find((x) => riskId === x.riskId);
					pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
				});
			} else {
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfigAccident.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
			}
			this.cms.mapCoverageGroups(pack.coverages);
		});
		this.model.calculationAccident.abCalc.packs = new Map<number, Object>();
		this.model.calculationAccident.abCalc.excesses.forEach((excess) => {
			const calcs = [];
			this.model.calculationAccident.abCalc.packs.set(excess.id, calcs);
			this.model.calculationAccident.abCalc.packages.forEach((pack) => {
				calcs.push(reducePack(cloneDeep(pack)));
			});
		});
		this.mapAccidentCoveragesFromProductConfig(this.model, this.cms);
	}
	private mapAccidentCoveragesFromProductConfig(model, cms) {
		model.calculationAccident.allCoverages = new Map<number, Map<number, Array<Coverage>>>();
		model.calculationAccident.abCalc.packs.forEach((calculations, ownRiscId) => {
			const coveragesForPackage = new Map<number, Array<Coverage>>();
			calculations.forEach((calc, packageInx) => {
				const coverages: Array<Coverage> = [];
				calc.coverages.forEach((coverage) => {
					const coverage_ = new Coverage(
						coverage.riskId + '',
						cms.getCoverageName(coverage.name),
						cms.getCoverageName(coverage.name),
						cms.getCoverageDescription(coverage.name, coverage.description)
					);
					coverages.push(coverage_);
				});
				coveragesForPackage.set(packageInx, coverages);
			});
			model.calculationAccident.allCoverages.set(ownRiscId, coveragesForPackage);
		});
	}

	public setupAccidentExcessList() {
		// hardcode dummy excess
		this.model.calculationAccident.abCalc.excesses = [
			{
				id: -1,
				minCustomerAge: 0,
			},
		];
	}

	private mapCoveragesFromCalc() {
		if (!this.model.calculation.allCoverages || this.model.calculation.allCoverages.size === 0) {
			this.model.calculation.allCoverages = new Map<number, Map<number, Array<Coverage>>>();
			this.model.calculation.abCalc.packs.forEach((calculations, ownRiscId) => {
				const coveragesForPackage = new Map<number, Array<Coverage>>();
				calculations.forEach((calc, packageInx) => {
					let coveragesForPackageTmp: Array<Coverage> = [];
					calc.coverages.forEach((coverage) => {
						const coverage_ = new Coverage(
							coverage.riskId + '',
							this.cms.getCoverageName(coverage.name),
							this.cms.getCoverageName(coverage.name),
							this.cms.getCoverageDescription(coverage.name, coverage.description)
						);
						coveragesForPackageTmp.push(coverage_);
					});
					// add accident covs
					const accidentCovs = this.model.calculationAccident.allCoverages
						.get(this.model.calculationAccident.abCalc.excessIdDefault)
						.get(packageInx);
					calc.coverages = accidentCovs.concat(calc.coverages); // add to product coverages
					coveragesForPackageTmp = accidentCovs.concat(coveragesForPackageTmp);
					coveragesForPackage.set(packageInx, coveragesForPackageTmp);
					const ulykkeExpanded = this.model.calculationAccident.abCalc.packs.get(
						this.model.calculationAccident.abCalc.excessIdDefault
					)[packageInx].expandedCoverages;
					calc.expandedCoverages = [...ulykkeExpanded, ...calc.expandedCoverages];
				});
				this.model.calculation.allCoverages.set(ownRiscId, coveragesForPackage);
			});
		}
	}

	public setupExcessList() {
		this.model.calculation.abCalc.excesses = this.calcConfigHome.excessList;
		this.model.ownRiskOptions = [];
		this.model.ownRiskLabels = [];

		this.model.calculation.abCalc.excesses.forEach((excess) => {
			if (this.model.personInfo.customerAge >= excess.minCustomerAge) {
				this.model.ownRiskOptions.push(excess.id);
				this.model.ownRiskLabels.push(Formatter.format(excess.amount) + ' kr.');
			}
		});
	}

	private checkFirstPackagePrices(excessId: number) {
		let ok = true;
		const packages = this.model.calculation.abCalc.packs.get(excessId);
		packages.forEach((calc) => {
			if (!calc.totalPrice) {
				ok = false;
			}
		});
		return ok;
	}

	private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
		try {
			this.successCalc = true;

			const rebate = getDiscount(this.cms);
			this.model.calculation.discount = rebate.discount;
			this.model.calculation.discountDisplay = rebate.discountDisplay;

			if (hasAddressSupplement(this.model, this.cms)) {
				this.model.calculation.addressSupplement = this.cms.addressSupplement;
			} else {
				this.model.calculation.addressSupplement = 1;
			}

			const promises = [];
			for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
				const packageId = this.model.calculation.abCalc.packageIds[index];
				promises.push(this.onePackageCalcEpi(monthly, excessId, packageId));
			}
			await Promise.all(promises);
			return Promise.resolve(this.successCalc);
		} catch (ex) {
			console.error(ex);
			if (monthly) {
				this.doErrorPopUp(400);
			}
			return Promise.resolve(false);
		}
	}

	public async getCalculations(excessId?: number): Promise<boolean> {
		store.state.showSpinner = true;
		store.state.calculatorContext.calculating = true;
		// handle "eternal" spinning
		setTimeout(() => {
			if (store.state.showSpinner) {
				store.state.showSpinner = false;
				store.state.calculatorContext.calculating = false;
			}
		}, this.cms.calculationSpinnerTimeout);

		// reset prices
		const _excessId =
			excessId || this.model.choosePackage.ownRiskId || this.model.calculation.abCalc.excessIdDefault;

		const pack = this.model.calculation.abCalc.packs.get(_excessId);

		pack.totalPriceDisplay = undefined;
		this.updateCalcKeys();
		const indbo = await this.getEpiCalculations(_excessId);
		if (!indbo) {
			this.model.calculation.isCalculated = false;
			// store.state.showSpinner = false;
			store.state.calculatorContext.calculating = false;
			// TODO KLK error msg
			return Promise.reject();
		}
		pack.totalPriceDisplay = undefined;
		this.updateCalcKeys();
		const accident_ = await this.getAccidentCalculations();
		if (!accident_) {
			this.model.calculation.isCalculated = false;
			store.state.showSpinner = false;
			store.state.calculatorContext.calculating = false;
			// TODO KLK error msg
			return Promise.reject();
		}
		const accident = this.model.calculationAccident.abCalc.packs.get(
			this.model.calculationAccident.abCalc.excessIdDefault
		);
		this.model.calculation.abCalc.packs.forEach((calcs) => {
			calcs.forEach((calc) => {
				if (calc.totalPriceDisplay) {
					const accidentCalc = accident.filter((accidentCalc) => accidentCalc.name === calc.name)[0];
					this.addAccidentPricesToHomePrices(calc, accidentCalc);
				}
			});
		});
		this.model.calculation.isCalculated = true;
		store.state.showSpinner = false;
		store.state.calculatorContext.calculating = false;
		this.updateCalcKeys();
		// triggerCustomGtmEvent({
		//     'event': 'track-vp',
		//     'virtualPath': `${getTrackingPreUrl()}${this.model.productName}/prices_shown`,
		// });
		return Promise.resolve(true);
	}

	private addAccidentPricesToHomePrices(homeCalc, childCalc) {
		/*
        basePrice: 312.1666666666667
        coverages: (10) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
        id: 3004
        name: "Grunddækning"
        rebatePrice: 266.3333333333333
        rebateProcent: 15
        statutoryFee: 10
        totalPrice: 312
        totalPriceDisplay: "312 kr."
        yearlyBasePrice: 3631
        yearlyPriceTotal: 3631
        yearlyPriceTotalDisplay: "3.631 kr."
        yearlyRebatePrice: 3098

    */

		homeCalc.basePrice += childCalc.summedBasePrice;
		homeCalc.totalPrice += childCalc.summedTotalPrice;
		homeCalc.yearlyBasePrice += childCalc.summedYearlyBasePrice;
		homeCalc.yearlyPriceTotal += childCalc.summedYearlyPriceTotal;

		homeCalc.totalPriceDisplay = Formatter.format(homeCalc.totalPrice) + ' kr.';
		homeCalc.yearlyPriceTotalDisplay = Formatter.format(homeCalc.yearlyPriceTotal) + ' kr.';
	}

	private async getEpiCalculations(_excessId?: number): Promise<boolean> {
		// reset calculation
		await this.setUpConfig();

		let success = true;

		success = await this.calculatePackagesEpi(true, _excessId);
		// check if has got prices
		if (!success) {
			success = this.checkFirstPackagePrices(_excessId);
		}
		if (success) {
			await this.calculatePackagesEpi(false, _excessId);
		}
		return success;
	}

	private async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {
		const response = await this.axiosService.getRetryDefaultUrl(
			this.createParamsEpi(excessId, packageId, monthly ? 1 : 0)
		);

		if (response?.status !== 200) {
			this.successCalc = false;
			this.doErrorPopUp(response?.status);
			return Promise.resolve(false);
		}

		const pack = this.model.calculation.abCalc.packs.get(excessId);
		mapPriceResult(response);
		setPrices(packageId, response, monthly, pack, this.model, this.cms);
		if (!this.model.updatedExcessListFromProduct) {
			updateExcessesFromCalculation(response, this.calcConfigHome);
			this.setupExcessList();
			this.model.updatedExcessListFromProduct = true;
		}

		return Promise.resolve(true);
	}

	private doErrorPopUp(status) {
		const popup: any = {
			content: this.cms.defaultCalcErrorContent,
			track: false,
			btnSecondLabel: 'Ok',
			id: status === 250 ? 'calcWarning' : 'calcError',
			title: this.cms.defaultCalcErrorTitle,
			show: true,
		};

		if (this.cms.useBtnInlineFail) {
			this.errorPopup.btnInlineLabel = this.cms.btnInlineFailLabel;
			this.errorPopup.btnInlineStyle = this.cms.btnInlineFailStyle;
			this.errorPopup.btnInlineIcon = this.cms.btnInlineIcon;
			this.errorPopup.btnInlineAction = () => {
				this.getCalculations();
			};
		}

		togglePopup(this.cms, popup);
	}

	private createParamsEpi(excessId: number, packageId: number, monthlyPrices: number) {
		const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g, ''));
		const streetBuildingLetter = this.model.personInfo.houseNr.trim().replace(/[0-9]/g, ''); // remove numbers
		const streetNumber = this.model.personInfo.houseNr.trim().replace(/\D/g, ''); // remove characters

		let suite: number;
		let floorSideCode: string;
		if (this.model.personInfo.doorNr) {
			suite =
				this.model.personInfo.doorNr.trim().replace(/[0-9]/g, '') === ''
					? parseInt(this.model.personInfo.doorNr.trim())
					: undefined;
			floorSideCode =
				this.model.personInfo.doorNr.trim().replace(/\D/g, '', '') === ''
					? this.model.personInfo.doorNr.trim()
					: undefined;
		}

		let insuredValue = this.model.choosePackage.insuredValueAmount;
		if (!insuredValue) {
			insuredValue = this.model.calculation.abCalc.insuredValueOptions[0].value;
		}

		let groupAgreement = '-1';
		if (this.model.campaign.valid) {
			groupAgreement = this.cms.campaigns.get(this.model.campaign.ID).groupId;
		}

		// create params
		const params = {
			district_name: 'xxx', // ?
			customer_age: customerAge,
			insured_value: insuredValue,
			street_name: this.model.personInfo.streetName,
			street_building: streetNumber,
			street_building_letter: streetBuildingLetter !== '' ? streetBuildingLetter : undefined,
			floor: this.model.personInfo.floorNr,

			suite, // hvis tal
			floor_side_code: floorSideCode, // hvis ikke tal

			post_code: `${this.model.personInfo.zipCode}`,
			has_thatched_roof: this.model.personInfo.thatchedRoof === 'ja' ? 'y' : 'n',
			has_burglar_alarm: 'n',
			family_member_count: this.model.personInfo.familyMemberCountId,
			plus_customer: this.cms.plusCustomerCalculation ? 'PK1' : 'PK0',
			excess_id: `${excessId}`,
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: getRisksForPackage(packageId, this.model),
			group_agreement: groupAgreement !== '-1' ? groupAgreement : undefined,
			ext_bike_sum: this.cms.extendedBikeSum,
		};

		return params;
	}

	public async checkForValidAddress(): Promise<boolean> {
		const packageId = this.model.calculation.abCalc.packageIds[0];
		const excessId = this.model.calculation.abCalc.excessIdDefault;
		const ok = await this.onePackageCalcEpi(true, excessId, packageId);
		return Promise.resolve(ok);
	}

	public updateCalcKeys() {
		this.model.calculation.updatePrices = this.model.calculation.updatePrices.map((elem) => {
			return (elem += '1');
		});
	}

	private async getAccidentCalculations(excessId?: number): Promise<boolean> {
		// reset calculation and setup coverages by age
		await this.setupAccident();

		try {
			const promises = [];
			this.model.calculationAccident.abCalc.packs
				.get(this.model.calculationAccident.abCalc.excessIdDefault)
				.forEach((calc) => {
					calc.summedBasePrice = 0;
					calc.summedStatutoryFee = 0; // not returned by api
					calc.summedTotalPrice = 0;

					calc.summedYearlyBasePrice = 0;
					calc.summedYearlyStatutoryFee = 0; // not returned by api
					calc.summedYearlyPriceTotal = 0;
				});
			for (let i = 0; i <= this.model.familyInfo.personCountId; i++) {
				const age = this.model.familyInfo['age' + i];
				const workId = this.model.familyInfo['workId' + i];

				this.model.calculationAccident.abCalc.packages.forEach((pack) => {
					const features = this.getFeatures(pack, age);
					promises.push(this.calculateAccidentQuickQuote(pack.name, age, features, workId));
				});
			}
			await Promise.all(promises);

			return Promise.resolve(true);
		} catch (err) {
			console.error('Accident offer', err);
			// triggerCustomGtmEvent({
			//     'event': 'track-vp',
			//     'virtualPath': `${getTrackingPreUrl()}${this.model.productName}/error/tiaonlineoffer`,
			// });

			togglePopup(this.cms, this.errorPopup);
			store.state.showSpinner = false;
			store.state.calculatorContext.calculating = false;
			return Promise.resolve(false);
		}
	}

	private async calculateAccidentQuickQuote(
		productType: string,
		age: string,
		features: Array<any>,
		workId: string
	): Promise<any> {
		let customerAge = parseInt(age);
		if (customerAge < this.cms.minChildAge) {
			customerAge = this.cms.minChildAge;
		}

		if (!features) {
			return Promise.reject();
		}
		const createOffer: any = {
			product: {
				product_name: productAccidentId,
				features,
				parameters: {
					age: customerAge,
					postalCode: this.model.personInfo.zipCode,
				},
			},
		};
		workId ? (createOffer.product.parameters.employment = workId) : undefined;

		try {
			//const res = await this.abAxiosSelfserviceOpen.offerServiceLayer.createOffer(createOffer);
			const res = await this.axiosAccidentService.postRetry(undefined, createOffer);
			const offer = res.data;
			if (!offer || !offer.price || !offer.price.annually) {
				throw new Error();
			}
			this.model.calculationAccident.abCalc.packs
				.get(this.model.calculationAccident.abCalc.excessIdDefault)
				.forEach((calc) => {
					if (calc.name === productType) {
						let totalPrice = offer.price.monthly;
						calc.basePrice = totalPrice;
						calc.statutoryFee = 0; // not returned by api

						// pluscustomer discount
						let discount = this.model.calculation.discount;
						if (this.model.campaign.valid) {
							discount -= this.model.campaign.discount;
						}

						totalPrice *= discount;
						// totalPrice *= this.model.calculation.discount;

						// // campaign discount
						// if (this.model.campaign.valid)  {
						//     totalPrice -= (totalPrice * this.model.campaign.discount);
						// }

						calc.totalPrice = Math.round(totalPrice);

						totalPrice = offer.price.annually;
						calc.yearlyBasePrice = totalPrice;
						calc.yearlyStatutoryFee = 0; // not returned by api

						// pluscustomer discount
						totalPrice *= discount;
						// totalPrice *= this.model.calculation.discount;

						// // campaign discount
						// if (this.model.campaign.valid)  {
						//     totalPrice -= (totalPrice * this.model.campaign.discount);
						// }

						calc.yearlyPriceTotal = Math.round(totalPrice);

						calc.summedBasePrice += calc.basePrice;
						calc.summedStatutoryFee = 0; // not returned by api
						calc.summedTotalPrice += calc.totalPrice;

						calc.summedYearlyBasePrice += calc.yearlyBasePrice;
						calc.summedYearlyPriceTotal += calc.yearlyPriceTotal;
						calc.summedYearlyStatutoryFee = 0; // not returned by api

						calc.totalPriceDisplay = Formatter.format(calc.summedTotalPrice) + ' kr.';
						calc.yearlyPriceTotalDisplay = Formatter.format(calc.summedYearlyPriceTotal) + ' kr.';
					}
				});

			return Promise.resolve();
		} catch (err) {
			console.error(err);
			if (isDevelop && this.cms.mockData) {
				// MOCK
				console.warn('USING MOCK DATA!');
				let add = 2000;
				this.model.calculation.abCalc.packs
					.get(this.model.calculation.abCalc.excessIdDefault)
					.forEach((calc) => {
						add += 100;
						if (calc.name === productType) {
							let totalPrice = add;
							calc.basePrice = totalPrice;
							calc.statutoryFee = 0; // not returned by api
							if (this.model.campaign.valid) {
								totalPrice -= totalPrice * this.model.campaign.discount;
							}

							calc.totalPrice = Math.round(totalPrice * this.model.calculation.discount);
							calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';

							totalPrice = 12 * add * 0.97;
							calc.yearlyBasePrice = totalPrice;
							calc.yearlyStatutoryFee = 0; // not returned by api

							if (this.model.campaign.valid) {
								totalPrice -= totalPrice * this.model.campaign.discount;
							}
							calc.yearlyPriceTotal = Math.round(totalPrice * this.model.calculation.discount);
							calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
							this.updateCalcKeys();
						}
					});
			} else {
				togglePopup(this.cms, this.errorPopup);
				return Promise.reject(err);
			}
		}
	}

	private getFeatures(pack, childAge) {
		const features = [];
		pack.includedCoveragesExpandedIds.forEach((riskId) => {
			if (childAge < this.cms.childAgeSports) {
				// Barn under 4 år -> Fjern Farlig sport og Motorsport og ATV
				if (riskId === 'SS1VUF9VUC0yOC00Mw==' || riskId === 'SS1VUF9VUC0yOC0xOA==') {
					return;
				}
			}
			const cov = cloneDeep(
				this.model.calculationAccident.abCalc.coveragesDetails.filter(
					(coverage) => riskId === coverage.riskId
				)[0]
			);
			cov.name = cov.riskId;
			features.push(cov);
		});
		return features;
	}
}
