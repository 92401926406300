export enum LogLevel {
	Verbose = 0,
	Information = 1,
	Warning = 2,
	Error = 3,
	Critical = 4,
}

export class Log {
	//get appInsigts from the window
	private static appInsigts = (<any>window).appInsights;

	static verbose(message: string, properties?: { [key: string]: any }): void {
		this.trace(message, LogLevel.Verbose, properties);
	}

	static information(message: string, properties?: { [key: string]: any }): void {
		this.trace(message, LogLevel.Information, properties);
	}

	static warning(message: string, properties?: { [key: string]: any }): void {
		this.trace(message, LogLevel.Warning, properties);
	}

	static critical(message: string, properties?: { [key: string]: any }): void {
		this.trace(message, LogLevel.Critical, properties);
	}

	static trace(message: string, logLevel: LogLevel, properties: { [key: string]: any }): void {
		try {
			if (this.appInsigts) {
				this.appInsigts.trackTrace(message, properties, logLevel);
			} else {
				switch (logLevel) {
					case LogLevel.Critical:
					case LogLevel.Error:
						console?.error(message, properties);
						break;
					case LogLevel.Warning:
						console.warn(message, properties);
						break;
					case LogLevel.Information:
						console.info(message, properties);
						break;
					case LogLevel.Verbose:
						console.debug(message, properties);
						break;
					default:
						console?.log(message, logLevel, properties);
				}
			}
		} catch (err) {
			console?.error('error while trying to log message', message);
		}
	}

	static exception(message: string, exception: Error, properties: { [key: string]: any }): void {
		try {
			if (this.appInsigts) {
				this.appInsigts.trackException(exception, null, properties, null, LogLevel.Error);
				this.trace(message, LogLevel.Error, { ...properties, exception });
			} else {
				console?.error(message, exception, properties);
			}
		} catch (err) {
			console?.error('error while trying to log exception', message, exception);
		}
	}
}
