import { HtmlBlock } from '@/cms/definitions/content-types';
import BuyInsuranceSettings from '../BuyInsuranceSettings';

// export const enum PACKAGES {
//     BASIC = 'Grunddækning',
//     MELLEM = 'Tilvalgspakke',
//     // STOR = 'Stor',
// }
export class HouseSettings extends BuyInsuranceSettings {
	public changeCalcTitle: string;
	public changeCalcContent: string;
	public oldCalculatorPricesOther: string;
	public calculatorUrlOtherVue3: string;
	public calculatorUrlVue3: string;
	public selectedRisksOther: Array<string>;
	public oldCalculatorBlockIdOther: string;
	public basePackageOther: string;
	public excessIdOther: string;

	public insuredValue: string; // holidayHouse default sum, being copied from product info

	public constructor(settingsBlock: HtmlBlock) {
		super(settingsBlock);
		this.loadHouseSettings();
	}

	/**
	 * load settings from CMS if present
	 */
	private loadHouseSettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');
			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				if (setting.indexOf('changecalctitle=') > -1) {
					this.changeCalcTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('changecalccontent=') > -1) {
					this.changeCalcContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('oldcalculatorpricesother=') > -1) {
					this.oldCalculatorPricesOther = this.getSettingsValue(setting_);
				} else if (setting.indexOf('calculatorurlothervue3=') > -1) {
					this.calculatorUrlOtherVue3 = this.getSettingsValue(setting_);
				} else if (setting.indexOf('calculatorurlvue3=') > -1) {
					this.calculatorUrlVue3 = this.getSettingsValue(setting_);
				} else if (setting.indexOf('selectedrisksother=') > -1) {
					this.selectedRisksOther = this.getSettingsValue(setting_).split('¤');
				} else if (setting.indexOf('oldcalculatorblockidother=') > -1) {
					this.oldCalculatorBlockIdOther = this.getSettingsValue(setting_);
				} else if (setting.indexOf('basepackageother=') > -1) {
					this.basePackageOther = this.getSettingsValue(setting_);
				} else if (setting.indexOf('excessidother=') > -1) {
					this.excessIdOther = this.getSettingsValue(setting_);
				}
			});
		} catch (e) {
			console.error(e);
		}
	}
}
