import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import store from '@/store/store';
import Coverage from '../components/coverageComponent/Coverage';
import { Formatter, monthYearArray } from './ValidationService';
import { SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import { STEP } from './UiStepService';
import { GO_TO_PACKAGE_STEPS } from './NavigationService';

export const mapCoveragesFromProductConfig = (model, cms) => {
	model.calculation.allCoverages = new Map<number, Map<number, Array<Coverage>>>();
	model.calculation.abCalc.packs.forEach((calculations, ownRiscId) => {
		const coveragesForPackage = new Map<number, Array<Coverage>>();
		calculations.forEach((calc, packageInx) => {
			const coverages: Array<Coverage> = [];
			calc.coverages.forEach((coverage) => {
				// (featureId: string, name: string, title: string, description: string)
				const coverage_ = new Coverage(
					coverage.riskId + '',
					cms.getCoverageName(coverage.name),
					cms.getCoverageName(coverage.name),
					cms.getCoverageDescription(coverage.name, coverage.description)
				);
				coverages.push(coverage_);
			});
			coveragesForPackage.set(packageInx, coverages);
		});
		model.calculation.allCoverages.set(ownRiscId, coveragesForPackage);
	});
};

export const handleSpecialCarDiscounts = (model, cms): { specialDiscount: number; specialDiscountType: string } => {
	const res = { specialDiscount: 0, specialDiscountType: undefined };
	if (model.productName !== InsurancesProductEnum.BIL_FORSIKRING) {
		return res;
	}
	let addSpecialDiscount = false;

	if (cms.vehicleDiscountEnabled) {
		addSpecialDiscount = true;
		if (model.campaign.valid) {
			if (!cms.vehicleDiscountEnabledInCampaign.includes(model.campaign.ID)) {
				addSpecialDiscount = false;
			}
		}
	}
	if (addSpecialDiscount) {
		const grp = model.carInfo.vehicleGroup + '';
		if (cms.vehicleDiscountGroups.includes(grp)) {
			res.specialDiscount = cms.vehicleDiscount;
			res.specialDiscountType = 'vehicleGroup';
		}
	}

	if (model?.carInfo?.isElectricHybrid && model.calculation.discount < 1) {
		// pluskunde og el/hybrid
		addSpecialDiscount = true;
		if (model.campaign.valid) {
			if (!cms.electricFuelTypeDiscountEnabledInCampaign.includes(model.campaign.ID)) {
				addSpecialDiscount = false;
			}
		}
		if (addSpecialDiscount) {
			const grp = model.carInfo.vehicleGroup + '';
			if (cms.electricFuelTypeVehicleGroups.includes(grp)) {
				res.specialDiscount = cms.electricFuelTypeDiscount;
				res.specialDiscountType = 'fuelType';
			}
		}
	}

	return res;
};

export const mapPriceResult = (response) => {
	const res = response.data.quotes[0];
	response.data.totalPrice = res.total_price;
	response.data.statutoryFee = res.statutory_fee;
	response.data.rebatePrice = res.alternative_prices;
};

export const getRisksForPackage = (packageId: number, model) => {
	// console.log('chooosing package', packageId);

	const pack = model.calculation.abCalc.packages.find((pack) => packageId === pack.id);
	//  console.log('found package for id', packageId, pack);
	// console.log('coverages', model.calculation.abCalc.coveragesDetails);

	const selectedRisks = pack.includedCoveragesExpandedIds.filter((riskNo) => {
		if (riskNo > -1) {
			return riskNo;
		}
	});

	// console.log('selected risks', selectedRisks);
	// find coverages in riskGroups
	const risksInGroups: Array<number> = [];
	// console.log('model.calculation.abCalc.coveragesDetails', model.calculation.abCalc.coveragesDetails);
	selectedRisks.forEach((riskId) => {
		model.calculation.abCalc.coveragesDetails.forEach((coverage) => {
			if (coverage.riskId !== -1 && coverage.riskGroup === riskId && coverage.riskId !== riskId) {
				risksInGroups.push(coverage.riskId);
			}
		});
	});
	// console.log('found risks in groups', risksInGroups);

	const res = selectedRisks.concat(risksInGroups).sort((a, b) => {
		return a - b;
	});

	// console.log('using risks', res);

	return res.toString();
};

export const setPrices = (packageId, response, monthly, pack, model, cms, specialProductDiscount = 0) => {
	pack.forEach((calc) => {
		if (calc.id === packageId) {
			if (monthly) {
				response.data.totalPrice = response.data.totalPrice / 12; // always receive yearly price
				response.data.statutoryFee = response.data.statutoryFee / 12; // always receive yearly fee
			}

			let hasDogFee = false;
			if (response.data.specialFee) {
				if (
					model.calculation.discount === 0.95 ||
					(model.calculation.discount === 1 && cms.alwaysUseSickAccident)
				) {
					hasDogFee = true;
				}
			}

			if (cms.plusCustomerCalculation) {
				let totalPrice = response.data.totalPrice; // - response.data.statutoryFee;
				totalPrice *= 1.05; // PK1
				const statutoryFee = totalPrice * 0.03525;
				response.data.totalPrice = totalPrice; // + statutoryFee;
				response.data.statutoryFee = statutoryFee;
			}
			// vehicleGroup/fuelType Discount for now
			calc.specialDiscount = specialProductDiscount ? specialProductDiscount : undefined;

			if (model.calculation?.addressSupplement > 1) {
				response.data.totalPrice *= model.calculation.addressSupplement; // adresse tillæg (60%)
			}

			if (monthly) {
				// console.log('setting monthly prices');

				calc.rebatePrice = response.data.rebatePrice;
				calc.rebateProcent = response.data.rebateProcent;
				calc.statutoryFee = response.data.statutoryFee;
				calc.basePrice = response.data.totalPrice;
				calc.specialFee = response.data.specialFee; // dog pluscustomer 5% PK1 - sickness disease coverage
				let totalPrice = response.data.totalPrice - response.data.statutoryFee;

				// dog pluscustomer 5% PK1 - sickness disease coverage
				if (hasDogFee) {
					totalPrice += calc.specialFee / 12;
				}

				// pluscustomer discount -> 1 | 0.95 | 0.9 | 0.85
				// totalPrice *= model.calculation.discount;
				let discount = model.calculation.discount;

				if (model.campaign.valid) {
					discount -= model.campaign.discount;
				}
				totalPrice *= discount;

				// // campaign discount
				// if (model.campaign.valid)  {
				//     totalPrice -= (totalPrice * model.campaign.discount);
				// }

				// special discount
				if (calc.specialDiscount) {
					totalPrice -= totalPrice * calc.specialDiscount;
				}

				// totalPrice *= model.calculation.discount;
				calc.totalPrice = Math.round(totalPrice + response.data.statutoryFee);
				calc.totalPriceDisplay = Formatter.format(calc.totalPrice) + ' kr.';
			} else {
				// console.log('setting yearly prices');
				calc.yearlyRebatePrice = response.data.rebatePrice;
				calc.yearlyBasePrice = response.data.totalPrice;
				calc.yearlyStatutoryFee = response.data.statutoryFee;
				calc.yearlySpecialFee = response.data.specialFee;
				let totalPrice = response.data.totalPrice - response.data.statutoryFee;

				// dog pluscustomer 5% PK1 - sickness disease coverage
				if (hasDogFee) {
					totalPrice += calc.yearlySpecialFee;
				}

				// pluscustomer discount -> 1 | 0.95 | 0.9 | 0.85
				// totalPrice *= model.calculation.discount;
				let discount = model.calculation.discount;

				if (model.campaign.valid) {
					discount -= model.campaign.discount;
				}
				totalPrice *= discount;

				// // campaign discount
				// if (model.campaign.valid)  {
				//     totalPrice -= (totalPrice * model.campaign.discount);
				// }

				// special discount
				if (calc.specialDiscount) {
					totalPrice -= totalPrice * calc.specialDiscount;
				}

				calc.yearlyPriceTotal = Math.round(totalPrice + response.data.statutoryFee);
				calc.yearlyPriceTotalDisplay = Formatter.format(calc.yearlyPriceTotal) + ' kr.';
			}
		}
	});
};

/** calculate pluskunde discount
 *  EKS.
    Børnefamilieforsikring | (Indboforsikring + Ulykkesforsikring)	5 %
    Børnefamilieforsikring | (Indboforsikring + Børne- Ulykkesforsikring) + fritids- Husforsikring	10 %
    Børnefamilieforsikring | (Indboforsikring + Børne- Ulykkesforsikring) + fritids- Husforsikring + Bilforsikring	15 %
*/
export const getDiscount = (cms: any, useActiveCalculator = false): { discount: number; discountDisplay: string } => {
	const rebate = { discount: 1, discountDisplay: undefined };
	let products = [];

	// get commonModel for calculators
	const commonPersonInfo = store.getters.getCalculatorCommonModel?.personInfo;
	let personInfo = commonPersonInfo;

	if (!personInfo?.almbrandCustomer || useActiveCalculator) {
		// get from active calculator
		personInfo = store.getters.getActiveCalculator?.model?.personInfo;
	}

	if (personInfo?.existingAlmBrandProducts) {
		products = Object.assign([], personInfo.existingAlmBrandProducts);
		products = products.map((product) => product.toLocaleLowerCase());
		if (useActiveCalculator && commonPersonInfo) {
			// update commonData
			commonPersonInfo.existingAlmBrandProducts = products;
		}
	}

	const basket = store.getters.getSelectableGroupUnique('basket');

	basket.forEach((product) => {
		if (!products.includes(product)) {
			products.push(product);
		}
	});
	// find primary product, and remove if present (homeInsurance)
	let count = 0;
	cms.discountPrimaryProducts.forEach((primaryProduct) => {
		if (products.includes(primaryProduct.toLocaleLowerCase())) {
			count = 1; // only one product will count
		}
	});
	// no discount
	if (count === 0) {
		return rebate;
	}
	// find first secondary product, and remove if present (accident, car, house or vacationHouse)
	cms.discountSecondaryProducts.forEach((secondaryProduct) => {
		if (products.includes(secondaryProduct.toLocaleLowerCase())) {
			count++;
		}
	});
	// Børnefamilieforsikring || børneulykke
	if (!products.includes(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
		if (
			products.includes(InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING) ||
			products.includes(InsurancesProductEnum.BOERNEULYKKES_FORSIKRING)
		) {
			count++;
		}
	}
	// kun en af hus- fritishus forsikring gælder
	if (
		products.includes(InsurancesProductEnum.HUS_FORSIKRING) &&
		products.includes(InsurancesProductEnum.FRITIDSHUS_FORSIKRING)
	) {
		count--;
	}

	// no discount, only indbo
	if (count === 1) {
		return rebate;
	}
	// find correct discount -> count remaining products and set discount
	switch (count) {
		case 2:
			rebate.discount = 0.95;
			break;
		case 3:
			rebate.discount = 0.9;
			break;
		default:
			rebate.discount = 0.85;
			break;
	}

	const discount = Math.abs(rebate.discount - 1);
	rebate.discountDisplay = Number(discount).toLocaleString('da-DK', {
		style: 'percent',
		minimumFractionDigits: 0,
	});
	return rebate;
};

/** discount depending on amount of selected products */
export const getDiscountForAmountProducts = (campaign) => {
	if (campaign.productStepDiscount) {
		const basket = store.getters.getSelectableGroup('basket');
		const productAmount = basket.length;
		if (productAmount >= campaign.productStepDiscount.length) {
			return campaign.productStepDiscount[campaign.productStepDiscount.length - 1];
		}
		switch (productAmount) {
			case 0:
			case 1:
				return campaign.productStepDiscount[0];
			default:
				return campaign.productStepDiscount[productAmount - 1];
		}
	}
	return campaign.discount;
};

export const reducePack = (pack) => {
	return {
		coverages: pack.coverages,
		expandedCoverages: pack.expandedCoverages,
		name: pack.name,
		id: pack.id,
		rebatePrice: pack.rebatePrice,
		rebateProcent: pack.rebateProcent,
		statutoryFee: pack.statutoryFee,
		totalPrice: pack.totalPrice,
		totalPriceDisplay: pack.totalPriceDisplay,
		yearlyPriceTotal: pack.yearlyPriceTotal,
		yearlyPriceTotalDisplay: pack.yearlyPriceTotalDisplay,
	};
};

export const resetSelectedCalculation = (model) => {
	model.choosePackage.selectedPackage = undefined;
	model.choosePackage.subtitle = undefined;
	model.choosePackage.ownRiskId = undefined;
	model.calculation.isCalculated = false;
	model.overview.highlights = undefined;
	model.overview.details = undefined;
	model.readyForBasket = false;
	// set to monthly payment as default
	model.choosePackage.monthYear = monthYearArray[0].value;
	store.state.calculatorContext.calculating = false;
};

export const subscribeBasketChange = (comp): Function => {
	return store.subscribeAction((action, state) => {
		if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
			if (comp.model.currentCardName === STEP.PACKAGE) {
				if (comp.model?.calculation?.isCalculated) {
					// redo calculation if pluscustomer discount has changed after basket change
					const rebate = getDiscount(comp.cms);
					if (rebate.discount !== comp.model.calculation.discount) {
						comp.calculator.getCalculations();
					}
				}
				return;
			}
			if (GO_TO_PACKAGE_STEPS.includes(comp.model.currentCardName) && comp.model.currentCardName !== STEP.ORDER) {
				resetSelectedCalculation(comp.model);
				comp.gotoCard(STEP.PACKAGE);
			}
		}
	});
};
