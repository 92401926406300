import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { browserIe11OrOlder } from '@/services/environmentService';
import { CALCULATOR_UPDATE_COMMON_MODEL } from '@/store/modules/calculatorContext';
import store from '@/store/store';
import localforage from 'localforage';
import { cloneDeep } from 'lodash';
import { nextTick } from 'vue';
import { togglePopup } from './AlertService';
import { getDiscountForAmountProducts } from './CalculationService';
import { handleLoggedInUser } from './UserLoginService';
import { Formatter } from './ValidationService';

// steps names
export const enum STEP {
	DOG_INFO = 'dog_info',
	CAR_INFO = 'car_info',
	CHILDREN_INFO = 'children_info',
	// FAMILY_INFO = 'family_info',
	PERSON_INFO = 'person_info',
	PACKAGE = 'choose_package',
	OVERVIEW = 'overview',
	ADDITIONAL_INFO = 'additional_info',
	CONTACT_INFORMATION = 'contact_information',
	PAYMENT = 'payment',
	ORDER = 'order',
}

export const STEPS_EXCLUDE_BASKET: Array<string> = [STEP.CONTACT_INFORMATION, STEP.OVERVIEW, STEP.PAYMENT, STEP.ORDER];

export const initComponent = async (comp): Promise<boolean> => {
	handleQueryParams(comp);
	await handleCampaigns(comp);

	handleHeaderBadge(comp);

	const productsInBasket = store.getters.getSelectableGroup('basket');

	comp.model.multipleProducts = productsInBasket.length > 1;
	if (comp.model.multipleProducts) {
		store.dispatch(CALCULATOR_UPDATE_COMMON_MODEL, { fromCommonModel: true });
		removeStepsIfBasket(comp);
	}

	// check if user is logged in (coming from MitAlmbrand), and use customer data
	await handleLoggedInUser(comp);

	removeUnreferencedBlocks(comp);

	// this.resetModal();
	if (browserIe11OrOlder()) {
		nextTick().then(() => {
			togglePopup(comp.cms, {
				id: 'error_browser',
				show: true,
				redirect: '/',
				track: true,
				trackToken: 'oldiebrowser',
				title: comp.cms.ieBrowserTitle,
				content: comp.cms.ieBrowserContent,
			});
		});
		let tries = 10;
		const interval = setInterval(() => {
			if (store.state.showSpinner || tries < 0) {
				store.state.showSpinner = false;
				clearInterval(interval);
			}
			tries--;
		}, comp.cms.initialTimeout);
		return false;
	}

	if (comp.cms.closeCalculator) {
		store.state.calculatorContext.calculating = false;
		nextTick().then(() => {
			togglePopup(comp.cms, {
				id: 'closed',
				show: true,
				btnLabel: comp.cms.btnLabelClose,
				btnSecondLabel: comp.cms.btnSecondLabelClose,
				btnSecondStyle: comp.cms.btnSecondLabelCloseStyle,
				redirectOnClose: comp.cms.redirectOnClose,
				redirect: comp.cms.btnRedirectUrlClose,
				track: true,
				trackToken: 'calculator closed',
				title: comp.cms.closeCalculatorPopupTitle,
				content: comp.cms.closeCalculatorPopupContent,
			});
		});
		let tries = 10;
		const interval = setInterval(() => {
			if (store.state.showSpinner && tries < 0) {
				store.state.showSpinner = false;
				clearInterval(interval);
			} else if (!store.state.showSpinner) {
				clearInterval(interval);
			}
			tries--;
		}, comp.cms.initialTimeout);
		return false;
	}

	store.state.showSpinner = false;

	return true;
};

/**
 * Make whole string lowercase and Capitalize 1. Letter
 * If allWords, then take every word to lowercase, split by ' ' and capitalize 1 letter.
 * checks also for double ' '
 * TODO perhaps also check also on '-'
 * @param str
 * @param allWords default = false
 */

export const capitalizeFirstLetter = (str: string, allWordsInString = false) => {
	if (!str || str.trim() === '') {
		return '';
	}

	const str_ = str.toLocaleLowerCase();
	if (!allWordsInString) {
		return str_[0].toUpperCase() + str_.slice(1);
	}
	return str_
		.split(' ')
		.map((w) => {
			w = w.trim();
			return w !== '' ? w[0].toUpperCase() + w.slice(1) : w;
		})
		.join(' ');
};

/***** private methods *****************/

const handleQueryParams = (comp) => {
	try {
		// check for customerNo in query
		const query = comp.$route.query;
		if (Object.keys(query).length === 0) {
			return;
		}
		const customerNo = (query.customerno as string) || (query.customerNo as string) || (query.K as string);
		if (customerNo) {
			comp.model.contact_information.customerNo = customerNo;
		}
		// check for includedProducts
		if (comp.cms.queryProductNames.length > 0) {
			comp.cms.queryProductNames.forEach((name, inx) => {
				const value = query[name];
				if (value !== undefined) {
					if (value === 1 || value.toLocaleLowerCase().trim() === 'true') {
						comp.model.personInfo.almbrandCustomer = 'ja';
						comp.model.personInfo.existingAlmBrandProducts.push(comp.cms.queryProductValues[inx]);
					}
				}
			});
		}
		// const source = query.utm_source as string
		// if (source) {
		//     triggerCustomGtmEvent({
		//         'event': 'track-vp',
		//         'virtualPath': `${getTrackingPreUrl()}${comp.model.productName}/utm_source/${source}`
		//     });
		// }
		// const medium = query.utm_medium as string
		// if (medium) {
		//     triggerCustomGtmEvent({
		//         'event': 'track-vp',
		//         'virtualPath': `${getTrackingPreUrl()}${comp.model.productName}/utm_medium/${medium}`
		//     });
		// }
		// const campaign = query.utm_campaign as string
		// if (medium) {
		//     triggerCustomGtmEvent({
		//         'event': 'track-vp',
		//         'virtualPath': `${getTrackingPreUrl()}${comp.model.productName}/utm_campaign/${campaign}`
		//     });
		// }
	} catch (error) {
		// too bad
	}
};

const handleCampaigns = async (comp) => {
	if (comp.model.campaign.ID && comp.model.campaign.valid) {
		// campaign already loaded (basket or localStorage)
		return;
	}
	let campaignID = comp.$route.query.partner as string;
	if (!campaignID) {
		campaignID = comp.$route.query.campaign as string;
	}
	if (!campaignID) {
		campaignID = comp.$route.query.utm_campaign as string;
	}

	if (!campaignID) {
		const hasStoredCampaign = await loadCampaignFromStorage(comp);
		if (hasStoredCampaign) {
			return; // campaign loaded from storage
		}
	}

	comp.model.campaign.ID = campaignID?.toLocaleLowerCase();
	comp.model.campaign.valid = false;

	if (comp.model.campaign.ID && comp.model.campaign.ID === 'diba') {
		if (comp.cms.validCampaigns.includes(comp.model.campaign.ID)) {
			comp.model.campaign.uniqueKey =
				(comp.$route.query.partnerGuid as string) || (comp.$route.query.partnerguid as string);
			if (comp.model.campaign.uniqueKey) {
				comp.model.campaign.valid = true;
			}
		}
	} else if (comp.model.campaign.ID) {
		if (comp.cms.validCampaigns.includes(comp.model.campaign.ID)) {
			comp.model.campaign.valid = true;
		}
	}

	// standard almbrand online campaign - fallback
	if (!comp.model.campaign.valid && comp.cms.validCampaigns.includes('almbrandonline')) {
		comp.model.campaign.valid = true;
		comp.model.campaign.ID = 'almbrandonline';
	}

	if (comp.model.campaign.valid) {
		const campaign = comp.cms.campaigns.get(comp.model.campaign.ID);
		comp.model.campaign.productStepDiscount = campaign.productStepDiscount;
		comp.model.campaign.discount = campaign.discount;
		comp.model.campaign.originalDiscount = campaign.discount;
		comp.model.campaign.discountDisplay = Formatter.format(100 * campaign.discount) + ' %';
		comp.model.campaign.tiaDiscount = campaign.tiaDiscount;
		comp.model.campaign.description = campaign.description;
		comp.model.campaign.groupId = campaign.groupId;
		comp.model.campaign.includeDiscountPlusCustomer = campaign.includeDiscountPlusCustomer;
		if (comp.cms.useLocalStorage) {
			// save to localStorage
			const expires = new Date();
			expires.setTime(new Date().getTime() + comp.cms.campaignStoreValidMillis);
			// cloneDeep, since localForage cannot persist vue proxy
			localforage.setItem(
				'campaign' + comp.model.productName,
				cloneDeep({
					campaign: comp.model.campaign,
					expires,
				})
			);
		}
	}

	// make sure campaign discount is updated, after campaign is loaded
	if (comp.model.campaign.valid) {
		comp.model.campaign.discount = getDiscountForAmountProducts(comp.model.campaign);
		comp.model.campaign.originalDiscount = comp.model.campaign.discount;
		comp.model.campaign.discountDisplay = Formatter.format(100 * comp.model.campaign.discount) + ' %';
	}
};

const handleHeaderBadge = (comp) => {
	if (!comp.headerBadgeTxt) {
		comp.model.showHeaderBadge = false;
		return;
	}
	if (comp.model.campaign.valid && comp.model.campaign.ID && comp.model.campaign.ID !== 'almbrandonline') {
		comp.model.showHeaderBadge = false;
		return;
	}
	comp.model.showHeaderBadge = true;
};

const removeStepsIfBasket = (comp) => {
	if (comp.model.multipleProducts && comp.model.productName !== 'kurv') {
		comp.steps = comp.steps.filter((step) => !STEPS_EXCLUDE_BASKET.includes(step));
	}
	// When STEP.OVERVIEW is not present - routelink for STEPS:CHOOSE_PACKAGE must be updated
	const choosePackageBlock = comp.contentBlocks.find((block) => block.name === STEP.PACKAGE);
	if (choosePackageBlock) {
		choosePackageBlock.routePath = STEP.ADDITIONAL_INFO;
	}
};
const removeUnreferencedBlocks = (comp) => {
	let removeBlocks = [];
	comp.contentBlocks.forEach((block, inx) => {
		if (comp.model.campaign.valid && block.name === comp.model.campaign.ID) {
			// check for partner welcome page
			comp.model.campaign.hasContentPage = true;
			return;
		}
		if (!comp.steps.includes(block.name)) {
			removeBlocks.push(inx);
			return;
		}
		if (comp.model.multipleProducts || !comp.model.showExistingAlmBrandProducts) {
			// DOG
			if (comp.model.productName === InsurancesProductEnum.HUNDE_FORSIKRING && block.name === STEP.PERSON_INFO) {
				if (comp.isValid(STEP.PERSON_INFO)) {
					// remove step person_info
					removeBlocks.push(inx);
					// comp.cms.defaultCard = STEP.PACKAGE;
					comp.contentBlocks.find((block) => block.name === STEP.DOG_INFO).routePath = STEP.PACKAGE;
				}
			}
			// CHILDREN ACCIDENT
			if (
				comp.model.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING &&
				block.name === STEP.PERSON_INFO
			) {
				if (comp.isValid(STEP.PERSON_INFO)) {
					// remove step person_info
					removeBlocks.push(inx);
					comp.contentBlocks.find((block) => block.name === STEP.CHILDREN_INFO).routePath = STEP.PACKAGE;
				}
			}
			// HOUSE
			if (comp.model.productName === InsurancesProductEnum.HUS_FORSIKRING && block.name === STEP.PERSON_INFO) {
				if (comp.isValid(STEP.PERSON_INFO)) {
					// remove step person_info
					removeBlocks.push(inx);
					comp.cms.defaultCard = STEP.PACKAGE;
					if (comp.model.campaign.valid) {
						const block = comp.contentBlocks.find((block) => block.name === comp.model.campaign.ID);
						if (block) {
							block.routePath = STEP.PACKAGE;
						}
					}
				}
			}
			//TRAVEL
			if (comp.model.productName === InsurancesProductEnum.REJSE_FORSIKRING && block.name === STEP.PERSON_INFO) {
				if (comp.isValid(STEP.PERSON_INFO)) {
					// remove step person_info
					removeBlocks.push(inx);
					comp.cms.defaultCard = STEP.PACKAGE;
					if (comp.model.campaign.valid) {
						const block = comp.contentBlocks.find((block) => block.name === comp.model.campaign.ID);
						if (block) {
							block.routePath = STEP.PACKAGE;
						}
					}
				}
			}
			// DONT REMOVE STEPS IN ACCIDENT
			// DONT REMOVE STEPS IN CAR
		}
	});

	removeBlocks = removeBlocks.reverse();
	removeBlocks.forEach((inx) => {
		comp.contentBlocks.splice(inx, 1);
	});
};

const loadCampaignFromStorage = async (comp): Promise<boolean> => {
	try {
		if (!comp.cms.useLocalStorage) {
			// don't load from localStorage
			return;
		}
		const campaign: any = await localforage.getItem('campaign' + comp.model.productName);
		if (!campaign) {
			return false;
		}
		if (new Date().getTime() < campaign.expires.getTime()) {
			comp.model.campaign = campaign.campaign;
			return true;
		} else {
			// remove from storage
			localforage.removeItem('campaign' + comp.model.productName);
		}
		return false;
	} catch (error) {
		// Not handled - could be versioning error
		return false;
	}
};
