import Coverage from '../components/coverageComponent/Coverage';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/services/environmentService';
// list and order of steps
export const STEPS: Array<string> = [
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class Model {
	public static model = {
		campaign: {
			ID: undefined,
			valid: false,
			init: false,
			hasContentPage: false,
			subtitle: undefined,
			uniqueKey: undefined,
			discount: undefined,
		},
		personInfo: {
			subtitle: undefined,
			customerAge: undefined,
			zipCode: undefined,
			zipName: undefined,
			familyMemberCount: undefined,
			familyMemberCountId: undefined,
			almbrandCustomer: undefined,
			existingAlmBrandProducts: [],
		},
		choosePackage: {
			subtitle: undefined,
			ownRiskId: undefined,
			segmentedProductProperty: undefined, // destination
			selectedPackage: undefined,
			monthYear: undefined, //this.monthYearArray[0].value,
			forfald: undefined, // bruges til Mastercard kampagne
		},
		contact_information: {
			subtitle: undefined,
			name: undefined,
			email: undefined,
			phone: undefined,
			cpr: undefined,
			customerNo: undefined,
			accept: false,
		},
		overview: {
			subtitle: undefined,
			highlights: undefined,
			details: undefined,
		},
		additionalInfo: {
			subtitle: undefined,
			existInsurance: undefined,
			existingInsurance: undefined,
			existingInsuranceId: undefined,
			validNow: undefined,
			validFromDate: undefined,
			skader: undefined,
			rki: undefined,
		},
		payment: {
			subtitle: undefined,
			regNo: undefined,
			kontoNo: undefined,
			userSelected: false,
		},
		pristineStep: new Map([
			[STEP.PERSON_INFO.valueOf(), true],
			[STEP.PACKAGE.valueOf(), true],
			[STEP.ADDITIONAL_INFO.valueOf(), true],
			[STEP.OVERVIEW.valueOf(), true],
			[STEP.CONTACT_INFORMATION.valueOf(), true],
			[STEP.PAYMENT.valueOf(), true],
		]),
		productName: InsurancesProductEnum.REJSE_FORSIKRING,
		ownRiskOptions: [] as Array<number>,
		ownRiskLabels: [] as Array<string>,
		calculation: {
			isCalculated: false,
			updatePrices: ['pr1', 'pr2', 'pr3'],
			excessIdDefault: undefined,
			packageIds: undefined,
			packages: undefined,
			abCalc: {
				excesses: [],
				packs: new Map<number, Object>(),
			},
			allCoverages: new Map<number, Map<number, Array<Coverage>>>(),
			discount: undefined,
			discountDisplay: undefined,
		},
		modal: {
			show: false,
			id: 'none',
			track: false,
			trackToken: undefined,
			errorType: undefined,
			productName: undefined,
			stepName: undefined,
			trackMsg: undefined,
			content: undefined,
			btnStyle: undefined,
			btnLabel: undefined,
			btnAction: undefined,
			btnSecondStyle: undefined,
			btnSecondLabel: undefined,
			title: undefined,
			redirect: undefined,
		},
		modalId: 'none',
		showReceipt: false,
		currentCardName: 'none',
		updateAll: 'updateAll',
		readyForBasket: false,
		multipleProducts: false,
		showExistingAlmBrandProducts: true,
	};

	public static mock() {
		if (isDevelop) {
			Object.assign(Model.model.personInfo, {
				almbrandCustomer: 'nej',
				// almbrandCustomer:"ja",
				// existingAlmBrandProducts:['Indboforsikring', 'Husforsikring'],
				customerAge: '45',
				existingAlmBrandProducts: [],
				familyMemberCount: '4 personer',
				familyMemberCountId: 4,
				address: 'Adolphsvej 19, 2820 Gentofte',
				houseNr: '19',
				streetName: 'Adolphsvej',
				zipCode: '2820',
				zipName: 'Gentofte',
			});

			Object.assign(Model.model.additionalInfo, {
				existInsurance: 'nej',
				validNow: 'ja',
				skader: '0',
				rki: 'nej',
			});

			Object.assign(Model.model.contact_information, {
				accept: true,
				cpr: '2342342323',
				customerNo: undefined,
				email: 'abklku@almbrand.dk',
				name: 'Test Testesen',
				phone: '11111111',
				subtitle: 'Test Testesen',
			});
			// Object.assign(Model.model.payment, {
			//     kontoNo:'123456',
			//     regNo:'1233',
			// });
		}
	}
}
