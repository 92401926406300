import { isDevelop } from '@/services/environmentService';
import { dataLayer } from '@/services/trackingUtility';
import { cloneDeep } from 'lodash';
import { ActionContext, Dispatch } from 'vuex';

export const ADD_GA4_EVENT = 'ADD_GA4_EVENT';
export const ADD_GA4_IMMEDIATE_EVENT = 'ADD_GA4_IMMEDIATE_EVENT';
export const ADD_GA4_PAGE_EVENTS = 'ADD_GA4_PAGE_EVENTS';
export const FIRE_GA4_EVENTS = 'FIRE_GA4_EVENTS';

const MUTATE_ADD_GA4_EVENT = 'MUTATE_ADD_GA4_EVENT';
const MUTATE_ADD_GA4_PAGE_EVENTS = 'MUTATE_ADD_GA4_PAGE_EVENTS';
const MUTATE_FIRE_GA4_EVENTS = 'MUTATE_FIRE_GA4_EVENTS';

const doLog = isDevelop;
export interface TrackingContextState {
	events: Map<string, any>;
	pageEvents: Array<any>;
	lastUrl: string;
	lastPageEventFiredToUrl: string;
	isFirstPageEventFired: boolean;
	resetEventId: string;
}

const state: TrackingContextState = {
	events: new Map(),
	pageEvents: [],
	lastUrl: '',
	lastPageEventFiredToUrl: 'initial',
	isFirstPageEventFired: false,
	resetEventId: 'reset',
};

const mutations = {
	[MUTATE_ADD_GA4_PAGE_EVENTS](state: TrackingContextState, events: Array<any>) {
		state.pageEvents = events;
	},
	[MUTATE_ADD_GA4_EVENT](state: TrackingContextState, event: any) {
		// always override, if there is an identical event - shouldn't happen
		if (!event?.event && event.hasOwnProperty('ecommerce')) {
			state.resetEventId += 1;
			state.events.set(state.resetEventId, event);
		} else {
			state.events.set(event.event, event);
		}
	},
	[MUTATE_FIRE_GA4_EVENTS](state: TrackingContextState) {
		// sanity check
		if (!state.pageEvents[0]?.fullUrl || state.lastPageEventFiredToUrl === state.pageEvents[0]?.fullUrl) {
			// console.log('CAUGHT SANITY CHECK', state.lastPageEventFiredToUrl);
			return;
		}
		state.lastPageEventFiredToUrl = state.pageEvents[0]?.fullUrl;

		state.pageEvents.forEach((event) => {
			if (doLog) {
				if (event.fullUrl) {
					console.log(
						'**************************************PAGE LOAD*********************************************'
					);
					console.log('General pageLoad', event.previousPageUrl, event.fullUrl);
				} else {
					console.log(event.event);
				}
			}
			push(event);
		});
		state.pageEvents = [];

		state.events.forEach((event) => {
			if (doLog) {
				if (event?.event) {
					console.log(event.event, cloneDeep(event));
				} else {
					//console.log(event);
					if (event.hasOwnProperty('ecommerce')) {
						console.log('reset ecommerce', cloneDeep(event));
					} else {
						console.warn('WHAT!!!!!', cloneDeep(event));
					}
				}
			}
			push(event);
		});
		state.events = new Map();
		state.isFirstPageEventFired = true;
	},
};

const actions = {
	async [ADD_GA4_PAGE_EVENTS]({ commit, state, dispatch }: ActionContext<any, any>, events: Array<Object>) {
		commit(MUTATE_ADD_GA4_PAGE_EVENTS, events);
	},
	async [ADD_GA4_IMMEDIATE_EVENT]({ commit, state, dispatch }: ActionContext<any, any>, event: Object) {
		if (doLog) {
			console.log('immediate', event);
		}
		push(event);
	},
	async [ADD_GA4_EVENT]({ commit, state, dispatch }: ActionContext<any, any>, event: Object) {
		commit(MUTATE_ADD_GA4_EVENT, event);
	},
	async [FIRE_GA4_EVENTS]({ commit, state, dispatch }: ActionContext<any, any>) {
		commit(MUTATE_FIRE_GA4_EVENTS);
	},
};

const push = (event) => {
	// cloneDeep -> remove vue proxy
	dataLayer.push(cloneDeep(event));
};

export default {
	state,
	mutations,
	actions,
};
