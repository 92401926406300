
	import { defineComponent } from 'vue';
	import { defaultConfig } from '@/cms/api/ApiConfig';
	import store from '@/store/store';
	import { runAtStart } from '@/startupRunners';
	// import { loadUsabillaScript } from '@/jsTempImport/loadUsabillaScript';
	// import { loadRecaptchaScript } from '@/services/reCaptchaService';

	export default defineComponent({
		data() {
			return {
				firstLoad: true,
			};
		},
		computed: {
			// meta() {
			//   /* eslint-disable-next-line
			//   const { metaDescription, metaKeywords, metaTitle, ogImage, ogTitle, ogDescription } = (store.state.epiContent.model as any);
			//   return {
			//     metaDescription,
			//     metaKeywords,
			//     metaTitle,
			//     ogImage,
			//     ogTitle,
			//     ogDescription
			//   }*/
			// },
			canonicalUrl() {
				return store.state.epiContent.model?.canonicalUrl;
			},
			themeName: () => {
				return store?.getters?.getThemeContext;
			},
		},
		methods: {
			// handleResize() {
			//   store.commit('setWindowWidth', window.innerWidth)
			// },
			// handleScroll() {
			//   store.commit('setScrollPosition', document.documentElement.scrollTop || document.body.scrollTop)
			// },
			isTouchDevice() {
				const isTouchDevice =
					'ontouchstart' in window ||
					navigator.maxTouchPoints > 0 ||
					// eslint-disable-next-line
					(window.navigator as any).msMaxTouchPoints > 0;
				store.commit('setDeviceType', isTouchDevice);
				return isTouchDevice;
			},
			getScrollbarWidth() {
				if (store.state.isTouchDevice) {
					return;
				}
				const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
				document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
			},
			setCanonicalUrl() {
				const tag = document.querySelector('link[rel="canonical"]');
				if (tag) tag.setAttribute('href', this.canonicalUrl.url);
			},
		},
		// created() {
		//   // window.addEventListener('resize', this.handleResize);
		//   // window.addEventListener('scroll', this.handleScroll);

		//   // loadUsabillaScript();
		//   // loadRecaptchaScript();
		// },
		mounted() {
			const element = document.getElementById('app');

			let domainUrl: string;
			domainUrl = element?.getAttribute('data-domain') ?? process.env.VUE_APP_BASEURL ?? '';

			const contentDeliveryApiUri =
				element?.getAttribute('data-contentdelivery-url') ?? process.env.VUE_APP_CONTENTDELIVERY_URL ?? '';
			// const language = element?.getAttribute("data-language") ?? "";
			// store.state.epiContent.modelLanguage = language;
			defaultConfig.apiUrl = domainUrl + contentDeliveryApiUri;
			defaultConfig.baseUrl = domainUrl;
			defaultConfig.selectAllProperties = true;
			defaultConfig.expandAllProperties = true;

			this.$root['themeBrand'] = element?.getAttribute('data-theme-brand');
			this.$root['themeBrandSimple'] = element?.getAttribute('data-theme-brand-simple');
			this.$root['themeFavicon'] = element?.getAttribute('data-theme-favicon');
			this.$root['appVersion'] = element?.getAttribute('data-app-version');
			this.$root['appVersionMessage'] = element?.getAttribute('data-app-version-message');

			/*
			 * If the `epiEditMode` parameter is present we know we're
			 * in either edit- or preview mode and should include following scripts.
			 */
			if (this.$route.query.epieditmode || document.location.search.includes('epieditmode')) {
				const domainScript = document.createElement('script');
				domainScript.innerHTML = "document.domain = 'localhost';";
				document.body.appendChild(domainScript);

				const communicationScript = document.createElement('script');
				communicationScript.src = `${domainUrl}/episerver/cms/latest/clientresources/epi-cms/communicationinjector.js`;
				document.body.appendChild(communicationScript);
			}

			this.isTouchDevice();
			document.onreadystatechange = () => {
				// wait for DOM to render
				if (document.readyState === 'complete') setTimeout(this.getScrollbarWidth, 100);
			};

			runAtStart(this);
		},
		beforeUnmount() {
			window.removeEventListener('resize', this.handleResize);
			window.removeEventListener('scroll', this.handleScroll);
		},
		// watch: {
		//   meta() { // on page change
		//     console.log('meta!!!!!');

		//     this.setMetaData();
		//     this.setCanonicalUrl();

		//   },
		//   // $route(from, to) {
		//   // }
		// }
	});
