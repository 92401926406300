import BuyHouse from './BuyHouseComponent';
import { cloneDeep } from 'lodash';
import AxiosService from '@/services/axiosService';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { SelectableData, UPDATE_SELECTABLE } from '@/store/modules/selectableContext';
import store from '@/store/store';
import { REMOVE_CALCULATOR } from '@/store/modules/calculatorContext';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	getDiscount,
	getRisksForPackage,
	mapCoveragesFromProductConfig,
	mapPriceResult,
	reducePack,
	setPrices,
} from '../services/CalculationService';
import { Formatter, hasAddressSupplement, monthYearArray } from '../services/ValidationService';
import { togglePopup } from '../services/AlertService';
import { updateExcessesFromCalculation } from '../services/ProductService';

export default class HouseCalculator {
	private buyHouse: BuyHouse; // component using calculator
	private model: any; // the data structure for state and properties
	private cms: any; // settings from EPI
	private calcConfigHouse: any;
	private successCalc = true;
	private axiosService: AxiosService;
	private calculatorUrl: string;
	private calculatorUrlOther: string;
	private calculatorInfo: Array<any>;

	constructor(buyHouse: BuyHouse) {
		this.buyHouse = buyHouse;
		this.model = buyHouse.model;
		this.cms = buyHouse.cms;
		this.calculatorInfo = JSON.parse(buyHouse.calculatorInfoBlock.markup);

		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + this.cms.calculatorUrlVue3; // either holiday or house.
		this.calculatorUrlOther =
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + this.cms.calculatorUrlOtherVue3; // either holiday or house.
		this.axiosService = new AxiosService(this.calculatorUrl);
		this.setUpConfig();
	}

	public async setUpConfig() {
		if (!this.calcConfigHouse) {
			if (this.model.campaign.valid) {
				this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
			}
			const calculatorInfo = this.calculatorInfo.find(
				(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId
			);
			this.calcConfigHouse = calculatorInfo.insuranceConfiguration;
		}
		this.model.calculation.abCalc = {
			excessIdDefault: this.calcConfigHouse.excessIdDefault,
			packageIds: this.calcConfigHouse.packageIds,
			packages: cloneDeep(this.calcConfigHouse.packages),
			coveragesDetails: this.calcConfigHouse.coverages,
		};

		if (this.model.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING) {
			this.cms.insuredValue = this.calcConfigHouse.insuredValue;
		}

		this.setupExcessList();
		this.model.calculation.abCalc.packages.forEach((pack) => {
			pack.coverages = [];
			if (this.cms.extendCoverages) {
				pack.includedCoverageIds.forEach((riskId) => {
					const coverage = this.calcConfigHouse.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
				pack.expandedCoverages = [];
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfigHouse.coverages.find((x) => riskId === x.riskId);
					pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
				});
			} else {
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfigHouse.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
			}
			this.cms.mapCoverageGroups(pack.coverages);
		});
		this.model.calculation.abCalc.packs = new Map<number, Object>();
		this.model.calculation.abCalc.excesses.forEach((excess) => {
			const calcs = [];
			this.model.calculation.abCalc.packs.set(excess.id, calcs);
			this.model.calculation.abCalc.packages.forEach((pack) => {
				calcs.push(reducePack(cloneDeep(pack)));
			});
		});
		this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'

		mapCoveragesFromProductConfig(this.model, this.cms);
		this.updateCalcKeys();
	}

	public setupExcessList() {
		this.model.calculation.abCalc.excesses = this.calcConfigHouse.excessList;
		this.model.ownRiskOptions = [];
		this.model.ownRiskLabels = [];

		this.model.calculation.abCalc.excesses.forEach((excess) => {
			if (this.model.personInfo.customerAge >= excess.minCustomerAge) {
				this.model.ownRiskOptions.push(excess.id);
				this.model.ownRiskLabels.push(Formatter.format(excess.amount) + ' kr.');
			}
		});
	}

	private checkFirstPackagePrices(excessId: number) {
		let ok = true;
		const packages = this.model.calculation.abCalc.packs.get(excessId);
		packages.forEach((calc) => {
			if (!calc.totalPrice) {
				ok = false;
			}
		});
		return ok;
	}

	private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
		try {
			this.successCalc = true;
			const rebate = getDiscount(this.cms);
			this.model.calculation.discount = rebate.discount;
			this.model.calculation.discountDisplay = rebate.discountDisplay;

			const promises = [];
			for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
				const packageId = this.model.calculation.abCalc.packageIds[index];
				promises.push(this.onePackageCalcEpi(monthly, excessId, packageId));
			}
			await Promise.all(promises);
			return Promise.resolve(this.successCalc);
		} catch (ex) {
			console.error(ex);
			if (monthly) {
				this.errorPopUp(400);
			}
			return Promise.resolve(false);
		}
	}

	public async getCalculations(excessId?: number): Promise<boolean> {
		if (!this.model.calculation.isCalculated) {
			store.state.showSpinner = true;
			store.state.calculatorContext.calculating = true;
			// handle "eternal" spinning
			setTimeout(() => {
				if (store.state.showSpinner) {
					store.state.showSpinner = false;
					store.state.calculatorContext.calculating = false;
				}
			}, this.cms.calculationSpinnerTimeout);
		}

		// reset prices
		const selectedExcessId = excessId || this.model.choosePackage.ownRiskId;

		const pack = this.model.calculation.abCalc.packs.get(selectedExcessId);
		pack.totalPriceDisplay = undefined;

		return this.getEpiCalculations(selectedExcessId);
	}

	private async getEpiCalculations(excessId?: number): Promise<boolean> {
		// reset calculation
		await this.setUpConfig();

		let success = true;

		const _excessId = excessId || this.model.calculation.abCalc.excessIdDefault;

		if (hasAddressSupplement(this.model, this.cms)) {
			this.model.calculation.addressSupplement = this.cms.addressSupplement;
		} else {
			this.model.calculation.addressSupplement = 1;
		}

		success = await this.calculatePackagesEpi(true, _excessId);
		// check if has got prices
		if (!success) {
			success = this.checkFirstPackagePrices(_excessId);
		}
		if (success) {
			this.calculatePackagesEpi(false, _excessId);
		}
		this.model.calculation.isCalculated = success;
		store.state.showSpinner = false;
		store.state.calculatorContext.calculating = false;
		// triggerCustomGtmEvent({
		//     'event': 'track-vp',
		//     'virtualPath': `${getTrackingPreUrl()}${this.model.productName}/prices_shown`,
		// });

		return success;
	}

	private async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {
		// const response = await this.abAxiosCalculator.getRetry(this.cms.oldCalculatorPrices, this.createParamsEpi(excessId, packageId, monthly ? 1 : 0));
		// const response = JSON.parse(JSON.stringify(HouseTestCalculation));

		const response = await this.axiosService.getRetryDefaultUrl(
			this.createParamsEpi(excessId, packageId, monthly ? 1 : 0)
		);
		if (response?.status !== 200) {
			this.successCalc = false;
			this.errorPopUp(response?.status);
			return Promise.resolve(false);
		}

		const pack = this.model.calculation.abCalc.packs.get(excessId);
		mapPriceResult(response);
		setPrices(packageId, response, monthly, pack, this.model, this.cms);
		if (!this.model.updatedExcessListFromProduct) {
			updateExcessesFromCalculation(response, this.calcConfigHouse);
			this.setupExcessList();
			this.model.updatedExcessListFromProduct = true;
		}

		this.updateCalcKeys();
		return Promise.resolve(true);
	}

	private errorPopUp(status) {
		const popup: any = {
			content: this.cms.defaultCalcErrorContent,
			track: true,
			btnSecondLabel: 'Ok',
			id: status === 250 ? 'calcWarning' : 'calcError',
			title: this.cms.defaultCalcErrorTitle,
			show: true,
			trackToken: 'House/holiday house calculator',
			errorType: status ? status + '' : undefined,
		};

		if (this.cms.useBtnInlineFail) {
			popup.btnInlineLabel = this.cms.btnInlineFailLabel;
			popup.btnInlineStyle = this.cms.btnInlineFailStyle;
			popup.btnInlineIcon = this.cms.btnInlineIcon;
			popup.btnInlineAction = () => {
				this.getCalculations();
			};
		}

		togglePopup(this.cms, popup);
	}

	public async checkForValidAddress(): Promise<boolean> {
		try {
			let gotoOtherCalculator = false;
			const packageId = this.model.calculation.abCalc.packageIds[0];
			const excessId = this.model.calculation.abCalc.excessIdDefault;
			let response;
			let responseOther;

			try {
				store.state.showSpinner = true;
				// Spinner on load is false when personInfo card is not required
				let maxRun = 50;
				const interval = setInterval(() => {
					store.state.showSpinner = true;
					if (response?.status === 200 || responseOther?.status === 200 || maxRun < 1) {
						store.state.showSpinner = false;
						clearInterval(interval);
					}
					maxRun--;
				}, 100);

				const params = this.createParamsEpi(excessId, packageId, 1);
				response = await this.axiosService.getQuery(this.calculatorUrl, params);
				if (!response || response?.status === 500) {
					// try again, cannot use axiosretry, since error code 400 is OK
					response = await this.axiosService.getQuery(this.calculatorUrl, params);
					if (response?.status === 500) {
						// try again, cannot use axiosretry, since error code 400 is OK
						response = await this.axiosService.getQuery(this.calculatorUrl, params);
					}
				}
			} catch (e) {
				console.warn(e);
			} finally {
				store.state.showSpinner = false;
			}

			if (response.status === 200) {
				// store.state.showSpinner = false;
				if (!this.model.updatedExcessListFromProduct) {
					updateExcessesFromCalculation(response, this.calcConfigHouse);
					this.setupExcessList();
					this.model.updatedExcessListFromProduct = true;
				}
				return Promise.resolve(true);
			}

			if (response.status === 400) {
				// check if calculation will work for other calculator either holiday or house.
				try {
					store.state.showSpinner = true;
					let otherProduct: string;
					if (this.model.productName === InsurancesProductEnum.HUS_FORSIKRING) {
						otherProduct = InsurancesProductEnum.FRITIDSHUS_FORSIKRING;
					} else {
						otherProduct = InsurancesProductEnum.HUS_FORSIKRING;
					}

					const params = this.createParamsEpi(
						this.cms.excessIdOther,
						this.cms.basePackageOther,
						1,
						this.cms.selectedRisksOther,
						otherProduct
					);
					responseOther = await this.axiosService.getRetry(this.calculatorUrlOther, params);
				} catch (e) {
					console.error(e);
				} finally {
					store.state.showSpinner = false;
				}

				gotoOtherCalculator = responseOther.status === 200;
			}

			if (response.status !== 200) {
				let popup = {
					content: this.cms.failText,
					title: this.cms.failTitle,
				};

				if (gotoOtherCalculator && response.status === 400) {
					popup = this.handleCalculatorChange();
				}

				if (!gotoOtherCalculator && response.data.warningMessage) {
					popup.content = response.data.warningMessage.replace(/\[[^\]]*?\]/g, '').trim(); // remove error code from backend e.g. [BR2]
				}
				const modal: any = popup;
				modal.title = popup.title;
				modal.content = popup.content;
				modal.track = true;
				modal.trackToken = `error/invalidAddress`;
				modal.btnSecondLabel = 'Ok';
				modal.id = 'calcWarning';
				modal.show = true;
				togglePopup(this.cms, modal);
				return Promise.resolve(false);
			}
			return Promise.resolve(true);
		} catch (error) {
			console.error(error);
			return Promise.resolve(false);
		} finally {
			store.state.showSpinner = false;
		}
	}

	private handleCalculatorChange(): { content: string; title: string } {
		let address = this.model.personInfo.address;
		if (InsurancesProductEnum.FRITIDSHUS_FORSIKRING === this.model.productName) {
			address = this.model.personInfo.holidayAddress.address;
		}

		const customerAge = this.model.personInfo.customerAge;
		const almbrandCustomer = this.model.personInfo.almbrandCustomer;
		const gotoProduct =
			InsurancesProductEnum.HUS_FORSIKRING === this.model.productName
				? InsurancesProductEnum.FRITIDSHUS_FORSIKRING
				: InsurancesProductEnum.HUS_FORSIKRING;
		const existingAlmBrandProducts: Array<string> = this.model.personInfo.existingAlmBrandProducts.filter(
			(product) => product.toLocaleLowerCase() !== gotoProduct
		);
		const query = {
			step: 'person_info',
			address,
			customerAge,
			almbrandCustomer,
			existingAlmBrandProducts,
			product: gotoProduct,
		};

		const popup: any = {
			title: this.cms.changeCalcTitle,
			content: this.cms.changeCalcContent,
		};
		popup.btnInlineLabel = this.cms.btnInlineLabel;
		popup.btnInlineStyle = this.cms.btnInlineStyle;
		popup.btnInlineAction = () => {
			// const virtualPath = `${getTrackingPreUrl()}${this.model.productName}/change_to_${gotoProduct}/`;
			// triggerCustomGtmEvent({
			//     'event': 'track-vp',
			//     virtualPath
			// });
			// remove this from basket if there is only one product in basket
			this.removeFromBasket();
			// reset this personInfo
			this.model.personInfo = {
				subtitle: undefined,
				customerAge: undefined,
				address: undefined,
				zipCode: undefined,
				zipName: undefined,
				streetName: undefined,
				houseNr: undefined,
				floorNr: undefined,
				doorNr: undefined,
				kvhxValue: undefined,
				almbrandCustomer: undefined,
				existingAlmBrandProducts: [],
				holidayAddress: {
					address: undefined,
					zipCode: undefined,
					zipName: undefined,
					streetName: undefined,
					houseNr: undefined,
					floorNr: undefined,
					doorNr: undefined,
					kvhxValue: undefined,
				},
			};

			// TODO KLK remove ref to buyHouse
			this.buyHouse.$router.push({
				path: 'gotoproduct',
				query,
			});
			togglePopup(this.cms, {}); // close popup
			setTimeout(() => {
				store.dispatch(REMOVE_CALCULATOR, this.model.productName);
			}, this.cms.initialtimeout);
		};
		return popup;
	}
	private async removeFromBasket() {
		const selectGroup = await store.getters.getSelectableGroup('basket');
		const prods = selectGroup.filter((product) => product.startsWith('hus') || product.startsWith('fritids'));
		if (prods?.length < 2) {
			// remove this product from basket
			const toggle: SelectableData = {
				group: 'basket',
				id: this.model.productName + '0',
				isSelected: false,
				sortMultiple: true,
			};
			await store.dispatch(UPDATE_SELECTABLE, toggle);
		}
	}

	private createParamsEpi(
		excessId: number,
		packageId: number,
		monthlyPrices: number,
		selectedRisks?: Array<string>,
		forceProductName?: string
	) {
		const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g, ''));
		const selected = selectedRisks ? selectedRisks.toString() : getRisksForPackage(packageId, this.model);
		const productName = forceProductName ? forceProductName : this.model.productName;
		if (productName === InsurancesProductEnum.HUS_FORSIKRING) {
			const streetName = this.model.personInfo.streetName || this.model.personInfo.holidayAddress.streetName;
			const buildingNoWithLetter = this.model.personInfo.houseNr || this.model.personInfo.holidayAddress.houseNr;
			const postalCode = this.model.personInfo.zipCode || this.model.personInfo.holidayAddress.zipCode;
			const params = {
				plusCustomer: 'PK0',
				age: customerAge,
				excessId: `${excessId}`,
				streetName,
				// doorNr: this.model.personInfo.doorNr,
				// floorNr: this.model.personInfo.floorNr,
				buildingNoWithLetter,
				postalCode,
				paymentsPerYear: monthlyPrices === 1 ? 12 : 1,
				selected,

				// kvhxValue: this.model.personInfo.kvhxValue,
				// selectedOptionalProducts: '46,9,8',
				// zipName: `${this.model.personInfo.zipName}`,
				// extendedPackage: `${packageId}`,
				// calculatorBlockId: this.cms.oldCalculatorBlockId,
			};
			return params;
		} else {
			const streetName = this.model.personInfo.holidayAddress.streetName || this.model.personInfo.streetName;
			const buildingNoWithLetter = this.model.personInfo.holidayAddress.houseNr || this.model.personInfo.houseNr;
			const postalCode = this.model.personInfo.holidayAddress.zipCode || this.model.personInfo.zipCode;
			// console.log('to holiday', this.model.personInfo);

			const params = {
				plus_customer: 'PK0',
				age: customerAge,
				excess_id: `${excessId}`,
				street_name: streetName,
				building_no_with_letter: buildingNoWithLetter,
				postal_code: postalCode,
				payments_per_year: monthlyPrices === 1 ? 12 : 1,
				selected,
				insured_value: this.calcConfigHouse.insuredValue, // only holidayHouse
			};
			return params;
		}
	}

	public updateCalcKeys() {
		this.model.calculation.updatePrices = this.model.calculation.updatePrices.map((elem) => {
			return (elem += '1');
		});
	}
}
