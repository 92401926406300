import { getDevice, isDevelop } from '@/services/environmentService';
import store from '@/store/store';
import { CalculatorComponent } from '../BuyInsuranceComponent';
import { togglePopup } from '../services/AlertService';
import { handleSpecialCarDiscounts } from '../services/CalculationService';
import {
	addProp,
	buildExistingProductsDescription,
	getPaymentInfo,
	sendErrorMailToTeam,
	submitOrder,
} from '../services/OrderService';
import { getCoverageNames } from '../services/ProductService';
import { Formatter } from '../services/ValidationService';

export default class MapOrderDescription {
	private uiComp: CalculatorComponent;

	public constructor(uiComp: CalculatorComponent) {
		this.uiComp = uiComp;
	}

	public async orderByEmail() {
		const model = this.uiComp.model;
		const cms = this.uiComp.cms;
		try {
			const pack = model.choosePackage.selectedPackage;
			let desc = '';

			let tracking = '';

			if (model.contact_information.customerNo) {
				const device = getDevice();
				desc += `<li><h2><i>Kunde genkendt ${device}</i></h2></li>`;
				tracking += '/existingCustomer';
			}
			if (model.calculation?.addressSupplement > 1) {
				desc += '<li><h2><i>Adresse tillæg</i></h2></li>';
				tracking += '/addressSupplement';
			}
			if (model.campaign.valid) {
				const id = model.campaign.ID ? model.campaign.ID.toLocaleUpperCase() : 'Ukendt!';
				desc += `<li><h2><i>Kampagne ${id}</i></h2></li>`;
				tracking += `/campaign/${id}`;
			}
			if (model.additionalInfo.rki?.toLowerCase() === 'ja') {
				desc += '<li><h2><i>Kunde er registreret i RKI</i></h2></li>';
				// tracking += "/rki";
			}
			if (model.additionalInfo.skader !== '0') {
				desc += `<li><h2><i>Kunde har ${model.additionalInfo.skader} skader</i></h2></li>`;
				// tracking += "/damages";
			}

			if (!model.carInfo.licenseplate || model.carInfo.licenseplate === 'ikke oplyst') {
				desc += `<li><h2><i>Nummerplade ikke oplyst</i></h2></li>`;
				tracking += '/nolicenseplate';
			}

			if (desc !== '') {
				desc = '<ul>' + desc + '</ul>';
			}

			await submitOrder(desc, tracking, this.uiComp);
		} catch (e) {
			store.state.showSpinner = false;
			if (isDevelop) {
				model.modal.content = model.modal.content + '<br><div style="border: 2px solid red">' + e + '</div> ';
			}
			togglePopup(cms, {
				id: 'error',
				show: true,
				redirect: undefined,
				track: true,
				trackToken: 'car submitOrder',
			});
			sendErrorMailToTeam(e, model);
		}
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		const model = this.uiComp.model;
		const cms = this.uiComp.cms;

		const pack = model.choosePackage.selectedPackage;

		if (pack?.specialDiscount) {
			const discount = handleSpecialCarDiscounts(model, cms);
			if (discount.specialDiscountType === 'vehicleGroup') {
				desc += addProp(
					`Bilgruppe rabat (${cms.vehicleDiscountGroups.join(', ')}) inkluderet i pris`,
					Formatter.format(100 * pack.specialDiscount) + ' %'
				);
			} else if (discount.specialDiscountType === 'fuelType') {
				desc += addProp(
					`El/Hybrid rabat (bilgrp. ${cms.electricFuelTypeVehicleGroups.join(', ')}) inkluderet i pris`,
					Formatter.format(100 * pack.specialDiscount) + ' %'
				);
			} else {
				desc += addProp(`UKENDT rabat inkluderet i pris`, Formatter.format(100 * pack.specialDiscount) + ' %');
			}
		}

		desc += '<h2>Ejer</h2>';
		const now: Date = new Date();
		desc += addProp(
			'Email sendt',
			`${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${
				now.getMonth() + 1
			}-${now.getFullYear()}`
		);
		desc += addProp('Navn', model.contact_information.name);
		desc += addProp('Adresse', `${model.personInfo.address}`);
		desc += addProp('Alder', model.personInfo.customerAge);
		if (model.contact_information.customerNo) {
			desc += addProp('KundeNr', model.contact_information.customerNo);
		} else {
			desc += addProp('Cpr', model.contact_information.cpr);
		}
		desc += addProp('Antal skader', model.additionalInfo.skader);
		desc += addProp('Kunde har accepteret betingelser', model.contact_information.accept ? 'Ja' : 'Nej');
		desc += addProp('Email', model.contact_information.email);
		desc += addProp('Telefonnummer', model.contact_information.phone);

		desc += buildExistingProductsDescription(model, cms);

		desc += '<h2>Forsikring</h2>';
		desc += addProp('Rki', model.additionalInfo.rki);
		if (!(this.uiComp as any).isNewDriver) {
			desc += addProp('Eksisterende forsikring', model.additionalInfo.existInsurance);
			if (model.additionalInfo.existInsurance === 'nej') {
				desc += addProp('Tidligere forsikring', model.additionalInfo.existFormerInsurance);
				if (model.additionalInfo.existFormerInsurance === 'ja') {
					desc += addProp('Tidligere selskab', model.additionalInfo.formerInsurance);
				}
			} else {
				desc += addProp('Nuværende selskab', model.additionalInfo.existingInsurance);
			}
		} else {
			desc += addProp('Eksisterende forsikring', 'Ingen');
			desc += addProp('Nuværende selskab', 'Ingen, ny bilist');
		}

		desc += addProp('Gyldig hurtigst mulig', model.additionalInfo.validNow);
		if (model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', model.additionalInfo.validFromDate);
		}

		desc += '<h2>Ønsket forsikring</h2>';
		desc += addProp('Forsikringstype', pack.name);
		desc += addProp('Årlig kørselsbehov', model.carInfo.yearlyMileage);
		desc += addProp('Antal år med egen bil', model.personInfo.carOwnerYears);
		desc += addProp('Selvrisiko', (this.uiComp as any).getExcessAmount());
		desc += addProp('<br>Betaling', model.choosePackage.monthYear === 'M' ? 'Månedlig' : 'Årlig');
		desc += addProp(
			'Samlet pris',
			model.choosePackage.monthYear === 'M' ? pack.totalPriceDisplay : pack.yearlyPriceTotalDisplay
		);
		desc += addProp('Beregningstype', model.choosePackage.useTiaOffer ? 'Tia beregning' : 'AU_PB beregning');
		desc += addProp('Dækninger', getCoverageNames(pack));

		desc += getPaymentInfo(model, cms);

		desc += '<h2>Bil oplysninger</h2>';
		desc += addProp(
			'Nummerplade',
			model.carInfo.licenseplate ? model.carInfo.licenseplate.toUpperCase() : 'Ikke oplyst'
		);
		desc += addProp('Bil', model.carInfo.car);
		desc += addProp('Variant', model.carInfo.variantName);
		desc += addProp('Årgang', model.carInfo.year);
		desc += addProp('Kid', model.carInfo.kid + '');
		desc += addProp('VariantId', model.carInfo.variantId);
		desc += addProp('Vægt', model.carInfo.carWeight + '');
		desc += addProp('Drivmiddel', model.carInfo.fuelType);
		desc += addProp('VehicleGroup', model.carInfo.vehicleGroup);
		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
