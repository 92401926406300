import { mapState } from 'vuex';
import FieldValidator from '@/services/fieldValidatorService';
import { CardBlock } from '@/cms/definitions/content-types';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { jaNejArray, Validator, ValuePair } from '../services/ValidationService';
import { togglePopup } from '../services/AlertService';

@Options({
	name: 'FamilyMemberComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		personNo: Number,
		card: Object as PropType<CardBlock>,
		placeholderName: String,
		labelName: String,
		placeholderAge: String,
		labelAge: String,
		placeholderWork: String,
		labelWork: String,
		labelQuestionWork: String,
	},
})
export default class FamilyMemberComponent extends Vue {
	personNo: number; // if model has value
	// card: CardBlock; // Card from cms
	card: CardBlock; // Card from cms
	placeholderName: string;
	labelName: string;
	placeholderAge: string;
	labelAge: string;
	placeholderWork: string;
	labelWork: string;
	labelQuestionWork: string;

	public model!: any; // datastructure for user input (store)
	public cms!: any; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;
	public jaNejArray: Array<ValuePair> = jaNejArray;
	public useAdultAccident = false;

	public created() {
		// TODO KLK
		//this.useAdultAccident = this.model.productName === InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING && (this.cms as FamilySettings).useAdultAccident;
		this.useAdultAccident =
			this.model.productName === InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING &&
			(this.cms as any).useAdultAccident;
		this.handleAge();
	}

	public beforeUnmount() {
		if (this.model.familyInfo['hasWork' + this.personNo] !== 'ja') {
			this.model.familyInfo['hasWork' + this.personNo] = undefined;
			this.model.familyInfo['work' + this.personNo] = undefined;
			this.model.familyInfo['workId' + this.personNo] = undefined;
		}
	}
	public get showPerson() {
		if (!this.model.familyInfo.personCount || this.personNo > this.model.familyInfo.personCountId) {
			return false;
		}
		if (this.personNo === 0) {
			return true;
		}
		// check previous element
		const name = 'name' + (this.personNo - 1);
		// const cpr = 'cpr'+ (this.personNo-1);
		const ageProp = 'age' + (this.personNo - 1);

		if (this.useAdultAccident) {
			if (this.model.familyInfo[ageProp] === undefined) {
				return false;
			}
			const age = this.model.familyInfo[ageProp];

			const ok = this.fieldValidator.isValidDefault(this.model.familyInfo[name]) && age !== -1;
			if (ok) {
				if (age >= 18) {
					if (!this.model.familyInfo['hasWork' + (this.personNo - 1)]) {
						return false;
					}
					if (this.model.familyInfo['hasWork' + (this.personNo - 1)] === 'ja') {
						if (this.model.familyInfo['workId' + (this.personNo - 1)]) {
							return true;
						}
						return false;
					}
				}
			}
			return ok;
		} else {
			// BØRNE_ULYKKE

			if (this.model.familyInfo[ageProp] === undefined) {
				return false;
			}
			const age = this.model.familyInfo[ageProp];

			const ok = this.fieldValidator.isValidDefault(this.model.familyInfo[name]) && age !== -1 && age < 18;

			if (ok) {
				if (this.cms.showWorkForMinors && age >= 15) {
					if (!this.model.familyInfo['hasWork' + (this.personNo - 1)]) {
						return false;
					}
					if (this.model.familyInfo['hasWork' + (this.personNo - 1)] === 'ja') {
						if (this.model.familyInfo['workId' + (this.personNo - 1)]) {
							return true;
						}
						return false;
					}
				}
			}
			return ok;
		}
	}

	public get cprPopover(): string {
		if (!this.useAdultAccident && this.personNo === 0) {
			// only show on first child
			return this.cms.childCprPopover;
		}
		return undefined;
	}

	public get showWork(): boolean {
		if (this.age === undefined) {
			return false;
		}
		if (this.useAdultAccident) {
			return this.age >= 18;
		} else {
			// BØRNE_ULYKKE
			return this.cms.showWorkForMinors && this.age >= 15;
		}
	}

	public get showWorkQuestion(): boolean {
		return this.useAdultAccident ? false : this.showWork;
	}

	public handleAge() {
		// const cpr = 'cpr'+ this.personNo;
		const age = 'age' + this.personNo;

		if (this.model.familyInfo[age] === undefined) {
			return false;
		}

		if (this.useAdultAccident) {
			const personAge = this.fieldValidator.getAge(this.model.familyInfo[age], false);
			//const personAge = this.fieldValidator.getAge(this.model.familyInfo[cpr]);
			if (personAge > -1 && personAge < 100) {
				this.model.familyInfo[age] = personAge;
				if (this.personNo === 0 && personAge >= 18) {
					this.model.personInfo.customerAge = personAge + '';
					this.model.contact_information.name = this.model.familyInfo.name0;
					// this.model.contact_information.cpr = this.model.familyInfo.cpr0;
				}
				if (personAge >= 18) {
					this.model.familyInfo['hasWork' + this.personNo] = 'ja';
				}
				return;
			}
		} else {
			// BØRNE_ULYKKE
			const personAge = this.fieldValidator.getAge(this.model.familyInfo[age], true);
			//const personAge = this.fieldValidator.getAgeUnderEighteen(this.model.familyInfo[cpr]);

			if (personAge > -1 && personAge < 18) {
				this.model.familyInfo[age] = personAge;
				return;
			}
		}

		this.model.familyInfo[age] = undefined;
	}

	public get age(): number {
		const age: any = this.model.familyInfo['age' + this.personNo];
		if (age === '') {
			return undefined;
		}
		return age;
	}

	public get fieldValidatorType(): string {
		return this.useAdultAccident ? 'over18' : 'under18';
	}
	public get work(): string {
		return this.model.familyInfo['work' + this.personNo];
	}
	public workSelected(result) {
		if (result.matchText) {
			this.model.familyInfo['work' + this.personNo] = result.matchText;
			const inx = this.cms.work.indexOf(result.matchText);
			this.model.familyInfo['workId' + this.personNo] = this.cms.workIds[inx];
			return;
		}
		this.model.familyInfo['work' + this.personNo] = undefined;
		this.model.familyInfo['workId' + this.personNo] = undefined;
	}

	public workSearchError() {
		const popup = {
			title: 'Problem med at finde dit job',
			content: 'Der er desværre i øjeblikket problemer med jobsøgning. Prøv igen om et øjeblik eller kontakt os.',
			btnSecondLabel: 'Ok',
			track: true,
			id: 'workSearchError',
			show: true,
			trackToken: 'workSearch Error',
		};
		togglePopup(this.cms, popup);
	}

	public handleRadioClick(evt: any) {
		this.model.familyInfo['hasWork' + this.personNo] = evt.value;
		if (evt.value === 'nej') {
			this.model.familyInfo['work' + this.personNo] = undefined;
			this.model.familyInfo['workId' + this.personNo] = undefined;
		}
	}
}
