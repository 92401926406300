import BuyCar from './BuyCarComponent';
import { cloneDeep } from 'lodash';
import AxiosService from '@/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	getDiscount,
	handleSpecialCarDiscounts,
	mapCoveragesFromProductConfig,
	reducePack,
	setPrices,
} from '../services/CalculationService';
import { Formatter, hasAddressSupplement, monthYearArray } from '../services/ValidationService';
import { togglePopup } from '../services/AlertService';
import { isDevelop } from '@/services/environmentService';
import { updateExcessesFromCalculation } from '../services/ProductService';

export default class CarCalculator {
	private model: any; // the data structure for state and properties
	private cms: any; // settings from EPI
	private licensePlateSearchUrl: string;
	private calcConfig: any;
	// used for first three calculations, to see if there's known error codes
	private successCalc = true;
	private axiosService: AxiosService;
	private calculatorUrl: string;
	private calculatorInfo: Array<any>;
	private trackCalcError = true;
	private trackSpecialCarError = true;

	constructor(buyCar: BuyCar) {
		this.model = buyCar.model;
		this.cms = buyCar.cms;
		this.calculatorInfo = JSON.parse(buyCar.calculatorInfoBlock.markup);

		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/car/quotes';

		this.licensePlateSearchUrl =
			defaultConfig.baseUrl +
			store.state.openServiceCalculatorUrl +
			'/insurance/car/variants/by-registration-number/';
		this.axiosService = new AxiosService(this.calculatorUrl);
		this.setUpConfig();
	}

	public async setUpConfig() {
		if (!this.calcConfig) {
			if (this.model.campaign.valid) {
				this.cms.calculatorConfigId = this.cms.campaigns.get(this.model.campaign.ID).blockId;
			}

			const calculatorInfo = this.calculatorInfo.find(
				(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId
			);

			this.calcConfig = calculatorInfo.insuranceConfiguration;
		}
		this.model.calculation.abCalc = {
			excessIdDefault: this.calcConfig.excessIdDefault,
			packageIds: this.calcConfig.packageIds,
			packages: cloneDeep(this.calcConfig.packages),
			coveragesDetails: this.calcConfig.coverages,
		};

		this.setupExcessList();

		// map coverages to each package
		this.model.calculation.abCalc.packages.forEach((pack) => {
			// we can only extend, when Ansvar+ is handled explicit
			if (this.cms.extendCoverages) {
				pack.coverages = [];
				pack.includedCoverageIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
				pack.expandedCoverages = [];
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.expandedCoverages.push(this.cms.getCoverageName(coverage.name));
				});
			} else {
				pack.coverages = [];
				pack.includedCoveragesExpandedIds.forEach((riskId) => {
					const coverage = this.calcConfig.coverages.find((x) => riskId === x.riskId);
					pack.coverages.push(coverage);
				});
			}
		});

		this.model.calculation.abCalc.packs = new Map<number, Object>();
		this.model.calculation.abCalc.excesses.forEach((excess) => {
			const calcs = [];
			this.model.calculation.abCalc.packs.set(excess.id, calcs);
			this.model.calculation.abCalc.packages.forEach((pack) => {
				calcs.push(reducePack(cloneDeep(pack)));
			});
		});
		this.model.choosePackage.monthYear = monthYearArray[0].value; // default 'M'
		if (!this.model.carInfo.yearlyMileage) {
			this.model.carInfo.yearlyMileage = this.cms.kmForbrug[2]; // op til 15.000 km
		}

		mapCoveragesFromProductConfig(this.model, this.cms);
		this.updateCalcKeys();
	}

	public setupExcessList() {
		this.model.calculation.abCalc.excesses = this.calcConfig.excessList;
		if (this.model.calculation.abCalc.excesses[0].id === this.model.calculation.abCalc.excessIdDefault) {
			// ensure default excess is last in list
			this.model.calculation.abCalc.excesses.reverse();
		}
		this.model.ownRiskOptions = [];
		this.model.ownRiskLabels = [];

		this.model.calculation.abCalc.excesses.forEach((excess) => {
			if (this.model.personInfo.customerAge >= excess.minCustomerAge) {
				this.model.ownRiskOptions.push(excess.id);
				this.model.ownRiskLabels.push(Formatter.format(excess.amount) + ' kr.');
			}
		});
	}

	private checkFirstPackagePrices(excessId: number) {
		let ok = true;
		const packages = this.model.calculation.abCalc.packs.get(excessId);
		packages.forEach((calc) => {
			if (!calc.totalPrice) {
				ok = false;
			}
		});
		return ok;
	}

	private async calculatePackagesEpi(monthly: boolean, excessId: number): Promise<boolean> {
		try {
			this.successCalc = true;

			const rebate = getDiscount(this.cms);
			this.model.calculation.discount = rebate.discount;
			this.model.calculation.discountDisplay = rebate.discountDisplay;

			// FuelType/VehicleGroup discount
			const special = handleSpecialCarDiscounts(this.model, this.cms);

			const promises = [];
			for (let index = 0; index < this.model.calculation.abCalc.packageIds.length; index++) {
				const packageId = this.model.calculation.abCalc.packageIds[index];
				promises.push(this.onePackageCalcEpi(monthly, excessId, packageId, special.specialDiscount));
			}
			await Promise.all(promises);
			return Promise.resolve(this.successCalc);
		} catch (ex) {
			console.error(ex);
			if (monthly) {
				this.errorPopUp();
			}
			return Promise.resolve(false);
		}
	}

	public async searchLicenseplate(value) {
		// return await getCarLicenseplateSearch();

		const url = `${this.licensePlateSearchUrl}${encodeURI(value)}`;
		return await this.axiosService.get(url);
	}
	public async getCalculations(excessId?: number): Promise<boolean> {
		if (!this.model.calculation.isCalculated) {
			store.state.showSpinner = true;
			store.state.calculatorContext.calculating = true;
			// handle "eternal" spinning
			setTimeout(() => {
				if (store.state.showSpinner) {
					store.state.showSpinner = false;
					store.state.calculatorContext.calculating = false;
				}
			}, this.cms.calculationSpinnerTimeout);
		}

		return this.getEpiCalculations();
	}

	private async getEpiCalculations(): Promise<boolean> {
		// reset calculation
		this.model.choosePackage.useTiaOffer = false;
		await this.setUpConfig();
		let success = true;

		const selectedExcessId = this.model.choosePackage.ownRiskId || this.model.calculation.abCalc.excessIdDefault;

		if (hasAddressSupplement(this.model, this.cms)) {
			this.model.calculation.addressSupplement = this.cms.addressSupplement;
		} else {
			this.model.calculation.addressSupplement = 1;
		}
		// resetPrices
		const pack = this.model.calculation.abCalc.packs.get(selectedExcessId);
		pack.totalPriceDisplay = undefined;

		success = await this.calculatePackagesEpi(true, selectedExcessId);
		// check if has got prices
		if (!success) {
			success = this.checkFirstPackagePrices(selectedExcessId);
		}

		if (success) {
			// get yearlyprices
			this.calculatePackagesEpi(false, selectedExcessId);
		}

		this.model.calculation.isCalculated = success;
		store.state.showSpinner = false;
		store.state.calculatorContext.calculating = false;
		return success;
	}

	private async onePackageCalcEpi(
		monthly: boolean,
		excessId: number,
		packageId: number,
		specialDiscount: number
	): Promise<boolean> {
		if (this.isSpecialCarOrExcludedBrand()) {
			this.successCalc = false;
			await this.specialCarModal();
			return Promise.resolve(false);
		}
		const response = await this.axiosService.getRetryDefaultUrl(
			this.createParamsEpi(excessId, packageId, monthly ? 1 : 0)
		);

		if (response?.status !== 200) {
			if (isDevelop && this.cms.mockData) {
				// MOCK
				let add = parseInt(500000 * Math.random() + '');
				this.model.calculation.abCalc.packs.get(excessId).forEach((calc) => {
					add += 100;
					const res = response.data;
					res.total = add;
					res.detail.statutory_fee = 15;
					res.rebate_price = add - 150;
				});
			} else {
				this.successCalc = false;

				if (response?.quote_problem?.length > 0) {
					return await this.specialCarModal();
				}
				this.errorPopUp();
				return Promise.resolve(false);
			}
		}

		const pack = this.model.calculation.abCalc.packs.get(excessId);
		// mapPriceResult(response); - doesn't work - car is different from other calculators
		this.mapPriceResultForCar(response);
		setPrices(packageId, response, monthly, pack, this.model, this.cms, specialDiscount);
		if (!this.model.updatedExcessListFromProduct) {
			updateExcessesFromCalculation(response, this.calcConfig);
			this.setupExcessList();
			this.model.updatedExcessListFromProduct = true;
		}

		this.updateCalcKeys();
		return Promise.resolve(true);
	}

	private errorPopUp() {
		let trackToken = 'car calculator';
		if (this.model.carInfo?.subtitle) {
			trackToken += ' ' + this.model.carInfo.subtitle;
		}
		const popup: any = {
			title: this.cms.defaultCalcErrorTitle,
			content: this.cms.defaultCalcErrorContent,
			track: this.trackCalcError,
			btnSecondLabel: 'Ok',
			id: 'calcError',
			show: true,
			trackToken,
			errorType: 'car calculator',
		};

		if (this.cms.useBtnInlineFail) {
			popup.btnInlineLabel = this.cms.btnInlineFailLabel;
			popup.btnInlineStyle = this.cms.btnInlineFailStyle;
			popup.btnInlineIcon = this.cms.btnInlineIcon;
			popup.btnInlineAction = () => {
				this.getCalculations();
			};
		}

		togglePopup(this.cms, popup);
		this.trackCalcError = false;
	}

	private mapPriceResultForCar(response) {
		const res = response.data;
		response.data.totalPrice = res.total;
		response.data.statutoryFee = res.detail.statutory_fee;
		response.data.rebatePrice = res.rebate_price;
	}

	private createParamsEpi(excessId: number, packageId: number, monthlyPrices: number) {
		// move these outside
		const carOwnerYears = this.cms.carOwnerYears.indexOf(this.model.personInfo.carOwnerYears);
		let yearlyDrivingInKm = this.cms.kmForbrug.indexOf(this.model.carInfo.yearlyMileage);
		yearlyDrivingInKm = yearlyDrivingInKm * 5000 + 5000;
		const customerAge = parseInt(this.model.personInfo.customerAge.trim().replace(/\D/g, ''));
		let groupAgreement = 0;
		if (this.model.campaign.valid) {
			groupAgreement = this.cms.campaigns.get(this.model.campaign.ID).groupId;
		}
		const pack = this.model.calculation.abCalc.packages.find((pack) => packageId === pack.id);

		// create params
		const params = {
			annual_driving_exceed_in_km: yearlyDrivingInKm,
			claims_last_3_years: 0,
			plus_customer: 'PK0',
			customer_age: customerAge,
			excess_id: excessId,
			years_with_own_car: carOwnerYears,
			postal_code: this.model.personInfo.zipCode,
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: pack.calculateRisks.toString(),
			kid: this.model.carInfo.useKid ? this.model.carInfo.kid : undefined,
			car_weight: this.model.carInfo.useKid ? this.model.carInfo.carWeight : undefined,
			variant_id: this.model.carInfo.useKid ? undefined : this.model.carInfo.variantId,
			model: this.model.carInfo.useKid ? undefined : this.model.carInfo.model,
			brand: this.model.carInfo.useKid ? undefined : this.model.carInfo.brand,
			group_agreement: groupAgreement > 0 ? groupAgreement : undefined,
		};
		return params;
	}

	private createParamsEpiSpecialCar() {
		const monthlyPrices = 1;
		const packageId = this.model.calculation.abCalc.packageIds[0]; // superkasko
		const excessId = this.model.calculation.abCalc.excessIdDefault;
		const pack = this.model.calculation.abCalc.packages.find((pack) => packageId === pack.id);

		// create params
		const params = {
			years_with_own_car: 2,
			annual_driving_exceed_in_km: 15000,
			customer_age: 30,
			postal_code: 1656,
			plus_customer: 'PK0',
			claims_last_3_years: 0,
			kid: this.model.carInfo.useKid ? this.model.carInfo.kid : undefined,
			car_weight: this.model.carInfo.useKid ? this.model.carInfo.carWeight : undefined,
			variant_id: this.model.carInfo.useKid ? undefined : this.model.carInfo.variantId,
			model: this.model.carInfo.useKid ? undefined : this.model.carInfo.model,
			brand: this.model.carInfo.useKid ? undefined : this.model.carInfo.brand,
			excess_id: excessId,
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: pack.calculateRisks.toString(),
		};

		return params;
	}

	public async isSpecialCar(): Promise<boolean> {
		if (!this.model.carInfo.checkForSpecialCar) {
			this.model.carInfo.useKid = this.cms.useKidFirst && this.model.carInfo.kid && this.model.carInfo.carWeight;
			this.model.carInfo.checkForSpecialCar = true;

			if (this.isSpecialCarOrExcludedBrand()) {
				return await this.specialCarModal();
			}

			let response = await this.axiosService.getRetryDefaultUrl(this.createParamsEpiSpecialCar());

			if (this.errorSpecialCar(response)) {
				return await this.specialCarModal();
			}

			if (response.status === 400) {
				let possibleFallbackCalculation = false;
				if (!this.model.carInfo.useKid) {
					possibleFallbackCalculation = this.model.carInfo.kid && this.model.carInfo.carWeight;
				} else {
					possibleFallbackCalculation =
						this.model.carInfo.brand && this.model.carInfo.model && this.model.carInfo.variantId;
				}

				if (
					possibleFallbackCalculation &&
					response?.data?.detail?.toLowerCase()?.trim() === 'unknown car variant'
				) {
					// toggle use KID
					this.model.carInfo.useKid = !this.model.carInfo.useKid;
					response = await this.axiosService.getRetryDefaultUrl(this.createParamsEpiSpecialCar());
					if (this.errorSpecialCar(response)) {
						return await this.specialCarModal();
					}
				}
			}

			if (response?.status === 200) {
				if (!this.model.updatedExcessListFromProduct) {
					updateExcessesFromCalculation(response, this.calcConfig);
					this.setupExcessList();
					this.model.updatedExcessListFromProduct = true;
				}
			}
		}
		return Promise.resolve(false);
	}

	private errorSpecialCar(response): boolean {
		if (
			response?.status === 400 &&
			(response?.quote_problem?.length > 0 ||
				response?.data?.detail?.toLowerCase().startsWith('antallet af heste'))
		) {
			this.model.carInfo.specialDetected = true;
			return true;
		}
		return false;
	}
	private isSpecialCarOrExcludedBrand() {
		if (
			this.model.carInfo.specialDetected ||
			this.cms.excludedVehicleGroups.includes(this.model.carInfo.vehicleGroup + '')
		) {
			this.model.carInfo.specialDetected = true;
			return true;
		}
		const brand = this.model.carInfo.brand?.toLocaleLowerCase()?.trim();
		return this.cms.carBrandBlackList.includes(brand);
	}

	private async specialCarModal(): Promise<any> {
		const vehicleGroup = this.model.carInfo?.vehicleGroup;
		const brand = this.model.carInfo?.brand?.toLocaleLowerCase()?.trim();
		const model = this.model.carInfo?.model?.toLocaleLowerCase()?.trim();
		let trackMsg = 'customer special car';
		if (brand) {
			trackMsg += ', brand ' + brand;
		}
		if (model) {
			trackMsg += ', model ' + model;
		}
		if (vehicleGroup) {
			trackMsg += ', vehicle group ' + vehicleGroup;
		}

		await new Promise((res) => setTimeout(res, 500));

		togglePopup(this.cms, {
			title: this.cms.specialCarTitle,
			content: this.cms.specialCarContent,
			track: this.trackSpecialCarError,
			trackToken: `car calculator specialcar`,
			productName: this.model.productName,
			stepName: 'car_info',
			trackMsg,
			errorType: 'info',
			btnSecondLabel: 'Ok',
			id: 'calcWarning',
			show: true,
		});
		this.trackSpecialCarError = false;
		return Promise.resolve(true);
	}

	public updateCalcKeys() {
		this.model.calculation.updatePrices = this.model.calculation.updatePrices.map((elem) => {
			return (elem += '1');
		});
	}
}
