import { isApp } from '@/services/environmentService';

export default {
	SET_EPI_ROUTING(state: any, epiRouting: []) {
		state.epiRouting = epiRouting;
	},

	SET_USABILLA_COUNT(state: any) {
		state.usabilla.count = state.usabilla.count + 1;
	},

	SET_USABILLA_MOUNTED(state: any) {
		state.usabilla.mounted = state.usabilla.mounted + 1;
	},

	SET_APP_REPLACE_ROUTE(state: any, appReplaceRoute: boolean) {
		if (isApp()) {
			// only app relevant
			state.appReplaceRoute = appReplaceRoute;
		}
	},
	TOGGLE_MODAL(state: any, modalState: { id: string; isActive: boolean }) {
		//state.modalState = modalState;
	},
	SET_CONSENT_SET_IN_APP(state: any, consent: boolean) {
		state.consentSetInApp = consent;
	},
	// SET_OLD_APP_VERSION_ALERTED(state: any, consent: boolean) {
	//     state.oldAppVersionAlerted = consent;
	// },

	// toggleMenu(state: any, payload) {
	//     state.isMenuOpen = payload;
	// },
	// setWindowWidth(state: any, payload) {
	//     state.windowWidth = payload;
	// },
	// setScrollPosition(state: any, payload) {
	//     state.scrollPosition = payload;
	// },
	setDeviceType(state: any, payload) {
		state.isTouchDevice = payload;
	},
	// setAllowTracking(state: any, payload) {
	//     state.allowTracking = payload;
	// },
	RECAPTCHA_SITE_KEY(state: any, recaptchaSiteKey: string) {
		state.recaptchaSiteKey = recaptchaSiteKey;
	},
};
