import { mapState } from 'vuex';
import { CardBlock } from '@/cms/definitions/content-types';
import { CarSettings } from '../car/CarSettings';
import { AccidentSettings } from '../accident/AccidentSettings';
import { HomeSettings } from '../home/HomeSettings';
import { HouseSettings } from '../house/HouseSettings';
import { DogSettings } from '../dog/DogSettings';
import { TravelSettings } from '../travel/TravelSettings';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import FieldValidator from '@/services/fieldValidatorService';
import { Options, Vue } from 'vue-class-component';
import { Validator } from '../services/ValidationService';
import { FamilySettings } from '../family/FamilySettings';
import { mapDawaAddress, resetAddress } from '@/services/addressMappingService';
import store from '@/store/store';
import { togglePopup } from '../services/AlertService';

@Options({
	name: 'ContactInformationStepComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object, // Card from cms
		isValid: Boolean, // wether card is complete
	},
})
export default class ContactInformationStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // whether card is complete

	public model!: any; // data structure for user input (store)
	public cms!:
		| DogSettings
		| TravelSettings
		| CarSettings
		| AccidentSettings
		| HomeSettings
		| HouseSettings
		| FamilySettings; // settings from CMS (store)
	public fieldValidator: FieldValidator = Validator;
	public noEmailCustomer = false;
	public showAddress = false;

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
		if (this.model.contact_information.customerNo) {
			if (!this.fieldValidator.isValidEmail(this.model.contact_information.email)) {
				this.noEmailCustomer = true;
			}
		}
		this.showAddress = this.cms.customerAddressRequired && !this.model.personInfo.address;
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public setSubHeader(evt) {
		if (evt.event.type === 'blur') {
			this.model.contact_information.subtitle = evt.value;
		}
	}

	public checkboxEvent(evt) {
		this.model.contact_information.accept = evt.checked;
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}

	public get showEmail(): boolean {
		if (this.model.contact_information.customerNo) {
			return this.noEmailCustomer;
		}
		return true;
	}

	public get emailFilled(): boolean {
		return this.fieldValidator.isValidEmail(this.model.contact_information.email);
	}

	public get addressFilled(): boolean {
		if (this.showAddress) {
			return this.model.personInfo.address !== undefined;
		}
		return true;
	}
	public addressSearch(address) {
		if (address.matchText) {
			if (!this.isConsistentAddress(address?.data?.postnr)) {
				this.model.pristineStep.set(this.card.name, false);
				return;
			}

			mapDawaAddress(this.model.personInfo, address);

			this.model.pristineStep.set(this.card.name, true);
		}
	}

	private isConsistentAddress(newZipCode): boolean {
		let ok = true;
		if (this.model.personInfo.zipCode && this.model.personInfo.zipCode !== newZipCode) {
			ok = false;
		}
		const commonPersonInfo = store.getters.getCalculatorCommonModel.personInfo;
		if (ok && !commonPersonInfo.zipCode) {
			return true;
		}
		if (ok) {
			ok = newZipCode === commonPersonInfo.zipCode;
		}
		if (!ok) {
			let msg = this.cms.wrongAddressPopupContent;
			msg = msg.replace(
				'@zipTxt',
				(commonPersonInfo.zipCode || this.model.personInfo.zipCode) +
					' ' +
					(commonPersonInfo.zipName || this.model.personInfo.zipName)
			);
			const popup = {
				title: this.cms.wrongAddressPopuptitle,
				content: msg,
				btnSecondLabel: 'Ok',
				track: true,
				trackToken: 'addressMismatch',
				id: 'addressMismatch',
				show: true,
			};
			togglePopup(this.cms, popup);
			return false;
		}

		return true;
	}

	public get hasChildrenCpr(): boolean {
		return (
			this.model.productName === InsurancesProductEnum.BOERNEULYKKES_FORSIKRING ||
			this.model.productName === InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING
		);
	}

	public get addressLabel(): string {
		return this.model.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING ? 'Hjem adresse' : 'Adresse';
	}

	// public isValidChildCpr(inx) : boolean {
	//     return this.fieldValidator.getAgeUnderEighteen(this.model.familyInfo['cpr' + (inx)]) !== -1;
	// }

	// public get isValidAdultCpr() : boolean {
	//     return this.fieldValidator.isValidCpr(this.model.personInfo.otherCpr);
	// }

	public get hasAdultCpr(): boolean {
		if (this.model.productName === InsurancesProductEnum.ULYKKES_FORSIKRING) {
			return this.model.personInfo.otherCustomerAge !== undefined;
		}
		return false;
	}
}
