import store from '@/store/store';

export const togglePopup = (cms, modalContent) => {
	const toggleModal = Object.assign({}, resetModal(cms), modalContent);
	store.state.modal = toggleModal;
};

export const resetModal = (cms) => {
	const modal = {} as any;
	// setup fail modal as default
	modal.show = false;
	modal.id = 'none';
	modal.track = false;
	modal.trackToken = undefined;
	(modal.errorType = undefined),
		(modal.productName = undefined),
		(modal.stepName = undefined),
		(modal.trackMsg = undefined),
		(modal.content = cms.failText);
	modal.btnType = 'tel';
	modal.btnStyle = cms.btnStyle;
	modal.btnLabel = cms.btnLabel;
	modal.btnIcon = cms.btnIcon;
	modal.btnAction = cms.btnAction;
	modal.btnSecondStyle = cms.btnSecondStyle;
	modal.btnSecondLabel = cms.btnSecondLabel;
	modal.btnInlineLabel = undefined;
	modal.btnInlineAction = undefined;
	modal.btnInlinestyle = cms.btnStyle;
	modal.btnInlineIcon = undefined;
	modal.title = cms.failTitle;
	modal.redirect = undefined;
	modal.redirectOnClose = true;
	return modal;
};
